import React, { useState, useEffect } from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import taxios from '../../util/token_refresh_hook';

const CourseRating = ({ courseId, initialRating = 0, userRating = 0, onRatingUpdate }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  // Add validation for courseId
  useEffect(() => {
    if (!courseId) {
      console.error('CourseRating: No courseId provided');
    }
  }, [courseId]);

  const handleRatingClick = async (e, starValue) => {
    if (isProcessing) return;
    if (!courseId) {
      toast.error('Cannot rate course: Invalid course ID');
      return;
    }
    
    setIsProcessing(true);
    setError(null);
    
    try {
      console.log('Rating request details:', {
        courseId,
        url: `${process.env.REACT_APP_BACKEND_URL}/api/courses/${courseId}/rate`,
        rating: starValue
      });

      const { left, width } = e.target.getBoundingClientRect();
      const clickX = e.clientX - left;
      const isHalf = clickX < width / 2;
      const finalRating = isHalf ? starValue - 0.5 : starValue;

      const response = await taxios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/courses/${courseId}/rate`,
        { rating: finalRating }
      );

      if (response.data && response.data.payload) {
        onRatingUpdate(response.data.payload);
        toast.success('Rating updated successfully!');
      }
    } catch (err) {
      console.error('Rating error:', err);
      const errorMessage = err.response?.data?.error || 'Error updating rating';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsProcessing(false);
    }
  };

  const renderStars = () => {
    const stars = [];
    const currentRating = userRating || initialRating;

    for (let i = 1; i <= 5; i++) {
      if (currentRating >= i) {
        stars.push(
          <button
            key={i}
            onClick={(e) => handleRatingClick(e, i)}
            disabled={isProcessing}
            className="text-yellow-400 text-2xl hover:scale-110 transition-transform"
            aria-label={`Rate ${i} stars`}
          >
            <FaStar />
          </button>
        );
      } else if (currentRating >= i - 0.5) {
        stars.push(
          <button
            key={i}
            onClick={(e) => handleRatingClick(e, i)}
            disabled={isProcessing}
            className="text-yellow-400 text-2xl hover:scale-110 transition-transform"
            aria-label={`Rate ${i} stars`}
          >
            <FaStarHalfAlt />
          </button>
        );
      } else {
        stars.push(
          <button
            key={i}
            onClick={(e) => handleRatingClick(e, i)}
            disabled={isProcessing}
            className="text-gray-300 text-2xl hover:scale-110 transition-transform"
            aria-label={`Rate ${i} stars`}
          >
            <FaStar />
          </button>
        );
      }
    }
    return stars;
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <div className="flex gap-1">
        {renderStars()}
      </div>
      {isProcessing && (
        <span className="text-gray-500 text-sm">Updating rating...</span>
      )}
      {error && (
        <span className="text-red-500 text-sm">{error}</span>
      )}
      <span className="text-gray-700">
        {userRating || initialRating} / 5
      </span>
    </div>
  );
};

export default CourseRating;