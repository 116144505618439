import React, { useState } from "react";
import "./AccessPage.css";
import Logo from "../../assets/LogoWithName.png";

const AccessPage = ({ onSuccess }) => {
  const [accessCode, setAccessCode] = useState("");
  const [error, setError] = useState("");
  const [hover, setHover] = useState(false);

const ACCESS_CODES = [
  "ALPHA123", "BETA456", "GAMMA789",
  "DELTA101", "EPSILON202", "ZETA303",
  "ETA404", "THETA505", "IOTA606",
  "KAPPA707", "LAMBDA808", "MU909",
  "NU111", "XI222",
  "PI444", "RHO555", "SIGMA666",
  "TAU777", "UPSILON888", "PHI999",
  "CHI1010", "PSI2020", "OMEGA3030"
];

  const handleAccessCodeSubmit = () => {
  if (ACCESS_CODES.some(code => code.toLowerCase() === accessCode.toLowerCase())) {
    onSuccess();
  } else {
    setError("Invalid access code. Please try again.");
  }
};
  return (
    <div className="access-page-container">
      {/* Animated Background Gradient */}
      <div className="access-page-background" />
      {/* Main Content */}
      <div className="access-page-content">
        <div className="glass-container">
          {/* Logo */}
          <div className="flex justify-center mb-8">
            <img src={Logo} alt="Archi Logo" className="logo" />
          </div>

          {/* Title and Description */}
          <div className="title-description">
            <h1 className="title">Alpha Beta Tester Access</h1>
            <p className="description">Please enter your access code below</p>
          </div>

          {/* Access Code Input */}
          <div className="space-y-6">
            <div>
              <input
                type="text"
                value={accessCode}
                onChange={(e) => setAccessCode(e.target.value)}
                placeholder="Enter Access Code"
                className="input"
              />
            </div>

            {/* Submit Button */}
            <button
              onClick={handleAccessCodeSubmit}
              className="submit-button"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {hover ? "Unlock Knowledge!" : "Access Now"}
            </button>

            {/* Error Message */}
            {error && (
              <div className="text-center">
                <p className="error-message">{error}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessPage;
