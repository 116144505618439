// ./components/CoursesPage/CoursesPage.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import { FaStar, FaShare, FaCaretDown, FaLock, FaUnlock } from 'react-icons/fa';
import taxios from '../../util/token_refresh_hook';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import './CoursesPage.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';


const placeholderImage = 'https://via.placeholder.com/50';

const CoursesPage = ({ toggleSidebar, sidebarOpen, onLogout }) => {
  // State Variables
  const [ratingFilter, setRatingFilter] = useState('');
  const [topicFilter, setTopicFilter] = useState('');
  const [sortFilter, setSortFilter] = useState('newest');
  const [enrolledFilter, setEnrolledFilter] = useState(''); 
  const [courses, setCourses] = useState([]);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [showDropdown, setShowDropdown] = useState(null);
  const [ratings, setRatings] = useState([]);
  const [topics, setTopics] = useState([]);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const stripe = useStripe();
  const elements = useElements();

  // Refs for Infinite Scrolling and Modal
  const courseLoadMoreRef = useRef(null);
  const modalRef = useRef(null);
    
  const navigate = useNavigate();

  // Function to navigate to course profile
  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  // Fetch Courses, User Role, and Enrolled Courses on Component Mount
  useEffect(() => {
    fetchCourses(page);
    fetchUserRole();
    fetchEnrolledCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch More Courses When Page Increments
  useEffect(() => {
    if (page === 1) return; // Already fetched on mount
    fetchCourses(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // Intersection Observer for Infinite Scrolling
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, { threshold: 1.0 });
    if (courseLoadMoreRef.current && hasMore) {
      observer.observe(courseLoadMoreRef.current);
    }
    return () => {
      if (courseLoadMoreRef.current) {
        observer.unobserve(courseLoadMoreRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore]);

  // Click Outside Handler for Modals
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowRoleModal(false);
        setShowJoinModal(false);
        setShowPaymentModal(false);
      }
    };

    if (showRoleModal || showJoinModal || showPaymentModal) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showRoleModal, showJoinModal, showPaymentModal]);

  // Observer Callback
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore, isLoading]);

  // Fetch All Courses with Pagination
  const fetchCourses = async (pageNumber) => {
    setIsLoading(true);
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/courses`, {
        params: { page: pageNumber, limit: 10 },
      });
      const coursesData = Array.isArray(response.data.payload) ? response.data.payload : [];

      setCourses((prevCourses) => {
        const newCourses = coursesData.filter(
          (course) => !prevCourses.some((c) => c._id === course._id)
        );
        return [...prevCourses, ...newCourses];
      });
      setHasMore(coursesData.length === 10); // Assuming backend sends 10 per page

      const uniqueRatings = [...new Set(coursesData.map((course) => Math.round(course.rating)))].sort((a, b) => a - b);
      const uniqueTopics = [...new Set(coursesData.map((course) => course.courseCategory))].sort();

      setRatings((prevRatings) => [...new Set([...prevRatings, ...uniqueRatings])].sort((a, b) => a - b));
      setTopics((prevTopics) => [...new Set([...prevTopics, ...uniqueTopics])].sort());

    } catch (error) {
      console.error('Error fetching courses:', error);
      setError('Failed to load courses.');
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch User Role
  const fetchUserRole = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/role`);
      console.log('User Role Response:', response.data); // Debugging log
      setUserRole(response.data.role);
    } catch (err) {
      console.error('Error fetching user role:', err);
      setError('Failed to fetch user role.');
    }
  };

  // Fetch Enrolled Courses
  const fetchEnrolledCourses = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/courses/enrolled`, {
        params: { page: 1, perPage: 100 }, // Adjust as needed
      });

      // Debugging: Log the response to inspect the structure
      console.log('Enrolled Courses Response:', response.data);

      // Update the mapping based on the response structure
      setEnrolledCourses(response.data.courses.map(c => c._id));
    } catch (err) {
      console.error('Error fetching enrolled courses:', err);
      setEnrolledCourses([]);
    }
  };

  // Handle Filter Dropdown Click
  const handleFilterClick = (filterType) => {
    setShowDropdown(showDropdown === filterType ? null : filterType);
  };

  // Handle Filter Selection
  const handleFilterSelect = (filterType, value) => {
    if (filterType === 'rating') setRatingFilter(value);
    if (filterType === 'topic') setTopicFilter(value);
    if (filterType === 'sort') setSortFilter(value);
    if (filterType === 'enrolled') setEnrolledFilter(value); 
    setShowDropdown(null);
  };

  // Handle Sharing Course Link
  const handleShare = (courseTitle) => {
    const url = `https://example.com/courses/${courseTitle.replace(/\s+/g, '-').toLowerCase()}`;
    navigator.clipboard.writeText(url).then(() => {
      alert(`Link to "${courseTitle}" has been copied to clipboard: ${url}`);
    }).catch(() => {
      alert('Failed to copy the link.');
    });
  };

  // Handle Create Course Button Click
  const handleCreateCourse = () => {
    if (userRole === 'educator' || userRole === 'organization') {
      navigate('/course-creation');
    } else {
      setShowRoleModal(true);
    }
  };

  // Redirect to Signup with Role
  const redirectToSignup = (role) => {
    navigate('/signup', { state: { role } });
  };

  // Handle Payment Submission
  const handlePaymentSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      // Step 1: Create a PaymentIntent by calling the back-end API
      const response = await taxios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/courses/${selectedCourse._id}/join`
      );
      
      const { clientSecret } = response.data;

      // Step 2: Process the payment
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        toast.error("Payment form not loaded properly");
        return;
      }

      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (error) {
        toast.error('Payment failed: ' + error.message);
        return;
      } 

      if (paymentIntent.status === 'succeeded') {
        // Step 3: Confirm enrollment after successful payment
        await taxios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/courses/${selectedCourse._id}/join/confirm`,
          { paymentIntentId: paymentIntent.id }
        );

        // Step 4: Update UI states
        setEnrolledCourses(prev => [...prev, selectedCourse._id]);
        toast.success('Successfully enrolled in course!');
        setShowPaymentModal(false);
        setShowJoinModal(true);
        
        // Refresh enrolled courses to ensure UI is up to date
        fetchEnrolledCourses();
      }
    } catch (error) {
      // Handle specific error cases
      if (error.response?.data?.error === 'Already enrolled in this course') {
        toast.info('You are already enrolled in this course');
        setShowPaymentModal(false);
      } else {
        toast.error('Payment failed. Please try again.');
        console.error('Payment error:', error);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  // Open Join Modal
  const openJoinModal = (course) => {
    setSelectedCourse(course);
    setShowJoinModal(true);
  };

  // Open Payment Modal
  const openPaymentModal = (course) => {
    setSelectedCourse(course);
    setShowPaymentModal(true);
  };

  // Apply Filters and Sorting to Courses
  const filteredCourses = courses
    .filter((course) => {
      const ratingMatch = ratingFilter ? Math.round(course.rating) === parseInt(ratingFilter) : true;
      const topicMatch = topicFilter ? course.courseCategory === topicFilter : true;
      const enrolledMatch = enrolledFilter === 'yes' 
        ? enrolledCourses.includes(course._id) 
        : enrolledFilter === 'no' 
          ? !enrolledCourses.includes(course._id) 
          : true;
      return ratingMatch && topicMatch && enrolledMatch;
    })
    .sort((a, b) => {
      if (sortFilter === 'newest') {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      if (sortFilter === 'oldest') {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
      if (sortFilter === 'rating') {
        return b.rating - a.rating;
      }
      return 0;
    });

  return (
    <div className="courses-page">
      {/* Top Navigation Bar */}
      <TopNavBar toggleSidebar={toggleSidebar} onLogout={onLogout} onCreateCourse={handleCreateCourse} />

      {/* Community Sidebar */}
      <CommunitySidebar isOpen={sidebarOpen} />

      {/* Main Content */}
      <div className={`courses-page-content ${sidebarOpen ? 'sidebar-open' : ''}`}>
        {/* Filters Section */}
        <div className="courses-page-filters">
          {/* Sort Filter */}
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => handleFilterClick('sort')}>
              Sort by: {sortFilter.charAt(0).toUpperCase() + sortFilter.slice(1)}
              <FaCaretDown className={`dropdown-icon ${showDropdown === 'sort' ? 'open' : ''}`} />
            </div>
            {showDropdown === 'sort' && (
              <div className="filter-options">
                <div className="filter-option" onClick={() => handleFilterSelect('sort', 'newest')}>
                  Newest
                </div>
                <div className="filter-option" onClick={() => handleFilterSelect('sort', 'oldest')}>
                  Oldest
                </div>
                <div className="filter-option" onClick={() => handleFilterSelect('sort', 'rating')}>
                  By Rating
                </div>
              </div>
            )}
          </div>

          {/* Rating Filter */}
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => handleFilterClick('rating')}>
              Rating: {ratingFilter || 'All'}
              <FaCaretDown className={`dropdown-icon ${showDropdown === 'rating' ? 'open' : ''}`} />
            </div>
            {showDropdown === 'rating' && (
              <div className="filter-options">
                {ratings.map((rating) => (
                  <div
                    key={rating}
                    className="filter-option"
                    onClick={() => handleFilterSelect('rating', rating.toString())}
                  >
                    {rating} Stars
                  </div>
                ))}
                <div className="filter-option" onClick={() => handleFilterSelect('rating', '')}>
                  All Ratings
                </div>
              </div>
            )}
          </div>

          {/* Topic Filter */}
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => handleFilterClick('topic')}>
              Topic: {topicFilter || 'All'}
              <FaCaretDown className={`dropdown-icon ${showDropdown === 'topic' ? 'open' : ''}`} />
            </div>
            {showDropdown === 'topic' && (
              <div className="filter-options">
                {topics.map((topic) => (
                  <div
                    key={topic}
                    className="filter-option"
                    onClick={() => handleFilterSelect('topic', topic)}
                  >
                    {topic}
                  </div>
                ))}
                <div className="filter-option" onClick={() => handleFilterSelect('topic', '')}>
                  All Topics
                </div>
              </div>
            )}
          </div>

          {/* Enrolled Filter */}
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => handleFilterClick('enrolled')}>
              Enrolled: {enrolledFilter || 'All'}
              <FaCaretDown className={`dropdown-icon ${showDropdown === 'enrolled' ? 'open' : ''}`} />
            </div>
            {showDropdown === 'enrolled' && (
              <div className="filter-options">
                <div className="filter-option" onClick={() => handleFilterSelect('enrolled', 'yes')}>
                  Yes
                </div>
                <div className="filter-option" onClick={() => handleFilterSelect('enrolled', 'no')}>
                  No
                </div>
                <div className="filter-option" onClick={() => handleFilterSelect('enrolled', '')}>
                  All
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Course List */}
        <div className="courses-page-course-list">
          {filteredCourses.length === 0 && !isLoading && !error && (
            <div>No courses available.</div>
          )}

          {filteredCourses.map((course) => (
            <div
              key={course._id}
              className="courses-page-course-item"
              onClick={() => handleCourseClick(course._id)}
              style={{ cursor: 'pointer' }}
            >
              {/* Course Thumbnail with Lock/Unlock Icon */}
              <div className="courses-page-course-thumbnail" style={{ position: 'relative' }}>
                <img 
                  src={
                    course.courseThumbnailURL 
                      ? course.courseThumbnailURL.startsWith('http')
                        ? course.courseThumbnailURL
                        : `https://archi-video-bucket.s3.us-west-1.amazonaws.com/thumbnails/${course.courseThumbnailURL}`
                      : 'https://via.placeholder.com/150'
                  } 
                  alt="Course Thumbnail" 
                />
                {enrolledCourses.includes(course._id) ? (
                  <FaUnlock className="unlock-icon" style={{ position: 'absolute', top: '10px', right: '10px', color: 'green' }} />
                ) : (
                  <FaLock className="lock-icon" style={{ position: 'absolute', top: '10px', right: '10px', color: 'red' }} />
                )}
              </div>

              {/* Course Information */}
              <div className="courses-page-course-info-row">
                <ProfilePicture 
                  src={course.creatorID.profilePicture || placeholderImage} 
                  alt={course.creatorID.username || 'Anonymous'} 
                />
                <div className="courses-page-course-details">
                  <h2>{course.courseTitle}</h2>
                  <div className="course-price-and-creator">
                    <span className="courses-page-creator-name">{course.creatorID.username}</span>
                    <span className="courses-page-price">
                      {course.coursePrice === 0 ? 'Free' : `$${course.coursePrice.toFixed(2)}`}
                    </span>
                  </div>
                  <div className="courses-page-rating">
                    {[...Array(5)].map((_, i) => (
                      <FaStar 
                        key={i} 
                        color={i < Math.round(course.rating) ? "#ffc107" : "#e4e9f0"} 
                      />
                    ))}
                    <span>{course.rating?.toFixed(1)} ({course.ratingsCount})</span>
                  </div>
                </div>

                {/* Join Button or Enrolled Indicator */}
                {enrolledCourses.includes(course._id) ? (
                  <button className="enrolled-button" disabled>Enrolled</button>
                ) : (
                  <button className="join-course-button" onClick={(e) => {
                    e.stopPropagation();
                    openPaymentModal(course);
                  }}>{course.coursePrice === 0 ? 'Join Free' : `Join $${course.coursePrice.toFixed(2)}`}</button>
                )}

                <FaShare className="courses-page-share-icon" onClick={(e) => {
                  e.stopPropagation();
                  handleShare(course.courseTitle);
                }} />
              </div>
            </div>
          ))}

          {/* Loading Indicator */}
          {isLoading && <div className="loading">Loading...</div>}

          {/* Error Message */}
          {error && <div className="error">{error}</div>}

          {/* Sentinel Element for Infinite Scrolling */}
          <div ref={courseLoadMoreRef}></div>
        </div>
      </div>

      {/* Join Course Modal */}
      {showJoinModal && selectedCourse && (
        <div className="join-course-modal">
          <div className="join-course-modal-content">
            <button className="close-button" onClick={() => setShowJoinModal(false)}>×</button>
            <h2>{`You have successfully joined ${selectedCourse.courseTitle}`}</h2>
            <p><strong>Instructor:</strong> {selectedCourse.creatorID.username}</p>
            <p><strong>Category:</strong> {selectedCourse.courseCategory}</p>
            <p><strong>Description:</strong> {selectedCourse.courseDescription}</p>
            <p><strong>Price:</strong> ${selectedCourse.coursePrice.toFixed(2)}</p>
            <button className="confirm-button" onClick={() => setShowJoinModal(false)}>Close</button>
          </div>
        </div>
      )}



      {/* Payment Modal */}
      {showPaymentModal && selectedCourse &&(
      <div className="payment-modal">
        <div className="payment-modal-content">
          <button className="close-button" onClick={() => setShowPaymentModal(false)}>×</button>
          <h2>{`Purchase ${selectedCourse.courseTitle}`}</h2>

          {/* Stripe CardElement for card details */}
          <form onSubmit={handlePaymentSubmit}>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': { color: '#aab7c4' },
                  },
                  invalid: { color: '#9e2146' },
                },
              }}
            />
            <button type="submit" disabled={!stripe || !elements || isProcessing}>
              {isProcessing ? 'Processing...' : `Pay $${selectedCourse.coursePrice.toFixed(2)}`}
            </button>
          </form>
        </div>
      </div>)}


      {/* Role Modal */}
      {showRoleModal && (
        <div className="courses-page-role-modal">
          <div className="courses-page-role-modal-content" ref={modalRef}>
            <button className="courses-page-close-button" onClick={() => setShowRoleModal(false)}>×</button>
            <h2>Create</h2>
            <div className="courses-page-role-options">
              <div className="courses-page-role-option">
                <h3>Educator</h3>
                <button onClick={() => redirectToSignup('educator')} className="courses-page-create-account-button">
                  Create an account
                </button>
              </div>
              <div className="courses-page-role-option">
                <h3>Organization</h3>
                <button onClick={() => redirectToSignup('organization')} className="courses-page-create-account-button">
                  Create an account
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Toast Container for Notifications */}
      <ToastContainer />
    </div>
  );
};

export default CoursesPage;