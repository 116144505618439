import taxios from "./token_refresh_hook";

/**
 * Fetches data from the server endpoint and either produces the JSON
 * result or errors out.
 * @param {String} url The URL to request data from
 * @returns The JSON data from the response
 */
export default async function fetcher(url){
	try {
		const response = await taxios.get(url);
		return response.data; //Return the data directly from the response
	}
	catch(error){
		//Format and return an error
		const resp = error.response.data;
		const errorMessage = error.response
			? `${error.response.status} :: ${resp.desc}`
			: "An unknown error occurred";
		throw new Error(errorMessage);
	}
}