import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  FaCaretDown,
  FaBell,
  FaUserAlt,
  FaComments,
  FaSearch,
  FaPlus,
  FaCog,
  FaQuestionCircle,
  FaMoon,
  FaSignOutAlt,
  FaChevronRight
} from "react-icons/fa";
import logo from "../../../assets/LogoWithName.png";
import taxios from "../../../util/token_refresh_hook";
import "./TopNavBar.css";
import { getUInfo } from "../../../util/whoami";

function TopNavBar({
  onLogout,
  onCreateStudyGroup,
  onCreateCourse,
  onCreateEvent,
  onCreateInternship,
}) {
  // State management
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isNotificationDropdownOpen, setNotificationDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState({
    results: [],
    pagination: {},
  });
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  // Hooks
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Effects
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
      if (
        !event.target.closest(".top-nav-bar__profile-section") &&
        !event.target.closest(".top-nav-bar__notification-section") &&
        !event.target.closest(".top-nav-bar__dropdown")
      ) {
        closeAllDropdowns();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm) {
        handleSearch(searchTerm);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm, selectedCategory]);

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 30000);
    return () => clearInterval(interval);
  }, []);

  // Search functionality
  const handleSearch = async (value) => {
    if (!value.trim()) {
      setSearchResults({ results: [], pagination: {} });
      setShowResults(false);
      return;
    }

    setIsLoading(true);
    try {
      const response = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/search`,
        {
          params: {
            query: value,
            category: selectedCategory,
            page: 1,
            limit: 10,
          },
        }
      );

      if (response.data.success) {
        setSearchResults({
          results: response.data.data.results || [],
          pagination: response.data.data.pagination || {},
        });
        console.log("Search API Response:", response.data);
        setShowResults(true);
      } else {
        setSearchResults({ results: [], pagination: {} });
      }
    } catch (error) {
      console.error("Search error:", error);
      setSearchResults({ results: [], pagination: {} });
    } finally {
      setIsLoading(false);
    }
  };
  // Dropdown handlers
  const toggleProfileDropdown = (event) => {
    event.stopPropagation();
    setProfileDropdownOpen((prev) => !prev);
    if (isNotificationDropdownOpen) {
      setNotificationDropdownOpen(false);
    }
  };

  const toggleNotificationDropdown = (event) => {
    event.stopPropagation();
    setNotificationDropdownOpen((prev) => !prev);
    if (isProfileDropdownOpen) {
      setProfileDropdownOpen(false);
    }
  };

  const closeAllDropdowns = () => {
    setProfileDropdownOpen(false);
    setNotificationDropdownOpen(false);
    setDropdownOpen(false);
  };

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setDropdownOpen((prev) => !prev);
  };

  // Notification handlers
  const handleNotificationClick = async (notif) => {
    try {
      console.log('Raw notification data:', notif);

      const { id: currentUserId } = getUInfo();
      if (!currentUserId) {
        navigate('/login');
        return;
      }

      // Get the notification and associated IDs
      const notificationId = notif?._id || notif?.id;
      const associatedId = notif?.associatedID || notif?.associatedId;

      if (!associatedId) {
        console.error('No associated ID in notification:', notif);
        return;
      }

      // Mark notification as read first
      if (notificationId) {
        try {
          await taxios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/notifications/${notificationId}/read`
          );
          
          // Update local notifications state to mark this as read
          setNotifications(prev => 
            prev.map(n => 
              (n._id === notificationId || n.id === notificationId) 
                ? { ...n, read: true }
                : n
            )
          );

          // Update unread count
          setUnreadCount(prev => Math.max(0, prev - 1));
        } catch (error) {
          console.error('Error marking notification as read:', error);
        }
      }

      // Close notification dropdown
      setNotificationDropdownOpen(false);

      // Navigate based on notification type
      if (['POST_LIKE', 'POST_DISLIKE', 'POST_COMMENT'].includes(notif.notifType)) {
        navigate(`/app?scrollTo=${associatedId}`);
      } else {
        switch (notif.notifType) {
          case 'COURSE_ENROLLMENT':
          case 'COURSE_RATING':
          case 'COURSE_FOLLOW':
            navigate(`/courses/${associatedId}`);
            break;
          case 'SEMINAR_JOIN':
            navigate(`/seminars/${associatedId}`);
            break;
          case 'INTERNSHIP_APPLICATION':
            navigate(`/internships/${associatedId}`);
            break;
          case 'USER_FOLLOW':
            navigate(`/profile/${associatedId}`);
            break;
          default:
            navigate(`/app?scrollTo=${associatedId}`);
        }
      }

      // Refresh notifications in background
      fetchNotifications();
    } catch (error) {
      console.error('Error in handleNotificationClick:', error);
    }
  };

  // Update your fetchNotifications function to handle read status
  const fetchNotifications = async () => {
    try {
      const { id: currentUserId } = getUInfo();
      
      const response = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/notifications`
      );
      
      // Filter notifications for current user and sort by timestamp
      const userNotifications = response.data
        .filter(notif => notif.userId === currentUserId)
        .sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
      
      console.log('Fetched notifications:', userNotifications);
      
      setNotifications(userNotifications);
      // Update unread count
      const unreadCount = userNotifications.filter(notif => !notif.read).length;
      setUnreadCount(unreadCount);

    } catch (error) {
      console.error('Error fetching notifications:', error);
      setNotifications([]);
      setUnreadCount(0);
    }
  };

  // Add this effect to refresh notifications periodically
  useEffect(() => {
    // Initial fetch
    fetchNotifications();

    // Set up periodic refresh
    const interval = setInterval(fetchNotifications, 30000); // Every 30 seconds

    return () => clearInterval(interval);
  }, []); // Empty dependency array means this runs once on mount

  // Category and search result handlers
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setDropdownOpen(false);
  };

  // Navigate to proper study group. This is just the home page with the group as a parameter
  const goToStudyGroup = (groupId) => {
    const searchParams = new URLSearchParams({
      group: groupId,
      page: 1
    });
    navigate(`/app?${searchParams.toString()}`);
  }

  const handleResultClick = (result) => {
    setShowResults(false);
    setSearchTerm("");

    switch (result.type) {
      case "users":
        navigate(`/profile/${result.id}`);
        break;
      case "courses":
        navigate(`/courses/${result.id}`);
        break;
      case "seminars":
        navigate(`/seminars/${result.id}`);
        break;
      case "internships":
        navigate(`/internships/${result.id}`);
        break;
      case "studyGroups":
        goToStudyGroup(result.id);
        break;
      case "posts":
        navigate(`/posts/${result.id}`);
        break;
      default:
        console.warn("Unknown result type:", result.type);
    }
  };

  // Logout handler
  const handleLogout = () => {
    taxios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/users/logout`, {})
      .then((response) => {
        if (response && response.data) {
          console.log("Logout response:", response.data);
          onLogout();
          navigate("/");
        } else {
          console.error("Unexpected logout response:", response);
        }
      })
      .catch((error) => {
        console.error("Logout failed:", error?.response?.data || error.message);
      });
  };

  // Render methods
  const renderResultItem = (result) => {
    if (result.type === "users") {
      return (
        <div
          key={result.id}
          className="search-result-item"
          onClick={() => handleResultClick(result)}
        >
          <div className="search-result-details">
            <h3>{result.name || result.title || "No Name Available"}</h3>
            {result.studentAt && <p>Student at {result.studentAt}</p>}
          </div>
        </div>
      );
    }

    return (
      <div
        key={result.id}
        className="search-result-item"
        onClick={() => handleResultClick(result)}
      >
        <div className="search-result-content">
          {result.creator?.profilePicture && (
            <img
              src={result.creator.profilePicture}
              alt="Profile"
              className="search-result-avatar"
            />
          )}
          <div className="search-result-details">
            <h3>{result.title}</h3>
            {result.description && <p>{result.description}</p>}
            {result.displayInfo && (
              <div className="search-result-info">
                {result.displayInfo.map((info, index) => (
                  <span key={index}>{info}</span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleMarkAllAsRead = async () => {
    try {
      // Mark all notifications as read in the backend
      await taxios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/notifications/mark-all-read`
      );

      // Clear all notifications from state
      setNotifications([]);
      setUnreadCount(0);
      
      // Close the dropdown
      setNotificationDropdownOpen(false);
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  const renderSearchResults = () => {
    if (isLoading) {
      return <div className="search-loading">Searching...</div>;
    }

    if (!searchResults.results || searchResults.results.length === 0) {
      return <div className="search-no-results">No results found</div>;
    }

    const groupedResults = searchResults.results.reduce((acc, result) => {
      const category = result.type;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(result);
      return acc;
    }, {});

    return (
      <div className="top-nav-bar__search-results">
        {Object.keys(groupedResults).map((category) => (
          <div key={category}>
            <div className="search-results-header">{category}</div>
            <div className="search-results-list">
              {groupedResults[category].map((result) =>
                renderResultItem(result)
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  return (
    <nav className="top-nav-bar">
      <div className="top-nav-bar__brand-container">
        <img
          src={logo}
          alt="Archi Logo"
          className="top-nav-bar__brand-logo"
          onClick={() => navigate("/app")}
        />
      </div>
      <div className="top-nav-bar__action-items">
        {location.pathname === "/courses" && (
          <button
            className="top-nav-bar__create-button"
            onClick={onCreateCourse}
          >
            Create a Course <FaPlus />
          </button>
        )}
        {location.pathname === "/seminars" && (
          <button
            className="top-nav-bar__create-button"
            onClick={onCreateEvent}
          >
            Create an Event <FaPlus />
          </button>
        )}
        {location.pathname === "/internships" && (
          <button
            className="top-nav-bar__create-button"
            onClick={onCreateInternship}
          >
            Create an Internship <FaPlus />
          </button>
        )}
        {location.pathname === "/app" && (
          <button
            className="top-nav-bar__study-group-button flex items-center"
            onClick={onCreateStudyGroup}
          >
            <span className="hidden sm:inline ">Create a Study Group</span><FaPlus className="mr-0"/>
          </button>

        )}
        <div className="top-nav-bar__search-container" ref={searchRef}>
          <div className="top-nav-bar__dropdown">
            <button className="top-nav-bar__dropbtn" onClick={toggleDropdown}>
              {selectedCategory} <FaCaretDown />
            </button>
            {isDropdownOpen && (
              <div className="top-nav-bar__dropdown-content">
                {[
                  "All",
                  "Educators",
                  "Students",
                  "Organizations",
                  "Courses",
                  "Seminars",
                  "Internships",
                  "Study Groups",
                ].map((category) => (
                  <a
                    key={category}
                    onClick={() => handleCategorySelect(category)}
                    href="#!"
                  >
                    {category}
                  </a>
                ))}
              </div>
            )}
          </div>
          <input
            type="text"
            placeholder="Search..."
            className="top-nav-bar__search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="top-nav-bar__search-icon">
            <FaSearch />
          </div>

          {/* Search Results Dropdown */}
          {showResults && (
            <div className="top-nav-bar__search-results">
              <div className="search-results-header">
                {isLoading ? (
                  <div className="search-loading">Searching...</div>
                ) : (
                  <div className="search-results-title">
                    Results for "{searchTerm}"
                  </div>
                )}
              </div>

              <div className="search-results-list">
                {searchResults.results.length > 0 ? (
                  searchResults.results.map((result) =>
                    renderResultItem(result)
                  )
                ) : (
                  <div className="search-no-results">No results found</div>
                )}
              </div>

              {searchResults.results.length > 0 && (
                <div className="search-results-footer">
                  <button onClick={() => setShowResults(false)}>Close</button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
     <div className="top-nav-bar__icons">
        <div className="top-nav-bar__icon" onClick={() => navigate("/chat")}>
          <FaComments className="top-nav-bar__icon-chats" />
          <span className="top-nav-bar__icon-label">Chats</span>
        </div>

        <div
          className="top-nav-bar__icon top-nav-bar__notification-section"
          onClick={toggleNotificationDropdown}
        >
          <FaBell className="top-nav-bar__icon-notifications" />
          <span className="top-nav-bar__icon-label">Notifications</span>
          {unreadCount > 0 && (
            <span className="notification-badge">{unreadCount}</span>
          )}
        </div>

        <div
          className="top-nav-bar__icon top-nav-bar__profile-section"
          onClick={toggleProfileDropdown}
        >
          <FaUserAlt className="top-nav-bar__icon-profile" />
          <span className="top-nav-bar__icon-label">Profile</span>
        </div>

        {/* Profile Dropdown */}
       {isProfileDropdownOpen && (
        <div className="top-nav-bar__profile-dropdown">
          <Link to="/profile" className="profile-dropdown-item">
            <FaUserAlt className="dropdown-icon" />
            <span>Edit Profile</span>
            <FaChevronRight className="arrow-icon" />
          </Link>
          <Link to="/settings" className="profile-dropdown-item">
            <FaCog className="dropdown-icon" />
            <span>Settings & Privacy</span>
            <FaChevronRight className="arrow-icon" />
          </Link>
          {/* <Link to="/help" className="profile-dropdown-item">
            <FaQuestionCircle className="dropdown-icon" />
            <span>Help & Support</span>
            <FaChevronRight className="arrow-icon" />
          </Link>
          <Link to="/display" className="profile-dropdown-item">
            <FaMoon className="dropdown-icon" />
            <span>Apperance</span>
            <FaChevronRight className="arrow-icon" />
          </Link> */}
          <hr className="profile-divider" />
          <button onClick={handleLogout} className="profile-dropdown-item logout">
            <FaSignOutAlt className="dropdown-icon" />
            <span>Logout</span>
            <FaChevronRight className="arrow-icon logout" />
          </button>
        </div>
      )}

        {/* Notification Dropdown */}
        {isNotificationDropdownOpen && (
          <div className="top-nav-bar__notification-dropdown">
            {notifications.length > 0 ? (
              notifications
                .sort(
                  (a, b) =>
                    new Date(secondTS(b.timeStamp)) - new Date(secondTS(a.timeStamp))
                )
                .map((notif) => (
                  <div
                    key={notif?._id || notif?.id}
                    className={`notification-item ${!notif?.read ? "unread" : "read"}`}
                    onClick={() => handleNotificationClick(notif)}
                  >
                    <div className="notification-content">
                      <div className="notification-message">{notif?.message}</div>
                        <div className="notification-timestamp">
                          {notif.timeStamp ? new Date(Number(notif.timeStamp)).toLocaleString() : "Invalid Date"}
                        </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="no-notifications">No notifications</div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
}

function secondTS(ts){
	return Math.floor(ts / 1000);
}

export default TopNavBar;
