// src/components/ProfilePicture.js
import React from 'react';
import styled from 'styled-components';

const ProfilePictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ddd;
`;

const placeholderImage = "/unknown.png";

const ProfilePicture = ({ src, alt }) => {
  return (
    <ProfilePictureContainer>
      <img src={src ?? placeholderImage} alt={alt ?? "UNKNOWN"} style={{ width: "100%", height: "auto" }} />
    </ProfilePictureContainer>
  );
};

export default ProfilePicture;
