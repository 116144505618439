import { create } from "zustand";

/** Contains the ratings of the currently loaded questions and comments; state management done by Zustand. */
const useRatingStore = create((set) => {
	console.log("Rating store has been created"); //Log when the store is created

    return {
		//Holds the currently loaded ratings in a map keyed by the ID of the post.
		ratings: {},

		//Sets a new rating in the map.
		setRating: (id, rating) => set((state) => ({
			ratings: { ...state.ratings, [id]: rating }
		})),

		//Updates an existing rating in the map.
		updateRating: (id, updateFn) => set((state) => ({
			ratings: {
				...state.ratings,
				[id]: updateFn(state.ratings[id] || {})
			}
		})),

		//Gets the value of a particular rating from the map.
		getRating: (id) => useRatingStore.getState().ratings[id],

		//Clears all ratings from the map.
		clearAllRatings: () => {
			console.log("all ratings have been purged");
			set({ ratings: {} });
		}
	};
});

export default useRatingStore;