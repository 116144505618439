import React from 'react';
import './GoogleSignIn.css';
import GoogleIcon from '../../../assets/google-icon.svg';

const GoogleSignIn = () => {
  return (
    <button className="google-signin-button">
      <img src={GoogleIcon} alt="Google icon" className="google-icon" /> 
      Sign up with Google
    </button>
  );
};

export default GoogleSignIn;
