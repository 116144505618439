import { create } from "zustand";

/** Contains the currently loaded study groups; state management done by Zustand. */
const useStudyGroupStore = create((set, get) => ({
	//Holds the currently loaded study groups.
	groupMap: {},

	//Holds the study group list for the state store.
	selectedGroup: null,

	//Holds whether the creation form is open.
	showCreationForm: false,

	//Holds whether the group map is populated.
	isPopulated: false,

	//Sets the contents of the study group map.
	setGroupMap: (newMap) => set((state) => ({ groupMap: { ...state.groupMap, ...newMap } })),

	//Sets the currently selected study group for the state store.
	setSelectedGroup: (group) => set({ selectedGroup: group }),

	//Toggles the visibility of the group creation form.
	toggleShowCreationForm: () => set((state) => ({ showCreationForm: !state.showCreationForm })),

	//Sets whether the group map is populated.
	setIsPopulated: (populated) => set({ isPopulated: populated }),

	//Adds a new group to the group map.
	addGroup: (newGroup) => set((state) => ({
		groupMap: { ...state.groupMap, [newGroup._id]: newGroup }
	})),

	//Returns the group list as an array for displaying. The "ideal" ordering is also used.
	garray: () => {
		//Get the local state objects that are needed
		const { groupMap } = get();

		//Create the output array from the existing map
		let groups = Object.values(groupMap).slice(); //Shallow copy is performed to avoid mutating the state

		//Eject the first item from the array, reverse it, and add the item back
		const first = { ...groups.shift() }; //Again, do not mutate the state. JS is really weird
		groups.reverse();
		groups.unshift(first);
		
		//Return the built array
		return groups;
	}
}));

export default useStudyGroupStore;
