import copy from "copy-to-clipboard";
import { FaComment } from "react-icons/fa";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { RiShareForwardFill } from "react-icons/ri";
import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";

import { getUInfo } from "../../../util/whoami";
import taxios from "../../../util/token_refresh_hook";
import useRatingStore from "../QuestionList/ratingStore";

import "./QuestionActions.css";

/**
 * Declares a question/comment action component.
 * @component
 * @param {Object} props The props for the component
 * @param {Question} props.question The question itself
 * @param {String} props.id The ID of the question/comment
 * @param {Author} props.author The author of the question/comment
 * @param {?Rating} props.irating The ratings of the question/comment
 * @param {Boolean} props.isComment Whether this is a comment
 * @param {Function} props.onHitCommentBtn The function to run when the user opts to comment on a post
 * @return {*} The rendered component
 */
export default function QuestionActions({ question, id, author, irating, isComment, onHitCommentBtn }) {
	//Set the states of the component
	const [isMyPost, setIsMyPost] = useState(false); // Whether the user is the author of the post
	const { setRating, updateRating, getRating } = useRatingStore(); // Zustand store decls for the ratings

	//Check if the rating exists in the Zustand store
	const existingRating = getRating(id);
	
	//Initialize the rating using irating if it doesn't exist in the store
	const initialRating = existingRating || {
		likes: irating?.likes || 0,
		dislikes: irating?.dislikes || 0,
		your_rating: irating?.your_rating || 0,
	};

	//Populate the component state when it loads
	useEffect(() => {
		//Only set the rating if it doesn't already exist in the store
		if(!existingRating){
			setRating(id, {
				likes: irating?.likes || 0,
				dislikes: irating?.dislikes || 0,
				your_rating: irating?.your_rating || 0,
			});
		}
		setIsMyPost(author._id === getUInfo().id);
	}, [id, irating, author._id, setRating, existingRating]);

	/** Updates the rating state when a user likes or dislikes a post. Thanks Perplexify! */
	const setMyRating = (val) => {
		updateRating(id, (prev) => {
			let newLikes = prev.likes;
			let newDislikes = prev.dislikes;

			if (val === 1) {
				newLikes = prev.your_rating === 1 ? prev.likes : prev.likes + 1;
				if (prev.your_rating === -1) {
					newDislikes -= 1;
				}
			} else if (val === -1) {
				newDislikes = prev.your_rating === -1 ? prev.dislikes : prev.dislikes + 1;
				if (prev.your_rating === 1) {
					newLikes -= 1;
				}
			} else {
				if (prev.your_rating === 1) {
					newLikes -= 1;
				} else if (prev.your_rating === -1) {
					newDislikes -= 1;
				}
			}

			return {
				...prev,
				your_rating: val,
				likes: newLikes,
				dislikes: newDislikes,
			};
		});
	};

	/** Handles when a user dislikes the post. */
	const handleDislike = async () => {
		//Bail out if the user is the author
		if (isMyPost) return;

		//Dislike the post
		try {
			//Check if the user already disliked the post
			if (initialRating.your_rating === -1) {
				//If already disliked, remove the dislike
				await taxios.post(`${getBaseReqURL(id, isComment)}/undislike`);
				setMyRating(0); //Clear rating
			} else {
				//Otherwise, set the dislike
				await taxios.post(`${getBaseReqURL(id, isComment)}/dislike`);
				setMyRating(-1); //Set dislike
			}
		}
		catch (error) {
			console.error("Error updating dislike:", error.response ? error.response.data : error.message);
		}
	};

	/** Handles when a user likes the post. */
	const handleLike = async () => {
		//Bail out if the user is the author
		if (isMyPost) return;

		try {
			//Check if the user already liked the post
			if (initialRating.your_rating === 1) {
				//If already liked, remove the like
				await taxios.post(`${getBaseReqURL(id, isComment)}/unlike`);
				setMyRating(0); //Clear rating
			} else {
				//Otherwise, set the like
				await taxios.post(`${getBaseReqURL(id, isComment)}/like`);
				setMyRating(1); //Set like
			}
		} 
		catch (error) {
			console.error("Error updating like:", error.response ? error.response.data : error.message);
		}
	};

	/** Handles when a user clicks the share button. Default strategy: copy the question info to the clipboard. */
	const handleShare = (event) => {
		//Use event.currentTarget to get the DOM element of the button
		const btn = event.currentTarget;
		btn.classList.add("animate");

		//Get the post data and add it to the clipboard
		const postData =
			`==+--- ${isComment ? "comment" : "post"} with ID ${question._id} ---+==\n` +
			`${author.username} <id: ${author._id}> ` +
			`@ ${question.createdAt}\n` +
			`${question.subject ? `subject: ${question.subject}\n` : ""}` +
			`${question.body}\n` +
			`rating: ${initialRating.likes + initialRating.dislikes} ` +
			`(${initialRating.likes} like${initialRating.likes !== 1 ? "s" : ""}, ` +
			`${initialRating.dislikes} dislike${initialRating.dislikes !== 1 ? "s" : ""})\n` +
			`${question.tags ? `tags: ${question.tags.join(", ")}\n` : ""}`
		.trim();
		copy(postData);

		//Remove the animation class after it completes
		setTimeout(() => {
			btn.classList.remove("animate");
		}, 600); // 600ms duration
	}

	//Set constants for "no reaction allowed" tooltips
	const tooltipId = "no-react-allowed";
	const tooltipDesc = `You may not react to your own ${isComment ? "comment" : "post"}`;
	const tooltipPlacement = "top";

	//Render the component
	return (
		<div className="question-actions">
			<button 
				className={
					`interaction-btn like-button 
					${initialRating.your_rating === 1 ? "liked" : ""}
					${isMyPost ? "self" : ""}`
				}
				onClick={handleLike}
				{...(isMyPost && {
					"data-tooltip-id": tooltipId,
					"data-tooltip-content": tooltipDesc,
					"data-tooltip-place": tooltipPlacement
				})}
			>
				<FiThumbsUp className="icon like-icon" />
				<span className="rating-count">{initialRating.likes}</span>
			</button>
			<button 
				className={
					`interaction-btn dislike-button 
					${initialRating.your_rating === -1 ? "disliked" : ""}
					${isMyPost ? "self" : ""}`
				}
				onClick={handleDislike}
				{...(isMyPost && {
					"data-tooltip-id": tooltipId,
					"data-tooltip-content": tooltipDesc,
					"data-tooltip-place": tooltipPlacement
				})}
			>
				<FiThumbsDown className="icon dislike-icon" />
				<span className="rating-count">{initialRating.dislikes}</span>
			</button>
			{!isComment && (
				<button className="interaction-btn comment-button" onClick={onHitCommentBtn}>
					<FaComment className="icon" />
					<span>Comment</span>
				</button>
			)}
			<button className="interaction-btn share-button" onClick={handleShare}>
				<RiShareForwardFill className="icon" />
				<span>Share</span>
			</button>
			{isMyPost && <></>}

			{/* == DEBUG START == * /}
			<br />
			<span className="debug">Rating (in):	 {JSON.stringify(irating)}</span>
			<span className="debug">Rating (cached): {JSON.stringify(initialRating)}</span>
			{/ * == DEBUG END == */}
		</div>
	);
}

/**
 * Builds the base request URL.
 * @param {Boolean} isComment Whether the post in question is a comment
 * @param {String} id The ID of the post being interacted with
 * @return {String} The complete base URL for the request
 */
function getBaseReqURL(id, isComment) {
	const purl = isComment ? "comments" : "posts";
	return `${process.env.REACT_APP_BACKEND_URL}/api/${purl}/${id}`;
}
