import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";

import fetcher from "../../../util/fetcher";
import useStudyGroupStore from "./studyGroupStore.js";

import "./StudyGroupList.css";

/** Declares a self-managed study group list component. */
export default function StudyGroupList(){
	//Setup component states
	const [searchParams, setSearchParams] = useSearchParams();	//Tracker for search params
	const listRef = useRef(null);
	const groupRefs = useRef({}); 								//Ref to store individual group elements
	const { 
		groupMap, selectedGroup, setGroupMap, garray,
		setSelectedGroup, setIsPopulated, isPopulated 
	} = useStudyGroupStore(); //Zustand store decls	

	//Fetch data
	const { data, error } = useSWR(`${process.env.REACT_APP_BACKEND_URL}/api/study_groups/all`, fetcher);

	//Update the component state when the data loads
	useEffect(() => {
		//Check if the data is finished loading and the list isn't already populated
		if(data && data.payload && !isPopulated){
			//Pull down the API response data and get the first element
			const gmap = data.payload;
			const first = Object.entries(gmap)[0] ? Object.entries(gmap)[0][1] : null;

			//Get the search params and assign the selected group if the param is valid
			const spGroup = searchParams.get("group");
			if(spGroup && gmap[spGroup]){
				setSelectedGroup(gmap[spGroup]);
			} 
			else {
				setSelectedGroup(first);
			}

			//Set the contents of the group map and mark it as populated
			setGroupMap(gmap);
			setIsPopulated(true);
		}
	}, [data, searchParams, setGroupMap, setSelectedGroup, setIsPopulated, groupMap, isPopulated]);

	//Scroll to the selected group when it changes
	useEffect(() => {
		if(selectedGroup && groupRefs.current[selectedGroup._id]){
			groupRefs.current[selectedGroup._id].scrollIntoView({ behavior: "smooth", block: "nearest" });
		}
	}, [selectedGroup]);	  

	//Loading & error states
	if(!data && !error) return <div>Loading...</div>;
	if(error) return <div style={{color: "#F00"}}><span style={{fontWeight: "bold"}}>Error:</span> {error.message}</div>;
	
	//Setup left scroll handler
	const scrollLeft = () => {
		if(listRef.current){
			listRef.current.scrollBy({ left: -200, behavior: "smooth" });
		}
	};

	//Setup right scroll handler
	const scrollRight = () => {
		if(listRef.current){
			listRef.current.scrollBy({ left: 200, behavior: "smooth" });
		}
	};

	//Setup group changer; this persists the state in the search params
	const handleGroupClick = (group) => {
		//console.log("clicked group", group);
		setSelectedGroup(group);
		setSearchParams({ group: group._id });
	};
	
	//Render the component
	return (
		<div className="study-group-list-container">
			<button className="scroll-button left" onClick={scrollLeft}><FaArrowLeft /></button>
			<div className="study-group-list-wrapper" ref={listRef}>
				<div className="study-group-list">
					{garray().map((grp, index) => (
						<div
							key={grp._id || index}
							ref={(el) => (groupRefs.current[grp._id] = el)} // Assign ref to each group
							className={`study-group-item ${selectedGroup ? (selectedGroup._id === grp._id ? "selected" : "") : ""}`}
							onClick={() => handleGroupClick(grp)}
						>
							{grp.name || "Untitled"}
							{/*
							{selectedGroup === group && (
								<div className="group-description">
									<p>{group.description}</p>
								</div>
							)}
							*/}
						</div>
					))}
				</div>
			</div>
			<button className="scroll-button right" onClick={scrollRight}><FaArrowRight /></button>
		</div>
	);
}