import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { useCookies } from "react-cookie";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import HomePage from "./components/HomePage/HomePage";
import LoginSignupPage from "./components/LoginSignup/LoginSignupPage";
import LoginPage from "./components/LoginSignup/Login/LoginPage";
import SignupPage from "./components/LoginSignup/Register/SignupPage";
import StudentPage from "./components/LoginSignup/User/StudentPage";
import EducatorPage from "./components/EducatorPage/EducatorPage";
import OrganizationPage from "./components/OrganizationPage/OrganizationPage";
import CoursesPage from "./components/CoursesPage/CoursesPage";
import CourseProfilePage from "./components/CoursesPage/CourseProfilePage"; // Import the CourseProfilePage
import SeminarsPage from "./components/SeminarsPage/SeminarsPage";
import InternshipsPage from "./components/InternshipsPage/InternshipsPage";
import ChatPage from "./components/ChatPage/ChatPage";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import CourseCreationPage from "./components/CoursesPage/CourseCreationPage/CourseCreationPage"; // Import CourseCreationPage
import EventCreationPage from "./components/SeminarsPage/EventCreationPage"; // Import CourseCreationPage
import InternshipCreationPage from "./components/InternshipsPage/InternshipCreationPage"; // Import CourseCreationPage
import SettingsPage from "./components/SettingsPage/SettingsPage";
import TermsOfService from "./components/TermsOfServicePage/TermsOfService";
import AccessPage from "./components/AccessPage/AccessPage";
import QuestionThread from "./components/HomePage/QuestionThread/QuestionThread";
import taxios from "./util/token_refresh_hook";

import { clearUInfo, setUInfoFromPayload } from "./util/whoami";
import "./App.css";
import "./index.css";
import PostPage from "./components/PostPage/PostPage";

const PrivateRoute = ({ children }) => {
  const [cookies] = useCookies(["authTokenExpr", "refreshTokenExpr"]);
  const navigate = useNavigate();

  useEffect(() => {
    const hasToken =
      cookies.refreshTokenExpr !== undefined &&
      cookies.refreshTokenExpr !== null;
    const notExpired = cookies.refreshTokenExpr
      ? new Date(cookies.refreshTokenExpr).getTime() >= Date.now()
      : false;

    if (!hasToken || !notExpired) {
      navigate("/login");
    }
  }, [cookies.refreshTokenExpr, navigate]);

  return children;
};

const QuestionThreadWrapper = () => {
  const { id } = useParams();
  const [question, setQuestion] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await taxios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/posts/by_id/${id}`
        );
        setQuestion(response.data);
      } catch (error) {
        console.error("Error fetching question:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchQuestion();
    }
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <QuestionThread
      question={question}
      sidebarOpen={false}
      toggleSidebar={() => {}}
      onLogout={() => {}}
    />
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function App() {
  const [courses, setCourses] = useState([]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [cookies] = useCookies(["authTokenExpr", "refreshTokenExpr"]);

  const checkAuthToken = () => {
    const hasToken =
      cookies.refreshTokenExpr !== undefined &&
      cookies.refreshTokenExpr !== null;
    const notExpired = cookies.refreshTokenExpr
      ? new Date(cookies.refreshTokenExpr).getTime() >= Date.now()
      : false;
    const isRootOrLogin =
      location.pathname === "/" ||
      location.pathname === "/login" ||
      location.pathname === "/signup" ||
      location.pathname === "/access" || //DELETE AFTER BETA TESTER PHASE
      location.pathname === "/LoginSignupPage"; //DELETE AFTER BETA TESTER PHASE

    if (hasToken && notExpired) {
      setIsLoggedIn(true);
      if (isRootOrLogin) {
        navigate("/app");
      }
    } else {
      setIsLoggedIn(false);
      if (!isRootOrLogin) {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    checkAuthToken();
  });

  useEffect(() => {
    if (isLoggedIn) {
      //loadStudyGroups();
    }
  }, [isLoggedIn]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogin = (res) => {
    setIsLoggedIn(true);
    setUInfoFromPayload(res);
    navigate("/app");
  };

  const handleSignup = (res) => {
    setIsLoggedIn(true);
    setUInfoFromPayload(res);
    navigate("/app");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    clearUInfo();
    navigate("/");
  };

  const handleAccessSuccess = () => {
    //DELETE AFTER BETA TESTER PHASE
    navigate("/LoginSignupPage");
  };

  //Deprecated!
  /*
  const addCourse = (course) => {
    setCourses((prevCourses) => [...prevCourses, course]);
  };
  */

  return (
    <Elements stripe={stripePromise}>
      <Routes>
        {/* ADD THIS BACK AFTER BETA TESTER PHASE */}
        {/* <Route
          path="/"
          element={
            <LoginSignupPage
              onLogin={() => navigate("/login")}
              onSignup={() => navigate("/signup")}
            />
          }
        /> */}
        <Route
          path="/"
          element={<AccessPage onSuccess={handleAccessSuccess} />}
        />
        <Route
          path="/LoginSignupPage"
          element={
            <LoginSignupPage
              onLogin={() => navigate("/login")}
              onSignup={() => navigate("/signup")}
            />
          }
        />
        <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
        <Route
          path="/signup"
          element={<SignupPage onSignup={handleSignup} />}
        />
        <Route path="/student" element={<StudentPage />} />
        <Route
          path="/educator"
          element={
            <EducatorPage
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggleSidebar}
              onLogout={handleLogout}
              //studyGroups={studyGroups}
              //createStudyGroup={createStudyGroup}
            />
          }
        />
        <Route
          path="/organization"
          element={
            <OrganizationPage
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggleSidebar}
              onLogout={handleLogout}
              //studyGroups={studyGroups}
              //createStudyGroup={createStudyGroup}
            />
          }
        />
        <Route
          path="/courses"
          element={
            <CoursesPage
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggleSidebar}
              onLogout={handleLogout}
              onCourseClick={(courseId) => navigate(`/course/${courseId}`)} // Pass the onCourseClick prop
            />
          }
        />
        <Route path="/course-creation" element={<CourseCreationPage />} />{" "}
        {/* Add CourseCreationPage route */}
        <Route path="/course/:id" element={<CourseProfilePage />} />{" "}
        {/* Add CourseProfilePage route */}
        <Route
          path="/seminars"
          element={
            <SeminarsPage
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggleSidebar}
              onLogout={handleLogout}
            />
          }
        />
        <Route path="/event-creation" element={<EventCreationPage />} />{" "}
        {/* Add EventCreationPage route */}
        <Route
          path="/internships"
          element={
            <InternshipsPage
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggleSidebar}
              onLogout={handleLogout}
            />
          }
        />
        <Route
          path="/internship-creation"
          element={<InternshipCreationPage />}
        />{" "}
        {/* Add InternshipCreationPage route */}
        <Route
          path="/chat"
          element={
            <ChatPage
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggleSidebar}
              onLogout={handleLogout}
            />
          }
        />
        <Route
          path="/profile"
          element={
            <ProfilePage
              onLogout={handleLogout}
              //studyGroups={studyGroups}
              //createStudyGroup={createStudyGroup}
              //editStudyGroup={editStudyGroup}
              //deleteStudyGroup={deleteStudyGroup}
            />
          }
        />
        <Route
          path="/profile/:id"
          element={
            <ProfilePage
              onLogout={handleLogout}
              //studyGroups={studyGroups}
              //createStudyGroup={createStudyGroup}
              //editStudyGroup={editStudyGroup}
              //deleteStudyGroup={deleteStudyGroup}
            />
          }
        />
        <Route
          path="/settings"
          element={<SettingsPage onLogout={handleLogout} />}
        />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route
          path="/posts/:id"
          element={
            <PostPage
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggleSidebar}
              onLogout={handleLogout}
            />
          }
        />
        {/* <Route 
          path="/posts/by_id/:id" 
          element={
            <PrivateRoute>
              <QuestionThreadWrapper />
            </PrivateRoute>
          } 
        /> */}
        {isLoggedIn && (
          <Route
            path="/app"
            element={
              <HomePage
                //questions={questions}
                //setQuestions={setQuestions}
                //onLike={(id, change) => setQuestions(questions.map((q, index) => (index === id ? { ...q, likes: q.likes + change } : q)))}
                //onDislike={(id, change) => setQuestions(questions.map((q, index) => (index === id ? { ...q, dislikes: q.dislikes + change } : q)))}
                //onComment={(id, updatedComments) => setQuestions(questions.map((q, index) => (index === id ? { ...q, comments: updatedComments.length } : q)))}
                /*
        onShare={(id) => {
                const postLink = `${window.location.href}#question-${id}`;
                navigator.clipboard.writeText(postLink).then(() => {
                  alert('Post link copied to clipboard!');
                });
              }}
          */
                toggleSidebar={toggleSidebar}
                sidebarOpen={sidebarOpen}
                //setShowCreateForm={setShowCreateForm}
                //showEditForm={false}
                //setShowEditForm={() => { }}
                handleLogout={handleLogout}
                //courses={courses} // Pass courses state to HomePage
              />
            }
          />
        )}
        <Route
          path="*"
          element={
            <LoginSignupPage
              onLogin={() => navigate("/login")}
              onSignup={() => navigate("/signup")}
            />
          }
        />
      </Routes>
    </Elements>
  );
}

export default App;
