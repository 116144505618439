import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import taxios from '../../util/token_refresh_hook';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import QuestionThread from '../HomePage/QuestionThread/QuestionThread';
import './PostPage.css';

const PostPage = ({ toggleSidebar, sidebarOpen, onLogout }) => {
    const {id} = useParams();
    const [post, setPost] = useState()

    useEffect(() => {
        const fetchPost = async () => {
            const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/posts/by_id/${id}`)
            setPost(response.data.payload)
        }
        fetchPost();
    }, [])


    return (
        <div className="post-page">
        <TopNavBar toggleSidebar={toggleSidebar} onLogout={onLogout} />
        <CommunitySidebar isOpen={sidebarOpen} />
        <div className={`content ${sidebarOpen ? 'sidebar-open' : ''}`}>

            
            {post && (<div className="main-section">
                <QuestionThread question={post}/>
            </div>)}
        </div>
        </div>
    );
};

export default PostPage;
