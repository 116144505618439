import React, { useState, useEffect, useRef } from 'react';
import './MediaDisplay.css';

const MediaDisplay = ({ mediaType, mediaKey }) => {
  const [mediaUrl, setMediaUrl] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!mediaKey) return;

    // Construct the direct S3 URL based on media type
    const baseUrl = 'https://archi-video-bucket.s3.us-west-1.amazonaws.com';
    const folder = mediaType === 'image' ? 'thumbnails' : 'videos';
    const url = `${baseUrl}/${folder}/${mediaKey}`;

    setMediaUrl(url);
  }, [mediaKey, mediaType]);

  useEffect(() => {
    if (mediaType !== 'video') return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          setIsVisible(entry.isIntersecting);
          const video = videoRef.current;
          
          if (entry.isIntersecting && video) {
            if (video.ended) {
              video.currentTime = 0;
            }
            video.play().catch(e => {
              console.log("Playback error:", e);
              setTimeout(() => {
                video.play().catch(console.error);
              }, 1000);
            });
          } else if (video) {
            video.pause();
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: '50px 0px'
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [mediaUrl, mediaType]);

  if (mediaType === 'image') {
    return (
      <div className="media-container">
        {mediaUrl ? (
          <img
            src={mediaUrl}
            alt="Post content"
            className="media-content"
          />
        ) : (
          <div className="media-loading">Loading image...</div>
        )}
      </div>
    );
  }

  return (
    <div className="media-container" ref={containerRef}>
      {mediaUrl && (
        <>
          <video
            ref={videoRef}
            className="media-content"
            loop
            muted={isMuted}
            playsInline
            preload="metadata"
            autoPlay={isVisible}
            controls
          >
            <source src={mediaUrl} type="video/mp4" />
          </video>
          {isVisible && (
            <button
              className="volume-toggle"
              onClick={() => setIsMuted(!isMuted)}
            >
              {isMuted ? "🔇" : "🔊"}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default MediaDisplay;
