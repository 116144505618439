/**
 * Gets a param from the URL params or sets the default.
 * @returns The requested param or the default value
 */
export function getParam(key, defaultVal){
	const params = new URLSearchParams(window.location.search);
	return parseInt(params.get(key)) || defaultVal;
}

/**
 * Updates the value of a key in the URL parameters.
 * @param {*} newVal The value to set for the param
 */
export function setParam(key, newVal){
	const params = new URLSearchParams(window.location.search);
	params.set(key, newVal.toString());
	window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
}