import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaSchool, FaBriefcase, FaEdit, FaEnvelope } from 'react-icons/fa';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import './ProfilePage.css';
import taxios from '../../util/token_refresh_hook';
import { toast } from 'react-toastify';
import Post from "../HomePage/QuestionThread/Post";
import QuestionThread from '../HomePage/QuestionThread/QuestionThread';

const StudentProfilePage = ({ onLogout, studyGroups, createStudyGroup, editStudyGroup, deleteStudyGroup, profileInfo, currentUser }) => {

  // Enumerator that contains the tab bar names. To be used for a useState that checks the selected tab
  const tabEnum = {
    HOME: 'home',
    POSTS: 'posts',
    STUDYGROUPS: 'studygroups',
    COURSES: 'courses'
  };
  const [tabSelected, setTabSelected] = useState(tabEnum.POSTS);
  // Make sure to use the tabEnum object to set this value
  const changeTab = (tab) => {
    if(Object.values(tabEnum).includes(tab)){
      setTabSelected(tab);
    }
    else console.error(`Invalid tab value: ${tab}`);
  };

  const [posts, setPosts] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [courses, setCourses] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [banner, setBanner] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);


  // Check for query params in the URL indicating a specific profile to fetch
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const profileUname = searchParams.get('uname');
  


  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleBannerUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setBanner(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Fetch content based off tab selected
  useEffect(() => {

    const fetchPosts = async () => {
      switch(tabSelected){
        // Fetch the user's posts if there are no posts currently populated
        case tabEnum.POSTS:
          if(posts.length === 0){
            try {
              const response = await taxios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/posts/by_author/${profileInfo.username}`);
              setPosts(response.data.payload.posts)
            } catch (error) {
              console.error(`Error fetching posts for user ${profileInfo.username}:`, error);
              toast.error('Failed to load posts');
              return [];
            }
          }
          break;
        default:
          break;
      }
    }
    
    fetchPosts();
  }, [tabSelected, currentUser]) 

  // Handle the functionality of the follow button
  const handleFollow = async () => {
    try {
      const endpoint = isFollowing
        ? `${process.env.REACT_APP_BACKEND_URL}/api/users/unfollow/${profileInfo._id}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/users/follow/${profileInfo._id}`;
      await taxios.post(endpoint);
      setIsFollowing(!isFollowing);
    } catch (error) {
      console.error(`Error ${isFollowing ? 'unfollowing' : 'following'} profile:`, error);
    }
  };

  // Check if the current user is currently following the profile being viewed
  useEffect(() => {
    const followStatus = profileInfo.followers.includes(currentUser._id)
    setIsFollowing(followStatus)
  }, [])


  return (
    <div className="profile-page">
      <TopNavBar 
        toggleSidebar={toggleSidebar} 
        onLogout={onLogout} 
        onCreateStudyGroup={createStudyGroup} 
        onEditStudyGroup={editStudyGroup} 
        deleteStudyGroup={deleteStudyGroup} 
        studyGroups={studyGroups} 
      />
      <div className="profile-main-content">
        <CommunitySidebar isOpen={sidebarOpen} />
        <div className="profile-container">
          <div className="profile-banner">
            {banner ? (
              <img src={banner} alt="Banner" className="banner-image" />
            ) : (
              profileInfo._id === currentUser._id &&
              (<label htmlFor="banner-upload" className="banner-upload-label">
                Upload a banner image or GIF
                <input type="file" id="banner-upload" accept="image/*" onChange={handleBannerUpload} />
              </label>)
            )}
          </div>
          <div className="profile-header">
            <div className="profile-info">
              

              <div className="profile-picture">
                <img src={profileInfo.avatar} alt="Profile" />
              </div>

              <div className="profile-details">
                <h1>{profileInfo.username}</h1>
                <p>{profileInfo.description}</p>
                <div className="profile-stats">
                  <span>{profileInfo.followers.length} Followers</span>
                  <span>{profileInfo.following.length} Following</span>
                </div>
                
                {/* Action buttons. Edit if it's the current user and follow/message otherwise */}
                {profileInfo._id === currentUser._id ? (
                  <div className='profile-actions'>
                    <button className="edit-button button-primary">
                      <FaEdit /> Edit
                    </button>
                  </div>
                ) : (
                  <div className='profile-actions'>
                    <button className={`follow-button ${isFollowing ? "button-secondary" : "button-primary"}`} onClick={handleFollow}>
                      {isFollowing ? "Following" : "Follow"}
                    </button>
                    <button className="message-button button-primary">
                      <FaEnvelope /> Message
                    </button>
                  </div>
                )}                 
              
              </div>
            </div>
            <div className="profile-extra">
              <div className="extra-item">
                <FaBriefcase /> <span>{profileInfo.job_title}</span>
              </div>
              <div className="extra-item">
                <FaSchool /> <span>{profileInfo.school}</span>
              </div>
            </div>
          </div>
          <div className="tabs">
            <button 
              className={tabSelected === tabEnum.POSTS ? "tab current" : "tab"}
              onClick={() => changeTab(tabEnum.POSTS)}
            >Posts
            </button>

            <button 
              className={tabSelected === tabEnum.STUDYGROUPS ? "tab current" : "tab"}
              onClick={() => changeTab(tabEnum.STUDYGROUPS)}
            >Study Groups
            </button>

          </div>
          {/* Content specific to the student */}
          <div className="profile-content">

            {/* Home section content */}
            {tabSelected === tabEnum.HOME &&
            (<div id="home" className="profile-section">
              <h2>Home</h2>
            </div>)
            }

            {/* Posts section content */}
            {tabSelected === tabEnum.POSTS &&
            (posts.length > 0 ?
            <div id="posts" className="profile-section">
              {posts.map((post) => (
                <div key={post._id} className="post">
                        <QuestionThread question={post} />
                </div>
              ))}
            </div>
            : <h2> No posts yet </h2>)
            }

            {/* Study Groups section content */}
            {tabSelected === tabEnum.STUDYGROUPS &&
            (<div id="study-groups" className="profile-section">
              <h2>Study Groups</h2>
            </div>)
            }

          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfilePage;