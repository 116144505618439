import React, { useState, useEffect, useRef } from 'react';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import { FaStar, FaShare, FaCaretDown } from 'react-icons/fa';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import CreateStudyGroupForm from '../HomePage/CreateStudyGroupForm/CreateStudyGroupForm';
import taxios from '../../util/token_refresh_hook';
import { getUInfo } from '../../util/whoami';
import './OrganizationPage.css';
import { useNavigate } from 'react-router-dom';

const placeholderImage = 'https://via.placeholder.com/50';

const OrganizationPage = ({ toggleSidebar, sidebarOpen, onLogout, studyGroups, createStudyGroup }) => {
  const [filterLocation, setFilterLocation] = useState('');
  const [filterIndustry, setFilterIndustry] = useState('');
  const [filterCompanySize, setFilterCompanySize] = useState('');
  const [sortFilter, setSortFilter] = useState('newest');
  const [organizations, setOrganizations] = useState([]);
  const [courses, setCourses] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
  const [isIndustryDropdownOpen, setIsIndustryDropdownOpen] = useState(false);
  const [isCompanySizeDropdownOpen, setIsCompanySizeDropdownOpen] = useState(false);
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const orgContainerRef = useRef(null);
  const orgLoadMoreRef = useRef(null);
  const courseLoadMoreRef = useRef(null);

  const currentUser = getUInfo();
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrganizations();
    fetchCourses();
  }, []);

  useEffect(() => {
    const orgObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreOrganizations();
      }
    });

    const courseObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreCourses();
      }
    });

    if (orgLoadMoreRef.current) {
      orgObserver.observe(orgLoadMoreRef.current);
    }
    if (courseLoadMoreRef.current) {
      courseObserver.observe(courseLoadMoreRef.current);
    }

    return () => {
      if (orgLoadMoreRef.current) {
        orgObserver.unobserve(orgLoadMoreRef.current);
      }
      if (courseLoadMoreRef.current) {
        courseObserver.unobserve(courseLoadMoreRef.current);
      }
    };
  }, [orgLoadMoreRef, courseLoadMoreRef]);

  const fetchOrganizations = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/organizations?limit=20`);
      const orgData = response.data.payload.users || [];
  
      // Format the data to ensure consistency
      const formattedOrgData = orgData.map(org => ({
        ...org,
        followers: Array.isArray(org.followers) ? org.followers : [],
        companySize: org.companySize || 0,
        name: org.organization_name || org.username,
        description: org.primary_service || 'No description available',
      }));
  
      setOrganizations((prevOrganizations) => [...prevOrganizations, ...formattedOrgData]);
    } catch (error) {
      console.error('Error fetching organizations:', error);
      setOrganizations([]);
    }
  };

  const fetchCourses = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/courses`);
      // Sort courses by newest first (descending order)
      const sortedCourses = response.data.payload.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setCourses((prevCourses) => [...prevCourses, ...sortedCourses]);
    } catch (error) {
      console.error("Error fetching courses:", error);
      setCourses([]);
    }
  };

  const loadMoreOrganizations = () => {
    fetchOrganizations();
  };

  const loadMoreCourses = () => {
    fetchCourses();
  };

  const handleFollow = async (organizationId, isFollowing) => {
    if (isProcessing) return;
    setIsProcessing(true);

    try {
      const endpoint = isFollowing
        ? `${process.env.REACT_APP_BACKEND_URL}/api/users/unfollow/${organizationId}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/users/follow/${organizationId}`;

      await taxios.post(endpoint);

      setOrganizations(prevOrganizations =>
        prevOrganizations.map(org =>
          org._id === organizationId
            ? {
                ...org,
                isFollowing: !isFollowing,
                followers: isFollowing
                  ? org.followers.filter(id => id !== currentUser.id)
                  : [...org.followers, currentUser.id]
              }
            : org
        )
      );
    } catch (error) {
      console.error(`Error ${isFollowing ? 'unfollowing' : 'following'} organization:`, error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  const filteredOrganizations = Array.isArray(organizations) ? organizations.filter((org) => {
    return (
      (!filterLocation || org.location === filterLocation) &&
      (!filterIndustry || org.industry === filterIndustry) &&
      (
        !filterCompanySize ||
        (filterCompanySize === '1000' && org.companySize < 1000) ||
        (filterCompanySize === '10000' && org.companySize < 10000) ||
        (filterCompanySize === '20000' && org.companySize < 20000) ||
        (filterCompanySize === '20001' && org.companySize >= 20000)
      )
    );
  }).sort((a, b) => {
    if (sortFilter === 'newest') {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
    if (sortFilter === 'oldest') {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    if (sortFilter === 'followers') {
      return (b.followers?.length || 0) - (a.followers?.length || 0);
    }
    return 0;
  }) : [];

  const handleShare = (courseTitle) => {
    const url = `https://example.com/courses/${courseTitle.replace(/\s+/g, '-').toLowerCase()}`;
    navigator.clipboard.writeText(url).then(() => {
      alert(`Link to "${courseTitle}" has been copied to clipboard: ${url}`);
    });
  };

  return (
    <div className="organization-page">
      <TopNavBar toggleSidebar={toggleSidebar} onLogout={onLogout} onCreateStudyGroup={() => setShowCreateForm(true)} />
      <CommunitySidebar isOpen={sidebarOpen} />
      <div className={`content ${sidebarOpen ? 'sidebar-open' : ''}`}>
        {showCreateForm && <CreateStudyGroupForm onCreate={createStudyGroup} onClose={() => setShowCreateForm(false)} />}
        <div className="filter-container">
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)}>
              Sort By <FaCaretDown className={`dropdown-icon ${isSortDropdownOpen ? 'open' : ''}`} />
            </div>
            {isSortDropdownOpen && (
              <div className="filter-options">
                <div className="filter-option" onClick={() => setSortFilter('newest')}>Newest</div>
                <div className="filter-option" onClick={() => setSortFilter('oldest')}>Oldest</div>
                <div className="filter-option" onClick={() => setSortFilter('followers')}>Followers</div>
              </div>
            )}
          </div>
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => setIsLocationDropdownOpen(!isLocationDropdownOpen)}>
              Location <FaCaretDown className={`dropdown-icon ${isLocationDropdownOpen ? 'open' : ''}`} />
            </div>
            {isLocationDropdownOpen && (
              <div className="filter-options">
                <div className="filter-option" onClick={() => setFilterLocation('New York')}>New York</div>
                <div className="filter-option" onClick={() => setFilterLocation('San Francisco')}>San Francisco</div>
              </div>
            )}
          </div>
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => setIsIndustryDropdownOpen(!isIndustryDropdownOpen)}>
              Industry <FaCaretDown className={`dropdown-icon ${isIndustryDropdownOpen ? 'open' : ''}`} />
            </div>
            {isIndustryDropdownOpen && (
              <div className="filter-options">
                <div className="filter-option" onClick={() => setFilterIndustry('Business Consulting and Services')}>Business Consulting and Services</div>
                <div className="filter-option" onClick={() => setFilterIndustry('Professional Services')}>Professional Services</div>
                <div className="filter-option" onClick={() => setFilterIndustry('Financial Services')}>Financial Services</div>
                <div className="filter-option" onClick={() => setFilterIndustry('Technology')}>Technology</div>
                <div className="filter-option" onClick={() => setFilterIndustry('Manufacturing')}>Manufacturing</div>
              </div>
            )}
          </div>
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => setIsCompanySizeDropdownOpen(!isCompanySizeDropdownOpen)}>
              Company Size <FaCaretDown className={`dropdown-icon ${isCompanySizeDropdownOpen ? 'open' : ''}`} />
            </div>
            {isCompanySizeDropdownOpen && (
              <div className="filter-options">
                <div className="filter-option" onClick={() => setFilterCompanySize('1000')}>Less than 1000</div>
                <div className="filter-option" onClick={() => setFilterCompanySize('10000')}>Less than 10000</div>
                <div className="filter-option" onClick={() => setFilterCompanySize('20000')}>Less than 20000</div>
                <div className="filter-option" onClick={() => setFilterCompanySize('20001')}>More than 20000</div>
              </div>
            )}
          </div>
        </div>
        <div className="main-section">
          <div className="organizations-container" ref={orgContainerRef}>
            {filteredOrganizations.map((org) => {
              const isFollowing = Array.isArray(org.followers) && org.followers.includes(currentUser.id);
              return (
                <div key={org._id} className="organization-card" onClick={() => navigate(`/profile/${org._id}`)}>
                  <div className="profile-picture">
                    <img src={org.avatar || placeholderImage} alt={`${org.name} profile`} />
                  </div>
                  <div className="organization-info">
                    <h3>{org.name} <span>•</span> {org.industry || 'Industry not specified'}</h3>
                    <p>{org.description}</p>
                    <span>{org.followers.length} Followers</span>
                    <span>{org.companySize ? org.companySize.toLocaleString() : 'Unknown'} Employees</span>
                  </div>
                  <button 
                    className={`follow-button ${isFollowing ? 'following' : ''}`}
                    onClick={() => handleFollow(org._id, isFollowing)}
                    disabled={isProcessing}
                  >
                    {isFollowing ? 'Following' : 'Follow'}
                  </button>
                </div>
              );
            })}
            <div ref={orgLoadMoreRef}></div>
          </div>

          <div className="courses-container">
            <h2 className="courses-title">Courses</h2>
            <div className="courses-list">
              {courses.map((course, index) => (
                <div 
                  key={course._id || index} 
                  className="course-item"
                  onClick={() => handleCourseClick(course._id)}
                >
                  <div className="course-thumbnail">
                    <img
                      src={
                        course?.courseThumbnailURL
                          ? course.courseThumbnailURL.startsWith('http')
                            ? course.courseThumbnailURL
                            : `https://archi-video-bucket.s3.us-west-1.amazonaws.com/thumbnails/${course.courseThumbnailURL}`
                          : 'https://via.placeholder.com/150'
                      }
                      alt="Course Thumbnail"
                    />
                  </div>
                  <div className="course-info">
                    <ProfilePicture 
                      src={course.creatorID.profilePicture || placeholderImage} 
                      alt={course.creatorID.username || 'Anonymous'} 
                    />
                    <div className="course-details">
                      <h2>{course.courseTitle}</h2>
                      <span className="creator-name">{course.creatorID.username}</span>
                      <div className="rating">
                        {[...Array(5)].map((_, i) => (
                          <FaStar key={i} color={i < Math.round(course.rating) ? "#ffc107" : "#e4e9f0"} />
                        ))}
                        <span>{course.rating?.toFixed(1)}</span>
                      </div>
                    </div>
                    <FaShare className="share-icon" onClick={() => handleShare(course.courseTitle)} />
                  </div>
                </div>
              ))}
              <div ref={courseLoadMoreRef}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationPage;
