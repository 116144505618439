import React, { useState, useEffect, useRef } from 'react';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import { FaStar, FaShare, FaChevronDown, FaStarHalfAlt } from 'react-icons/fa';
import taxios from '../../util/token_refresh_hook';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import CreateStudyGroupForm from '../HomePage/CreateStudyGroupForm/CreateStudyGroupForm';
import { useNavigate } from 'react-router-dom';
import { getUInfo } from '../../util/whoami';
import './EducatorPage.css';

const placeholderImage = 'https://via.placeholder.com/50';

const EducatorPage = ({ toggleSidebar, sidebarOpen, onLogout, studyGroups, createStudyGroup }) => {
  const [filterLocation, setFilterLocation] = useState('');
  const [filterSubject, setFilterSubject] = useState('');
  const [filterExperience, setFilterExperience] = useState('');
  const [filterRating, setFilterRating] = useState('');
  const [locationOpen, setLocationOpen] = useState(false);
  const [subjectOpen, setSubjectOpen] = useState(false);
  const [experienceOpen, setExperienceOpen] = useState(false);
  const [ratingOpen, setRatingOpen] = useState(false);
  const [educators, setEducators] = useState([]);
  const [courses, setCourses] = useState([]);
  const [locations, setLocations] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const eduContainerRef = useRef(null);
  const courseContainerRef = useRef(null);
  const eduLoadMoreRef = useRef(null);
  const courseLoadMoreRef = useRef(null);
  const currentUser = getUInfo();
  const navigate = useNavigate();

  useEffect(() => {
    fetchEducators();
    fetchCourses();
  }, []);

  useEffect(() => {
    const eduObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreEducators();
      }
    });

    const courseObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreCourses();
      }
    });

    if (eduLoadMoreRef.current) {
      eduObserver.observe(eduLoadMoreRef.current);
    }
    if (courseLoadMoreRef.current) {
      courseObserver.observe(courseLoadMoreRef.current);
    }

    return () => {
      if (eduLoadMoreRef.current) {
        eduObserver.unobserve(eduLoadMoreRef.current);
      }
      if (courseLoadMoreRef.current) {
        courseObserver.unobserve(courseLoadMoreRef.current);
      }
    };
  }, [eduLoadMoreRef, courseLoadMoreRef]);

  const fetchEducators = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/educators?limit=20`);
      const educatorsData = response.data.payload.users || [];

      // Collect unique values for dynamic filters
      const uniqueLocations = [...new Set(educatorsData.map(edu => edu.location))];
      const uniqueSubjects = [...new Set(educatorsData.map(edu => edu.subject))];
      const uniqueExperiences = [...new Set(educatorsData.map(edu => {
        if (edu.experience < 5) return 'Less than 5 years';
        if (edu.experience < 10) return 'Less than 10 years';
        if (edu.experience < 15) return 'Less than 15 years';
        return 'More than 15 years';
      }))];
      const uniqueRatings = [...new Set(educatorsData.map(edu => Math.round(edu.rating)))].sort();

      setLocations(uniqueLocations);
      setSubjects(uniqueSubjects);
      setExperiences(uniqueExperiences);
      setRatings(uniqueRatings);

      const updatedEducators = educatorsData.map(edu => ({
        ...edu,
        isFollowing: edu.followers.includes(currentUser.id),
        userRating: edu.userRating || 0,
        rating: edu.rating || 0,
      }));
      setEducators((prevEducators) => [...prevEducators, ...updatedEducators]);
    } catch (error) {
      console.error("Error fetching educators:", error);
      setEducators([]);
    }
  };

  const fetchCourses = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/courses`);
      // Sort courses by newest first (descending order)
      const sortedCourses = response.data.payload.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setCourses((prevCourses) => [...prevCourses, ...sortedCourses]);
    } catch (error) {
      console.error("Error fetching courses:", error);
      setCourses([]);
    }
  };

  const loadMoreEducators = () => {
    fetchEducators();
  };

  const loadMoreCourses = () => {
    fetchCourses();
  };

  const handleFollow = async (educatorId, isFollowing) => {
    try {
      const endpoint = isFollowing
        ? `${process.env.REACT_APP_BACKEND_URL}/api/users/unfollow/${educatorId}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/users/follow/${educatorId}`;
      await taxios.post(endpoint);
      setEducators(prevEducators =>
        prevEducators.map(edu =>
          edu._id === educatorId
            ? {
                ...edu,
                isFollowing: !isFollowing,
                followers: isFollowing
                  ? edu.followers.filter(id => id !== currentUser.id)
                  : [...edu.followers, currentUser.id]
              }
            : edu
        )
      );
    } catch (error) {
      console.error(`Error ${isFollowing ? 'unfollowing' : 'following'} educator:`, error);
    }
  };

  const handleRatingClick = async (e, educatorId, starValue) => {
    const { left, width } = e.target.getBoundingClientRect();
    const clickX = e.clientX - left;

    const isHalf = clickX < width / 2;
    const newRating = isHalf ? starValue - 0.5 : starValue;

    console.log("Educator ID:", educatorId); // Log the educator ID to verify it's correct

    try {
      const response = await taxios.put(`${process.env.REACT_APP_BACKEND_URL}/api/educators/${educatorId}/rate`, { rating: newRating });
      setEducators(prevEducators =>
        prevEducators.map(edu =>
          edu._id === educatorId
            ? {
                ...edu,
                userRating: newRating,
                rating: response.data.payload.rating
              }
            : edu
        )
      );
    } catch (error) {
      console.error("Error updating rating:", error);
    }
  };

  const renderStars = (userRating, rating, educatorId) => {
    const stars = [];
    const combinedRating = userRating || rating;

    for (let i = 1; i <= 5; i++) {
      if (combinedRating >= i) {
        stars.push(
          <FaStar
            key={i}
            size={24}
            onClick={(e) => handleRatingClick(e, educatorId, i)}
            color="#ffc107"
            className="rating-star"
          />
        );
      } else if (combinedRating >= i - 0.5) {
        stars.push(
          <FaStarHalfAlt
            key={i}
            size={24}
            onClick={(e) => handleRatingClick(e, educatorId, i)}
            color="#ffc107"
            className="rating-star"
          />
        );
      } else {
        stars.push(
          <FaStar
            key={i}
            size={24}
            onClick={(e) => handleRatingClick(e, educatorId, i)}
            color="#e4e9f0"
            className="rating-star"
          />
        );
      }
    }
    return stars;
  };

  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  const filteredEducators = Array.isArray(educators) ? educators.filter((edu) => {
    return (
      (!filterLocation || edu.location === filterLocation) &&
      (!filterSubject || edu.subject === filterSubject) &&
      (
        !filterExperience ||
        (filterExperience === 'Less than 5 years' && edu.experience < 5) ||
        (filterExperience === 'Less than 10 years' && edu.experience < 10) ||
        (filterExperience === 'Less than 15 years' && edu.experience < 15) ||
        (filterExperience === 'More than 15 years' && edu.experience >= 15)
      ) &&
      (!filterRating || Math.round(edu.rating) === parseInt(filterRating))
    );
  }) : [];

  const handleShare = (courseTitle) => {
    const url = `https://example.com/courses/${courseTitle.replace(/\s+/g, '-').toLowerCase()}`;
    navigator.clipboard.writeText(url).then(() => {
      alert(`Link to "${courseTitle}" has been copied to clipboard: ${url}`);
    });
  };

  return (
    <div className="educator-page">
      <TopNavBar toggleSidebar={toggleSidebar} onLogout={onLogout} onCreateStudyGroup={() => setShowCreateForm(true)} />
      <CommunitySidebar isOpen={sidebarOpen} />
      <div className={`content ${sidebarOpen ? 'sidebar-open' : ''}`}>
        {showCreateForm && <CreateStudyGroupForm onCreate={createStudyGroup} onClose={() => setShowCreateForm(false)} />}
        <div className="filter-container">
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => setLocationOpen(!locationOpen)}>
              Location <FaChevronDown className={`dropdown-icon ${locationOpen ? 'open' : ''}`} />
            </div>
            {locationOpen && (
              <div className="filter-options">
                {locations.map(location => (
                  <div key={location} className="filter-option" onClick={() => setFilterLocation(location)}>
                    {location}
                  </div>
                ))}
                <div className="filter-option" onClick={() => setFilterLocation('')}>
                  All Locations
                </div>
              </div>
            )}
          </div>
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => setSubjectOpen(!subjectOpen)}>
              Subject <FaChevronDown className={`dropdown-icon ${subjectOpen ? 'open' : ''}`} />
            </div>
            {subjectOpen && (
              <div className="filter-options">
                {subjects.map(subject => (
                  <div key={subject} className="filter-option" onClick={() => setFilterSubject(subject)}>
                    {subject}
                  </div>
                ))}
                <div className="filter-option" onClick={() => setFilterSubject('')}>
                  All Subjects
                </div>
              </div>
            )}
          </div>
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => setExperienceOpen(!experienceOpen)}>
              Experience <FaChevronDown className={`dropdown-icon ${experienceOpen ? 'open' : ''}`} />
            </div>
            {experienceOpen && (
              <div className="filter-options">
                {experiences.map(exp => (
                  <div key={exp} className="filter-option" onClick={() => setFilterExperience(exp)}>
                    {exp}
                  </div>
                ))}
                <div className="filter-option" onClick={() => setFilterExperience('')}>
                  All Experience Levels
                </div>
              </div>
            )}
          </div>
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => setRatingOpen(!ratingOpen)}>
              Rating <FaChevronDown className={`dropdown-icon ${ratingOpen ? 'open' : ''}`} />
            </div>
            {ratingOpen && (
              <div className="filter-options">
                {ratings.map(rating => (
                  <div key={rating} className="filter-option" onClick={() => setFilterRating(rating.toString())}>
                    {rating} Stars
                  </div>
                ))}
                <div className="filter-option" onClick={() => setFilterRating('')}>
                  All Ratings
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="main-section">
          <div className="educators-container" ref={eduContainerRef}>
            {filteredEducators.map((edu) => (
              <div key={edu._id} className="educator-card" onClick={() => navigate(`/profile/${edu._id}`)}>
                <div className="profile-picture">
                  <img src={edu.avatar || placeholderImage} alt={`${edu.username} profile`} />
                </div>
                <div className="educator-info">
                  <h3>
                    <span className="educator-name">{edu.username}</span>
                    <span className="dot-divider">•</span>
                    <span className="educator-subject">{edu.subject}</span>
                  </h3>
                  <p>{edu.description}</p>
                  <span>{edu.followers.length} Followers</span>
                  <span>{edu.experience} Years of Experience</span>
                  <div className="rating-section">
                    {renderStars(edu.userRating, edu.rating, edu._id)}
                    <span className="numeric-rating">{(edu.userRating || edu.rating).toFixed(1)} / 5</span>
                  </div>
                </div>
                <button 
                  className={`follow-button ${edu.isFollowing ? 'following' : ''}`}
                  onClick={() => handleFollow(edu._id, edu.isFollowing)}
                >
                  {edu.isFollowing ? 'Following' : 'Follow'}
                </button>
              </div>
            ))}
            <div ref={eduLoadMoreRef}></div>
          </div>
          <div className="courses-container" ref={courseContainerRef}>
            <h2 className="courses-title">Courses</h2>
            <div className="courses-list">
              {courses.map((course, index) => (
                <div key={course._id || index} className="course-item" onClick={() => handleCourseClick(course._id)}>
                  <div className="course-thumbnail">
                    <img
  src={
    course?.courseThumbnailURL
      ? course.courseThumbnailURL.startsWith('http')
        ? course.courseThumbnailURL
        : `https://archi-video-bucket.s3.us-west-1.amazonaws.com/thumbnails/${course.courseThumbnailURL}`
      : 'https://via.placeholder.com/150'
  }
  alt="Course Thumbnail"
/>
                  </div>
                  <div className="course-info">
                    <ProfilePicture 
                      src={course.creatorID.avatar || placeholderImage} 
                      alt={course.creatorID.username || 'Anonymous'} 
                    />
                    <div className="course-details">
                      <h2>{course.courseTitle}</h2>
                      <span className="creator-name">{course.creatorID.username}</span>
                      <div className="rating">
                        {[...Array(5)].map((_, i) => (
                          <FaStar key={i} color={i < Math.round(course.rating) ? "#ffc107" : "#e4e9f0"} />
                        ))}
                        <span>{course.rating?.toFixed(1)}</span>
                      </div>
                    </div>
                    <FaShare className="share-icon" onClick={() => handleShare(course.courseTitle)} />
                  </div>
                </div>
              ))}
              <div ref={courseLoadMoreRef}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducatorPage;
