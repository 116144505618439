import React, { useState, useEffect } from "react";
import useSWR from "swr";

import fetcher from "../../../util/fetcher.js";
import taxios from "../../../util/token_refresh_hook";

import "./QuestionThread.css";
import Post from "./Post.jsx";

/**
 * Declares a question thread component.
 * @component
 * @param {Object} props The props for the component
 * @param {Question} props.question The question object to display
 * @return {*} The rendered component
 */
export default function QuestionThread({ question }) {
  const [commentList, setCommentList] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [error, setError] = useState(null);
  const [author, setAuthor] = useState();

  // Safely get the question ID
  const questionId = question?._id;

  // Safely construct author object
  useEffect(() =>{
    const getAuthorInfo = async () => {
        const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/by_id/${question.author}`);
        setAuthor(response.data.payload)
    }
    getAuthorInfo();
  }, [])

  // Only fetch comments if we have a valid question ID and showComments is true
  const { data, error: swrError } = useSWR(
    showComments && questionId
      ? `${process.env.REACT_APP_BACKEND_URL}/api/comments/post/${questionId}`
      : null,
    fetcher
  );

  useEffect(() => {
    if (data?.payload?.comments) {
      setCommentList(data.payload.comments);
    }
    if (swrError) {
      console.error(`Failed to fetch comments for post with ID ${questionId}:`, swrError);
      setError('Failed to load comments');
    }
  }, [data, swrError, questionId]);

  const handleAddComment = async () => {
    if (!questionId) {
      console.error('Cannot add comment: Invalid question ID');
      setError('Cannot add comment at this time');
      return;
    }

    if (!newComment.trim()) {
      return;
    }

    try {
      const response = await taxios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/comments`,
        {
          parent: questionId,
          body: newComment.trim()
        }
      );

      if (response.data?.payload) {
        setCommentList(prevComments => [response.data.payload, ...prevComments]);
        setNewComment("");
        setError(null);
      }
    } catch (err) {
      console.error("Error adding comment:", err);
      setError('Failed to add comment');
    }
  };

  if (!question || !questionId) {
    return <div className="question-thread error">Invalid question data</div>;
  }

  return ( author &&
    <div className="question-thread">
      <Post
        post={question}
        author={author}
        onHitCommentBtn={() => setShowComments(!showComments)}
        isQuestion={true}
      />
      {showComments && (
        <div className="comments-section">
          <div className="comment-box">
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Write your comment here"
              disabled={!questionId}
            />
            <button 
              onClick={handleAddComment}
              disabled={!questionId || !newComment.trim()}
            >
              Submit
            </button>
          </div>
          {error && (
            <div className="error-message">
              {error}
            </div>
          )}
          <div className="comment-list">
            {commentList.map((comment, index) => (
              <Post
                key={comment._id || index}
                post={comment}
                author={comment.authorInfo}
                onHitCommentBtn={() => {}}
                isQuestion={false}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}