import React from "react";

function PricingReviewStep({
  coursePrice,
  onPriceChange,
  isUploading,
  onPrevious,
  onSave,
}) {
  return (
    <div className="border border-gray-300 rounded-lg bg-white p-6 mb-6">
      <h2 className="text-left text-lg font-bold text-gray-800">
        Pricing & Review
      </h2>
      <h3 className="text-left text-xs text-gray-800 mb-3">
        Now we just need to set the price for your course
      </h3>

      <div className="flex items-center">
        <div className="relative flex-1">
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600">
            $
          </span>
          <input
            type="number"
            value={coursePrice}
            onChange={(e) => onPriceChange(e.target.value)}
            className="border text-end border-gray-300 rounded-md p-2 w-1/3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            min="0"
            step="0.01"
          />
        </div>
      </div>

      {/* TODO: Add Review Summary here*/}

      <div className="flex justify-between mt-4">
        <button
          onClick={onPrevious}
          className="bg-[#002855] text-white rounded-md px-4 py-2 hover:bg-[#001f40] transition"
        >
          Back
        </button>
        <button
          onClick={onSave}
          disabled={isUploading}
          className={`rounded-md px-4 py-2 transition ${
            isUploading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-[#002855] text-white hover:bg-[#001f40]"
          }`}
        >
          {isUploading ? "Uploading..." : "Schedule Upload"}
        </button>
      </div>
    </div>
  );
}

export default PricingReviewStep;
