import React, { useEffect, useState } from 'react';

const StripeOnboarding = () => {
  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedAccountId = localStorage.getItem('stripeAccountId');
    if (storedAccountId) {
      setAccountId(storedAccountId);
    }
  }, []);

  const handleOnboardClick = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Create a connected account if it doesn't exist
      let id = accountId;
      if (!id) {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/create-connected-account`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        id = data.accountId;
        setAccountId(id);
        localStorage.setItem('stripeAccountId', id);
      }

      // Create the account link for onboarding
      const linkResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/create-account-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ accountId: id }),
        credentials: 'include'
      });

      if (!linkResponse.ok) {
        throw new Error(`HTTP error! status: ${linkResponse.status}`);
      }

      const linkData = await linkResponse.json();
      
      if (linkData.url) {
        window.location.href = linkData.url;
      } else {
        throw new Error('No URL received from server');
      }
    } catch (error) {
      console.error('Error during Stripe onboarding:', error);
      setError('Failed to setup Stripe onboarding. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-8 space-y-4">
      <div className="flex flex-col space-y-2">
        <h2 className="text-xl font-semibold text-light-text dark:text-dark-text">
          Payment Settings
        </h2>
        <p className="text-sm text-light-textSecondary dark:text-dark-textSecondary">
          Set up your Stripe account to receive payments
        </p>
      </div>
      
      {error && (
        <div className="p-4 text-sm text-red-800 bg-red-100 rounded-lg dark:bg-red-900 dark:text-red-200">
          {error}
        </div>
      )}

      <button
        onClick={handleOnboardClick}
        disabled={loading}
        className="px-6 py-2 bg-light-accent text-white rounded-lg hover:bg-light-accentSecondary dark:bg-dark-accent dark:hover:bg-dark-accentSecondary disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {loading ? (
          <span className="flex items-center justify-center">
            <svg className="w-5 h-5 mr-2 animate-spin" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            Setting Up...
          </span>
        ) : (
          'Set Up Payouts with Stripe'
        )}
      </button>
    </div>
  );
};

export default StripeOnboarding;