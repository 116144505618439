// src/components/StudentPage/StudentPage.jsx
import React from 'react';
import './StudentPage.css';
import logo from '../../../assets/LogoWithName.png'; // Adjust the path to your logo if necessary

const StudentPage = () => {
  return (
    <div className="student-page">
      <img src={logo} alt="Logo" className="logo" />
      <h2>Student Account</h2>
      <p className="form-subtitle">Tell us about yourself</p>
      <form>
        <input type="text" placeholder="Where do you go to school?" />
        <input type="text" placeholder="What are you studying?" />
        <input type="text" placeholder="Where do you work?" />
        <input type="text" placeholder="What do you do?" />
        <input type="text" placeholder="What are you looking to learn about?" />
        <button type="submit">Get Started</button>
      </form>
    </div>
  );
};

export default StudentPage;
