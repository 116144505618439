/**
 * Emits an API error as a pretty string.
 * @param resp The error response to emit.
 * @returns The error response pretty printed as a string
 */
export function prettyError(resp) {
	let etext = resp.status;
	if (resp.desc) {
		if(Array.isArray(resp.desc)){
			etext = resp.desc.length > 1 ? `[${resp.desc.join(", ")}]` : resp.desc[0];
		}
		else {
			etext = resp.desc;
		}
	}
	return `<${resp.code}> ${etext}`;
}

/**
 * Swallows an Axios error and returns either the response error or
 * internal Axios error.
 * @param e The error to swallow
 * @returns The response string from Axios
 */
export function swallowError(error) {
	//Check if the error has a response section
	let rtext = "";
	if (error.response !== undefined) {
		const response = error.response.data;
		rtext = prettyError(response);
	} else {
		rtext = error.message;
	}
	return rtext;
}