import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './EventCreationPage.css';
import { FaRegCalendarAlt, FaMapMarkerAlt, FaImage, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import taxios from '../../util/token_refresh_hook';

const EventCreationPage = ({ toggleSidebar, sidebarOpen, onLogout }) => {
  const [title, setTitle] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [eventImage, setEventImage] = useState(null);
  const [imageUploading, setImageUploading] = useState(false);
  const [industry, setIndustry] = useState('');
  const [type, setType] = useState('');
  // const [creator, setCreator] = useState(''); // Text input for creator
  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleImageUpload = (e) => {
    setImageUploading(true);
    const file = e.target.files[0];
    if (file) {
      setTimeout(() => { // Simulate an image upload process
        setEventImage(URL.createObjectURL(file));
        setImageUploading(false);
      }, 1000);
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input reference is null.");
    }
  };

  const handleSaveEvent = async () => {
    if (title && dateTime && location && description && industry && type && eventImage) {
      try {
        const eventData = {
          title,
          time: dateTime,
          location,
          description,
          thumbnail: eventImage, // Assuming you handle image storage elsewhere
          industry,
          type,
        };

        const response = await taxios.post(`${process.env.REACT_APP_BACKEND_URL}/api/seminars`, eventData);
        console.log('Event created successfully:', response.data);

        navigate('/seminars');
      } catch (error) {
        console.error('Error creating event:', error);
      }
    } else {
      alert('Please fill in all required fields.');
    }
  };

  return (
    <div className="event-creation-page">
      <TopNavBar toggleSidebar={toggleSidebar} onLogout={onLogout} />
      <CommunitySidebar isOpen={sidebarOpen} />
      <div className={`content ${sidebarOpen ? 'sidebar-open' : ''}`}>
        <div className="event-card">
          <div className="event-image-container" onClick={handleImageClick}>
            {eventImage ? (
              <img src={eventImage} alt="Event" className="event-image" />
            ) : (
              <div className="image-upload-placeholder">
                {imageUploading ? (
                  <p>Uploading...</p>
                ) : (
                  <>
                    <FaImage size={50} />
                    <p>Click to upload an image</p>
                  </>
                )}
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
          </div>
          <div className="event-details">
            <div className="input-group">
              <input
                type="text"
                placeholder="Event Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className={`event-title-input ${title ? 'valid' : 'invalid'}`}
              />
              {title ? <FaCheckCircle className="input-icon valid" /> : <FaExclamationCircle className="input-icon invalid" />}
            </div>
            <div className="event-info">
              <div className="info-group">
                <FaRegCalendarAlt />
                <input
                  type="datetime-local"
                  value={dateTime}
                  onChange={(e) => setDateTime(e.target.value)}
                  className={dateTime ? 'valid' : 'invalid'}
                />
              </div>
              <div className="info-group">
                <FaMapMarkerAlt />
                <input
                  type="text"
                  placeholder="Location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className={location ? 'valid' : 'invalid'}
                />
              </div>
              <div className="info-group">
                <input
                  type="text"
                  placeholder="Industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  className={industry ? 'valid' : 'invalid'}
                />
              </div>
              <div className="info-group">
                <input
                  type="text"
                  placeholder="Type (e.g., Seminar, Workshop)"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  className={type ? 'valid' : 'invalid'}
                />
              </div>
            </div>
            <textarea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="event-description"
              maxLength={500}
            />
            <div className="char-count">{description.length}/500</div>
            <div className="event-buttons">
              <button onClick={handleSaveEvent} className="save-event-button">Save Event</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCreationPage;