import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import taxios from "../../../util/token_refresh_hook";
import useStudyGroupStore from "./studyGroupStore";
import{swallowError} from "../../../util/http";

import "./CreateStudyGroupForm.css";

/** Declares a self-managed study group creator component. */
export default function CreateStudyGroupForm(){
	//Setup component states
	const [, setSearchParams] = useSearchParams();	//Tracker for search params.
	const [name, setGroupName] = useState("");					//Holds the form value for "groupName".
	const [description, setDescription] = useState("");	 		//Holds the form value for "groupDescription".
	const [isPrivate, setIsPrivate] = useState(false);			//Holds the form value for "isPrivate".

	//Access the state managers of the study group store
	const toggleShowGroupCreationForm = useStudyGroupStore((state) => state.toggleShowCreationForm);
	const setSelectedGroup = useStudyGroupStore((state) => state.setSelectedGroup);
	const addGroup = useStudyGroupStore((state) => state.addGroup);

	//Sets up the function that is to run when submitting the form
	const handleSubmit = async (e) => {
		//Prevent the default form submission behavior
		e.preventDefault();

		//Do arg parsing here
		if(name.trim() !== ""){
			//Construct the study group object
			const newGroup = {
				name: name,
				description: description,
				isPrivate: isPrivate
			}

			//Send a POST to the API to add the new study group
			try {
				//Do the POST request
				const response = await taxios.post(`${process.env.REACT_APP_BACKEND_URL}/api/study_groups`, newGroup);
				console.log("Study group creation succeeded:", response.data.desc);

				//Get the ID of the new group and add it to the new object
				const newGID = response.data.payload;
				newGroup._id = newGID;
		  
				//Update the local state and close the creation form
				addGroup(newGroup);
				setSelectedGroup(newGroup);
				setSearchParams({ group: newGroup._id });
				toggleShowGroupCreationForm();
			}
			catch (error) {
				console.error("There was an error adding the study group:", swallowError(error));
			}
		}
	};

	//Render the component
	return (
		<div className="create-study-group-modal">
			<div className="create-study-group-content">
				<button className="close-button" onClick={toggleShowGroupCreationForm}>
					<i className="fas fa-times"></i>
				</button>
				<h2>Create a study group</h2>
				<form onSubmit={handleSubmit}>
					{/* Form option: "groupName" */}
					<input
						type="text"
						placeholder="Group Name"
						value={name}
						onChange={(e) => setGroupName(e.target.value)}
						required
					/>
					{/* Form option: "groupDescription" */}
					<textarea
						placeholder="Description"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						required
					/>
					{/* Form option: "isPrivate" */}
					<label>
						Make private
						<input
							type="checkbox"
							checked={isPrivate}
							onChange={(e) => setIsPrivate(e.target.checked)}
						/>
					</label>
					<div className="form-actions">
						<button type="button" onClick={toggleShowGroupCreationForm}>Join a group</button>
						<button type="submit" className="create-button">Create</button>
					</div>
				</form>
			</div>
		</div>
	);
};


/*
  const editStudyGroup = (group) => {
    const updatedGroups = studyGroups.map((g) => (g.groupName === group.groupName ? group : g));
    setStudyGroups(updatedGroups);
  };

  const deleteStudyGroup = (group) => {
    setStudyGroups((prevGroups) => prevGroups.filter((g) => g.groupName !== group.groupName));
  };
*/