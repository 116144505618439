import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import { useNavigate } from 'react-router-dom';
import taxios from '../../util/token_refresh_hook';
import './InternshipCreationPage.css';

function InternshipCreationPage({ toggleSidebar, sidebarOpen, onLogout }) {
  const [jobTitle, setJobTitle] = useState('');
  const [location, setLocation] = useState('');
  const [salary, setSalary] = useState('');
  const [workplaceType, setWorkplaceType] = useState('remote');
  const [jobType, setJobType] = useState('internship');
  const [prerequisites, setPrerequisites] = useState([]);
  const [requireResume, setRequireResume] = useState(true);
  const [aboutJob, setAboutJob] = useState('');
  const [industry, setIndustry] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCourseModal, setShowCourseModal] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [newCourse, setNewCourse] = useState('');
  const [newQuestion, setNewQuestion] = useState('');
  const [courses, setCourses] = useState([]);  // Added state for courses
  const navigate = useNavigate();

  const handleCreateJob = async (event) => {
    event.preventDefault();
    const form = event.target;

    if (form.checkValidity() === false) {
      form.reportValidity(); // Trigger the built-in validation UI
      return;
    }

    const internshipData = {
      companyName, 
      industry,
      location,
      salary,
      workplaceType,
      jobType,
      internships: [
        {
          name: jobTitle,
          location,
          postedDaysAgo: 0,
          prerequisites: prerequisites.map((p) => p.value),
          about: aboutJob,
        },
      ],
    };

    try {
      const response = await taxios.post(`${process.env.REACT_APP_BACKEND_URL}/api/internships`, internshipData);
      console.log('Job created:', response.data);
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error creating job:', error);
      alert('There was an error creating the internship. Please try again.');
    }
  };

  // Modified fetchCourses function
  const fetchCourses = async (searchTerm) => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/courses`);
      const coursesData = Array.isArray(response.data.payload) ? response.data.payload : [];
  
      // Filter courses based on searchTerm
      const filteredCourses = coursesData.filter(course =>
        course.courseTitle.toLowerCase().includes(searchTerm.toLowerCase())
      );
  
      setCourses(filteredCourses);
    } catch (error) {
      console.error("Error fetching courses:", error);
      setCourses([]); // Clear courses on error
    }
  };

  // Modified the input change handler for the course input
  const handleCourseInputChange = (e) => {
    setNewCourse(e.target.value);
    if (e.target.value.length > 2) {
      fetchCourses(e.target.value);
    } else {
      setCourses([]); // Clear the dropdown if the input length is less than 3
    }
  };

  const handleAddCourse = () => {
    if (newCourse) {
      setPrerequisites([...prerequisites, { type: 'course', value: newCourse }]);
      setNewCourse('');
      setShowCourseModal(false);
      setCourses([]);  // Clear courses after adding
    }
  };

  const handleCourseSelect = (courseTitle) => {
    setNewCourse(courseTitle);
    setCourses([]); // Clear the dropdown after selecting a course
  };

  const addPrerequisite = (type) => {
    if (type === 'course') {
      setShowCourseModal(true);
    } else if (type === 'question') {
      setShowQuestionModal(true);
    }
  };

  const handleAddQuestion = () => {
    if (newQuestion) {
      setPrerequisites([...prerequisites, { type: 'question', value: newQuestion }]);
      setNewQuestion('');
      setShowQuestionModal(false);
    }
  };

  const removePrerequisite = (index) => {
    setPrerequisites(prerequisites.filter((_, i) => i !== index));
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate('/internships');
  };

  return (
    <div className="internship-creation-page-container">
      <TopNavBar toggleSidebar={toggleSidebar} onLogout={onLogout} />
      <CommunitySidebar isOpen={sidebarOpen} />
      <form
        className={`internship-creation-content-wrapper ${sidebarOpen ? 'sidebar-open' : ''}`}
        noValidate
        onSubmit={handleCreateJob}
      >
        <div className="internship-creation-header">
          <h2>Create a Job Post</h2>
          <FaTimes className="internship-close-icon" onClick={handleSuccessModalClose} />
        </div>
        <div className="internship-form-container">
          <div className="internship-form-group">
            <label>Organization Name</label>
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="Organization Name"
              required
            />
          </div>
          <div className="internship-form-group">
            <label>Job Title</label>
            <input
              type="text"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              placeholder="Job Title"
              required
            />
          </div>
          <div className="internship-form-group">
            <label>Industry</label>
            <input
              type="text"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              placeholder="Industry"
              required
            />
          </div>
          <div className="internship-form-group">
            <label>Location</label>
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Location"
              required
            />
          </div>
          <div className="internship-form-group">
            <label>Salary</label>
            <input
              type="text"
              value={salary}
              onChange={(e) => setSalary(e.target.value)}
              placeholder="Salary"
              required
            />
          </div>
          <div className="internship-form-group">
            <label>Workplace Type</label>
            <select
              value={workplaceType}
              onChange={(e) => setWorkplaceType(e.target.value)}
            >
              <option value="remote">Remote</option>
              <option value="on-site">On-site</option>
              <option value="hybrid">Hybrid</option>
            </select>
          </div>
          <div className="internship-form-group">
            <label>Job Type</label>
            <select
              value={jobType}
              onChange={(e) => setJobType(e.target.value)}
            >
              <option value="internship">Internship</option>
              <option value="full-time">Full-time</option>
              <option value="part-time">Part-time</option>
              <option value="contractor">Contractor</option>
            </select>
          </div>
          <div className="internship-prerequisites-group">
            <label>Prerequisites</label>
            <div className="prerequisite-buttons">
              <button
                className="internship-add-prerequisite-button"
                onClick={() => addPrerequisite('course')}
                type="button"
              >
                Add a course
              </button>
              <button
                className="internship-add-prerequisite-button"
                onClick={() => addPrerequisite('question')}
                type="button"
              >
                Add pre-qualifying questions
              </button>
            </div>

            {prerequisites.length > 0 && (
              <div className="internship-prerequisites-list">
                {prerequisites.map((prerequisite, index) => (
                  <div key={index} className="internship-prerequisite-item">
                    <span>
                      {prerequisite.type}: {prerequisite.value}
                    </span>
                    <FaTimes
                      className="internship-remove-icon"
                      onClick={() => removePrerequisite(index)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="internship-checkbox-group-centered">
            <input
              type="checkbox"
              checked={requireResume}
              onChange={() => setRequireResume(!requireResume)}
            />
            <label>Require Resume</label>
          </div>
          <div className="internship-form-group textarea-group">
            <label>About the Job</label>
            <textarea
              value={aboutJob}
              onChange={(e) => setAboutJob(e.target.value)}
              placeholder="About the job"
              required
            />
          </div>
        </div>
        <button
          className="internship-create-button"
          type="submit"
        >
          Create
        </button>
      </form>

      {showSuccessModal && (
        <div className="internship-modal">
          <div className="internship-modal-content">
            <h3>Post Created Successfully!</h3>
            <button
              className="internship-modal-ok-button"
              onClick={handleSuccessModalClose}
            >
              OK
            </button>
          </div>
        </div>
      )}

      {showCourseModal && (
        <div className="internship-modal">
          <div className="internship-modal-content">
            <h3>Add a Course</h3>
            <input
              type="text"
              value={newCourse}
              onChange={handleCourseInputChange}  // Modified input change handler
              placeholder="Enter course prerequisite"
            />
            {courses.length > 0 && (
  <ul className="course-dropdown">
    {courses.map((course, index) => (
      <li key={index} onClick={() => handleCourseSelect(course.courseTitle)}>
        {course.courseTitle}
      </li>
    ))}
  </ul>
)}
            <button
              className="internship-modal-add-button"
              onClick={handleAddCourse}
            >
              Add Course
            </button>
            <button
              className="internship-modal-cancel-button"
              onClick={() => setShowCourseModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {showQuestionModal && (
        <div className="internship-modal">
          <div className="internship-modal-content">
            <h3>Add a Pre-qualifying Question</h3>
            <input
              type="text"
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              placeholder="Enter pre-qualifying question"
            />
            <button
              className="internship-modal-add-button"
              onClick={handleAddQuestion}
            >
              Add Question
            </button>
            <button
              className="internship-modal-cancel-button"
              onClick={() => setShowQuestionModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default InternshipCreationPage;