import React, { useState, useEffect } from 'react';
import taxios from '../../util/token_refresh_hook';
import './ApplicationModal.css';

const ApplicationModal = ({ isOpen, onClose, jobTitle, internshipId }) => {
  const [formData, setFormData] = useState({
    email: '',
    phoneNumber: '',
    answers: '',
    resume: null
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successVisible, setSuccessVisible] = useState(false); // New state for success confirmation
  const [uploadProgress, setUploadProgress] = useState(0); // Added uploadProgress state

  useEffect(() => {
    if (successVisible) {
      const timer = setTimeout(() => {
        setSuccessVisible(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successVisible]);

  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError('');

      try {
          if (!formData.resume) {
              throw new Error('Please select a resume file');
          }

          // First upload the resume and get the URL
          const timestamp = Date.now().toString();
          const filename = `${timestamp}-${formData.resume.name}`;

          // Get signed URL for resume upload
          const { data: { payload } } = await taxios.get(
              `${process.env.REACT_APP_BACKEND_URL}/api/applications/generate-resume-url`,
              { 
                  params: { 
                      filename: filename,
                      contentType: formData.resume.type 
                  } 
              }
          );

          if (!payload?.signedUrl) {
              throw new Error('Failed to get upload URL');
          }

          // Upload resume to S3
          const uploadResponse = await fetch(payload.signedUrl, {
              method: 'PUT',
              headers: {
                  'Content-Type': formData.resume.type,
                  'x-amz-acl': 'bucket-owner-full-control'
              },
              body: formData.resume
          });

          if (!uploadResponse.ok) {
              throw new Error(`Upload failed with status ${uploadResponse.status}`);
          }

          // Now create FormData with all fields including the resume URL
          const formDataToSend = new FormData();
          formDataToSend.append('internshipId', internshipId);
          formDataToSend.append('email', formData.email);
          formDataToSend.append('phoneNumber', formData.phoneNumber);
          formDataToSend.append('answers', formData.answers);
          formDataToSend.append('resumeUrl', payload.resumeKey); // Changed from fileUrl to resumeKey

          console.log('Submitting application with data:', {
              internshipId,
              email: formData.email,
              phoneNumber: formData.phoneNumber,
              resumeUrl: payload.resumeKey
          });

          // Submit application
          const submitResponse = await taxios.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/applications`,
              formDataToSend
          );

          if (submitResponse.data && submitResponse.data.status === "ok") {
              setSuccessVisible(true);
              resetForm();
              setTimeout(() => {
                  setSuccessVisible(false);
                  onClose(); // Close the modal after success
              }, 3000);
          } else {
              setError(submitResponse.data.desc || 'Failed to submit application');
          }

      } catch (error) {
          console.error('Application submission error:', error);
          if (error.response?.data?.desc) {
              console.error('Server error details:', error.response.data);
              setError(error.response.data.desc);
          } else {
              console.error('Error details:', error);
              setError(error.message || 'Failed to submit application');
          }
      } finally {
          setLoading(false);
      }
  };

  // Helper function to reset form
  const resetForm = () => {
    setFormData({
      email: '',
      phoneNumber: '',
      answers: '',
      resume: null
    });
    setUploadProgress(0); // Reset upload progress
  };

  // Function to handle success confirmation close
  const handleSuccessClose = () => {
    setSuccessVisible(false); // Hide the success confirmation
    resetForm(); // Reset form for future use
  };

  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    
    if (file) {
      const ext = file.name.split('.').pop().toLowerCase();
      const allowedTypes = ['pdf', 'doc', 'docx'];
      
      if (!allowedTypes.includes(ext)) {
        setError('Invalid file type. Only PDF and DOC files are allowed.');
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        setError('File size too large. Maximum size is 5MB.');
        return;
      }

      setFormData(prev => ({
        ...prev,
        resume: file
      }));
      setError(''); // Clear any previous errors
    }
  };

  // Success confirmation modal
  const SuccessConfirmation = () => (
    <div className="success-overlay" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div className="success-content" style={{
        backgroundColor: '#d1fae5', // Light green for success
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center'
      }}>
        <h3>Application Submitted Successfully!</h3>
        <button onClick={handleSuccessClose} style={{
          marginTop: '10px',
          backgroundColor: '#10B981',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '4px',
          border: 'none',
          cursor: 'pointer'
        }}>
          OK
        </button>
      </div>
    </div>
  );

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}>
        <div className="modal-content" style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          width: '500px',
          maxWidth: '90%'
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <h2 style={{ margin: 0 }}>Apply for {jobTitle}</h2>
            <button 
              onClick={onClose}
              style={{
                background: 'none',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer'
              }}
            >
              &times;
            </button>
          </div>
          
          {error && (
            <div className="error-message">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="application-modal-form">
            <div className="form-group">
              <label className="form-label">
                Email address
              </label>
              <input
                type="email"
                required
                className="form-input"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({...prev, email: e.target.value}))}
              />
            </div>
            
            <div className="form-group">
              <label className="form-label">
                Phone Number
              </label>
              <input
                type="tel"
                required
                className="form-input"
                value={formData.phoneNumber}
                onChange={(e) => setFormData(prev => ({...prev, phoneNumber: e.target.value}))}
              />
            </div>
            
            <div className="form-group">
              <label className="form-label">
                Resume Upload
              </label>
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                required
                onChange={handleFileChange}
              />
              {uploadProgress > 0 && uploadProgress < 100 && (
                <div style={{ 
                  marginTop: '5px',
                  fontSize: '14px',
                  color: '#6B7280'
                }}>
                  Uploading: {uploadProgress}%
                </div>
              )}
            </div>
            
            <div className="form-group">
              <label className="form-label">
                Any Questions Employer Put
              </label>
              <textarea
                className="form-textarea"
                value={formData.answers}
                onChange={(e) => setFormData(prev => ({...prev, answers: e.target.value}))}
              />
            </div>
            
            <div className="form-button-group">
              <button
                type="submit"
                className="apply-button"
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Apply'}
              </button>
              <button
                type="button"
                onClick={onClose}
                className="cancel-button"
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>

      {successVisible && <SuccessConfirmation />}
    </>
  );
};

export default ApplicationModal;
