import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";

import fetcher from "../../../util/fetcher.js";
import QuestionThread from "../QuestionThread/QuestionThread.jsx";
import usePaginationStore from "../Pagination/paginationStore.js"
import useQuestionStore from "./questionStore.js";

import "./QuestionList.css";

export default function QuestionList() {
  const [searchParams] = useSearchParams();
  const [gValue, setGValue] = React.useState(searchParams.get("group") || null);
  const { 
    questions, setQuestions, shouldRevalidate, resetRevalidation, triggerRevalidation,
    isPopulated: isQPopulated, 
    setIsPopulated: setIsQPopulated
  } = useQuestionStore();
  const { 
    currentPage, setCurrentPage, setMaxPages,
    setIsPopulated: setIsPPopulated
  } = usePaginationStore();

  // Get the post ID to scroll to from search params
  const scrollToId = searchParams.get('scrollTo');

  // Effect to handle scrolling to post
  useEffect(() => {
    if (scrollToId && isQPopulated) {
      setTimeout(() => {
        const element = document.getElementById(scrollToId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          element.classList.add('highlighted');
          setTimeout(() => {
            element.classList.remove('highlighted');
          }, 3000);
        }
      }, 100);
    }
  }, [scrollToId, isQPopulated, questions]);

  // Your existing effects...
  useEffect(() => {
    const newGValue = searchParams.get("group");
    if(!gValue) setGValue(newGValue);
    if(newGValue !== gValue){
      console.log("group changed");
      setGValue(newGValue);
      triggerRevalidation();
      setIsQPopulated(false);
      setIsPPopulated(false);
      setCurrentPage(1);
    }
  }, [searchParams, gValue, setIsQPopulated, setIsPPopulated, setCurrentPage, triggerRevalidation]);

  const { data, error, mutate } = useSWR(
    buildQueryURL(
      searchParams,
      `${process.env.REACT_APP_BACKEND_URL}/api/posts`,
      currentPage
    ),
    fetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  useEffect(() => {
    if(shouldRevalidate){
      mutate();
      resetRevalidation();
    }
  }, [shouldRevalidate, mutate, resetRevalidation]);

  useEffect(() => {
    if(data && data.payload && !isQPopulated){
      mutate();
      const qs = data.payload.posts;
      const pagination = data.payload.pagination;

      console.log("pagination:", pagination);
      console.log("page:", data.payload.thisPage);

      setMaxPages(pagination.pages);
      setQuestions(qs)
      setIsQPopulated(true);
      setIsPPopulated(true);
    }
  }, [data, isQPopulated, mutate, setIsPPopulated, setIsQPopulated, setMaxPages, setQuestions]);

  if(!data && !error) return <div>Loading...</div>;
  if(error) return <div style={{color: "#FF0000"}}><span style={{fontWeight: "bold"}}>Error while loading posts:</span> {error.message}</div>;

  return (isQPopulated && (
    questions.length > 0
      ? questions.map(post => (
        <div
          key={post._id}
          id={post._id}
          className={`question-thread-wrapper ${post._id === scrollToId ? 'highlighted' : ''}`}
        >
          <QuestionThread question={post} />
        </div>
      ))
      : (
        <div id="no_qs_notice">
          <span>No one has asked a question in this group yet. Feel free to ask any questions you have!</span>
        </div>
      )
  ));
}

/**
 * Constructs an API query URL, including any required URL search params
 * from the current frontend URL.
 * @param {*} searchParams The search params state to mutate
 * @param {String} baseUrl The URL to add the parameters to
 * @param {Number} page The page number to query
 * @returns String The modified API URL
 */
function buildQueryURL(searchParams, baseUrl, page){
	//Get the required values from the search params
	const sgValue = searchParams.get("group");

	//Construct a URL object from the given URL
	const url = new URL(baseUrl);
	
	//Append parameters only if they exist
	if(sgValue) url.searchParams.append("study_group_id", sgValue);
	url.searchParams.append("posts_per_page", 8);
	url.searchParams.append("page", page);

	//Compile and return the URL object
	return url.toString();
}