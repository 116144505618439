import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import { FaStar, FaShare } from 'react-icons/fa';
import taxios from '../../util/token_refresh_hook';
import RegistrationModal from './RegistrationModal';
import './SeminarsPage.css';

const SeminarsPage = ({ toggleSidebar, sidebarOpen, onLogout }) => {
  const [locationFilter, setLocationFilter] = useState('');
  const [industryFilter, setIndustryFilter] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [locations, setLocations] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [seminars, setSeminars] = useState([]);
  const [courses, setCourses] = useState([]);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [selectedSeminar, setSelectedSeminar] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const seminarContainerRef = useRef(null);
  const seminarLoadMoreRef = useRef(null);
  const coursesContainerRef = useRef(null);
  const courseLoadMoreRef = useRef(null);
  const modalRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSeminars();
    fetchCourses();
    fetchUserRole();
  }, []);

  useEffect(() => {
    const seminarObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreSeminars();
      }
    });

    if (seminarLoadMoreRef.current) {
      seminarObserver.observe(seminarLoadMoreRef.current);
    }

    return () => {
      if (seminarLoadMoreRef.current) {
        seminarObserver.unobserve(seminarLoadMoreRef.current);
      }
    };
  }, [seminarLoadMoreRef]);

  useEffect(() => {
    const coursesObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreCourses();
      }
    });

    if (courseLoadMoreRef.current) {
      coursesObserver.observe(courseLoadMoreRef.current);
    }

    return () => {
      if (courseLoadMoreRef.current) {
        coursesObserver.unobserve(courseLoadMoreRef.current);
      }
    };
  }, [courseLoadMoreRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.seminars-page__custom-dropdown')) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdown]);

  const fetchSeminars = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/seminars/all?limit=20`);
      const seminarsData = Array.isArray(response.data.payload) ? response.data.payload : [];
      setSeminars((prevSeminars) => [...prevSeminars, ...seminarsData]);

      const uniqueLocations = [...new Set(seminarsData.map(seminar => seminar.location))];
      const uniqueIndustries = [...new Set(seminarsData.map(seminar => seminar.industry))];

      setLocations(uniqueLocations);
      setIndustries(uniqueIndustries);

    } catch (error) {
      console.error("Error fetching seminars:", error);
      setSeminars([]);
    }
  };

  const fetchCourses = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/courses`);
      const sortedCourses = response.data.payload.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setCourses((prevCourses) => [...prevCourses, ...sortedCourses]);
    } catch (error) {
      console.error("Error fetching courses:", error);
      setCourses([]);
    }
  };

  const fetchUserRole = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/role`);
      setUserRole(response.data.role);
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  const loadMoreSeminars = () => {
    fetchSeminars();
  };

  const loadMoreCourses = () => {
    fetchCourses();
  };

  const handleFilterChange = (filterType, value) => {
    if (filterType === 'location') setLocationFilter(value === 'All' ? '' : value);
    if (filterType === 'industry') setIndustryFilter(value === 'All' ? '' : value);
    if (filterType === 'sortBy') setSortBy(value);
    setOpenDropdown(null);
  };

  const handleShare = (seminarTitle) => {
    const url = `https://example.com/seminars/${seminarTitle.replace(/\s+/g, '-').toLowerCase()}`;
    navigator.clipboard.writeText(url).then(() => {
      alert(`Link to "${seminarTitle}" has been copied to clipboard: ${url}`);
    });
  };

  const handleCreateEvent = () => {
    if (userRole === 'educator' || userRole === 'organization') {
      navigate('/event-creation');
    } else {
      setShowRoleModal(true);
    }
  };

  const redirectToSignup = (role) => {
    navigate('/signup', { state: { role } });
  };

  const handleJoinClick = (seminar) => {
    setSelectedSeminar(seminar);
  };

  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  const filteredSeminars = seminars
    .filter(seminar => {
      return (
        (locationFilter ? seminar.location === locationFilter : true) &&
        (industryFilter ? seminar.industry === industryFilter : true)
      );
    })
    .sort((a, b) => {
      if (sortBy === 'newest') return new Date(b.createdAt) - new Date(a.createdAt);
      if (sortBy === 'oldest') return new Date(a.createdAt) - new Date(b.createdAt);
      if (sortBy === 'mostJoined') return b.attendees.length - a.attendees.length;
      return 0;
    });

  const handleRegistrationSubmit = async (registrationData) => {
    if (!selectedSeminar) return;

    try {
      await taxios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/seminars/${selectedSeminar._id}/register`,
        registrationData
      );
      setSelectedSeminar(null);
      fetchSeminars();
    } catch (error) {
      console.error('Error registering for seminar:', error);
    }
  };

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <div className="seminars-page">
      <TopNavBar 
        toggleSidebar={toggleSidebar} 
        onLogout={onLogout} 
        onCreateEvent={handleCreateEvent} 
      />
      <CommunitySidebar isOpen={sidebarOpen} />
      <div className={`seminars-page__content ${sidebarOpen ? 'sidebar-open' : ''}`}>
        <div className="seminars-page__filters">
          <div className="seminars-page__custom-dropdown">
            <button className="seminars-page__custom-dropdown-button" onClick={() => toggleDropdown('sortBy')}>
              <span>{sortBy ? `Sort By: ${sortBy}` : 'Sort By'}</span>
            </button>
            {openDropdown === 'sortBy' && (
              <ul className="seminars-page__custom-dropdown-options">
                <li onClick={() => handleFilterChange('sortBy', 'newest')}>Newest</li>
                <li onClick={() => handleFilterChange('sortBy', 'oldest')}>Oldest</li>
                <li onClick={() => handleFilterChange('sortBy', 'mostJoined')}>Most Joined</li>
              </ul>
            )}
          </div>
          <div className="seminars-page__custom-dropdown">
            <button className="seminars-page__custom-dropdown-button" onClick={() => toggleDropdown('location')}>
              <span>{locationFilter ? `Location: ${locationFilter}` : 'Filter by Location'}</span>
            </button>
            {openDropdown === 'location' && (
              <ul className="seminars-page__custom-dropdown-options">
                <li onClick={() => handleFilterChange('location', 'All')}>All Locations</li>
                {locations.map((location, index) => (
                  <li key={index} onClick={() => handleFilterChange('location', location)}>
                    {location}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="seminars-page__custom-dropdown">
            <button className="seminars-page__custom-dropdown-button" onClick={() => toggleDropdown('industry')}>
              <span>{industryFilter ? `Industry: ${industryFilter}` : 'Filter by Industry'}</span>
            </button>
            {openDropdown === 'industry' && (
              <ul className="seminars-page__custom-dropdown-options">
                <li onClick={() => handleFilterChange('industry', 'All')}>All Industries</li>
                {industries.map((industry, index) => (
                  <li key={index} onClick={() => handleFilterChange('industry', industry)}>
                    {industry}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="seminars-page__main-section">
          <div className="seminars-page__seminars-container" ref={seminarContainerRef}>
            {filteredSeminars.length === 0 ? (
              <div>No seminars available.</div>
            ) : (
              filteredSeminars.map((seminar, index) => (
                <div key={seminar._id || index} className="seminars-page__seminar-item">
                  <div className="seminars-page__seminar-thumbnail">
                    <img src={seminar.thumbnail} alt="Seminar Thumbnail" />
                  </div>
                  <div className="seminars-page__seminar-info-row">
                    <div className="seminars-page__seminar-details">
                      <h2 className="seminars-page__seminar-title">{seminar.title}</h2>
                      <span className="seminars-page__creator-name">{seminar.creator}</span>
                      <div className="seminars-page__seminar-info">
                        <strong>Location:</strong> {seminar.location} <br />
                        <strong>Time:</strong> {seminar.time} <br />
                        <strong>Attendees:</strong> {seminar.attendees.length}
                      </div>
                    </div>
                    <FaShare className="seminars-page__share-icon" onClick={() => handleShare(seminar.title)} />
                    <button className="seminars-page__join-button" onClick={() => handleJoinClick(seminar)}>Join</button>
                  </div>
                </div>
              ))
            )}
            <div ref={seminarLoadMoreRef}></div>
          </div>
          <div className="seminars-page__courses-container" ref={coursesContainerRef}>
            <h2 className="seminars-page__courses-title">Courses</h2>
            <div className="seminars-page__courses-list">
              {courses.length === 0 ? (
                <div>No courses available.</div>
              ) : (
                courses.map((course, index) => (
                  <div 
                    key={course._id || index} 
                    className="seminars-page__course-item"
                    onClick={() => handleCourseClick(course._id)} // Navigate to course profile
                  >
                    <div className="seminars-page__course-thumbnail">
                      <img
                        src={
                          course?.courseThumbnailURL
                            ? course.courseThumbnailURL.startsWith('http')
                              ? course.courseThumbnailURL
                              : `https://archi-video-bucket.s3.us-west-1.amazonaws.com/thumbnails/${course.courseThumbnailURL}`
                            : 'https://via.placeholder.com/150'
                        }
                        alt="Course Thumbnail"
                      />
                    </div>
                    <div className="seminars-page__course-info">
                      <img 
                        src={course.creatorID.profilePictureURL} 
                        alt="Creator Profile" 
                        className="seminars-page__profile-picture"
                      />
                      <div className="seminars-page__course-details">
                        <h2>{course.courseTitle}</h2>
                        <span className="seminars-page__creator-name">{course.creatorID.username}</span>
                        <div className="seminars-page__rating">
                          {[...Array(5)].map((_, i) => (
                            <FaStar key={i} color={i < Math.round(course.rating) ? "#ffc107" : "#e4e9f0"} />
                          ))}
                          <span>{course.rating?.toFixed(1)}</span>
                        </div>
                      </div>
                      <FaShare className="seminars-page__share-icon" onClick={() => handleShare(course.courseTitle)} />
                    </div>
                  </div>
                ))
              )}
              <div ref={courseLoadMoreRef}></div>
            </div>
          </div>
        </div>
      </div>

      {showRoleModal && (
        <div className="seminars-page__role-modal">
          <div className="seminars-page__role-modal-content" ref={modalRef}>
            <button className="seminars-page__close-button" onClick={() => setShowRoleModal(false)}>×</button>
            <h2>Create</h2>
            <div className="seminars-page__role-options">
              <div className="seminars-page__role-option">
                <h3>Educator</h3>
                <button onClick={() => redirectToSignup('educator')} className="seminars-page__create-account-button">Create an account</button>
              </div>
              <div className="seminars-page__role-option">
                <h3>Organization</h3>
                <button onClick={() => redirectToSignup('organization')} className="seminars-page__create-account-button">Create an account</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedSeminar && (
        <RegistrationModal
          seminar={selectedSeminar}
          onClose={() => setSelectedSeminar(null)}
          onSubmit={handleRegistrationSubmit}
        />
      )}
    </div>
  );
};

export default SeminarsPage;
