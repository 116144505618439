import React, { useState, useEffect } from 'react';
import taxios from '../util/token_refresh_hook';

const VideoPlayer = ({ videoId, isIntroVideo = false }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      if (!videoId) return;

      try {
        setIsLoading(true);
        setError(null);

        // Fixed endpoint paths
        const endpoint = isIntroVideo
          ? `${process.env.REACT_APP_BACKEND_URL}/api/videos/generate-media-url/${encodeURIComponent(videoId)}`
          : `${process.env.REACT_APP_BACKEND_URL}/api/videos/generate-course-video-url/${encodeURIComponent(videoId)}`;

        console.log('Fetching video URL from:', endpoint); // Debug log

        const response = await taxios.get(endpoint);

        if (response.data && response.data.url) {
          console.log('Received video URL:', response.data.url); // Debug log
          setVideoUrl(response.data.url);
        } else {
          throw new Error('Invalid video URL response');
        }
      } catch (error) {
        console.error('Error fetching video URL:', error);
        console.error('Error details:', error.response?.data);
        setError(error.response?.data?.error || 'Failed to load video');
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideoUrl();

    return () => {
      setVideoUrl('');
      setError(null);
    };
  }, [videoId, isIntroVideo]);

  const handleVideoError = (e) => {
    console.error('Video playback error:', e);
    console.error('Video source:', videoUrl); // Debug log
    setError('Failed to play video. Please try again later.');
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-4">
        <p>Loading video...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center p-4 text-red-500">
        <p>{error}</p>
        <p className="text-sm mt-2">Video ID: {videoId}</p> {/* Debug info */}
      </div>
    );
  }

  return (
    <div className="video-container">
      {videoUrl ? (
        <video
          width="100%"
          height="auto"
          controls
          className="max-w-full rounded-lg"
          playsInline
          controlsList="nodownload"
          onError={handleVideoError}
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div className="flex items-center justify-center p-4 bg-gray-100 rounded-lg">
          <p>No video available</p>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;