import React, { useState, useEffect } from 'react';
import useSWR from "swr";
import fetcher from "../../util/fetcher.js";
import taxios from "../../util/token_refresh_hook";
import Post from '../HomePage/QuestionThread/Post.jsx';
import { toast } from 'react-toastify';
import './CourseComments.css';

const CourseComments = ({ courseId, user }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showComments, setShowComments] = useState(true);

  // SWR fetch
  const { data, error: swrError } = useSWR(
    courseId ? `${process.env.REACT_APP_BACKEND_URL}/api/comments/post/${courseId}` : null,
    fetcher
  );

  // Update comments from SWR data
  useEffect(() => {
    if (data?.payload?.comments) {
      const fetchedComments = data.payload.comments;
      setComments(Array.isArray(fetchedComments) ? fetchedComments : []);
      setError(null);
    }
    if (swrError) {
      console.error(`Failed to fetch comments for course with ID ${courseId}:`, swrError);
      setError('Failed to load comments');
      toast.error('Failed to load comments');
    }
  }, [data, swrError, courseId]);

  // Add new comment
  const handleAddComment = async () => {
    if (!newComment.trim()) return;
    
    try {
      const response = await taxios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/comments`,
        {
          parent: courseId,
          body: newComment.trim()
        }
      );

      if (response.data?.payload) {
        const newCommentData = {
          ...response.data.payload,
          authorInfo: {
            id: user.id || user._id,
            username: user.username,
            profilePicture: user.profilePicture
          }
        };
        setComments(prevComments => [newCommentData, ...prevComments]);
        setNewComment('');
        toast.success('Comment added successfully');
      }
    } catch (err) {
      console.error('Error adding comment:', err);
      toast.error('Failed to add comment');
    }
  };

  // Delete comment
  const handleDeleteComment = async (commentId) => {
    try {
      await taxios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/comments/${commentId}`
      );
      setComments(prevComments => 
        prevComments.filter(comment => comment._id !== commentId)
      );
      toast.success('Comment deleted successfully');
    } catch (err) {
      console.error('Error deleting comment:', err);
      toast.error('Failed to delete comment');
    }
  };

  // Format comment as post
  const formatCommentAsPost = (comment) => ({
    _id: comment._id,
    body: comment.body,
    createdAt: comment.createdAt,
    updatedAt: comment.updatedAt,
    authorInfo: {
      id: comment.authorInfo?.id || comment.authorInfo?._id,
      username: comment.authorInfo?.username || 'Anonymous',
      profilePicture: comment.authorInfo?.profilePicture,
    },
    parent: courseId,
    likes: comment.likes || [],
    dislikes: comment.dislikes || [],
    isComment: true
  });

  return (
    <div className="comments-section">
      <h2>Course Discussion</h2>
      
      {/* Comment Input Section */}
      <div className="comment-box">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Share your thoughts about this course..."
          className="comment-textarea"
        />
        <button
          onClick={handleAddComment}
          disabled={!newComment.trim()}
          className="comment-submit-btn"
        >
          Post Comment
        </button>
      </div>

      {/* Error Message */}
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {/* Comments List */}
      <div className="comments-list">
        {isLoading ? (
          <div className="loading-comments">Loading comments...</div>
        ) : comments.length > 0 ? (
          comments.map((comment) => (
            <Post
              key={comment._id}
              post={formatCommentAsPost(comment)}
              author={comment.authorInfo}
              onDelete={() => handleDeleteComment(comment._id)}
              isComment={true}
              currentUser={user}
              disableReply={true}
              showActions={true}
              onHitCommentBtn={() => {}}
              onHitLikeBtn={() => {}}
              onHitDislikeBtn={() => {}}
            />
          ))
        ) : (
          <div className="no-comments">
            Be the first to comment on this course!
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseComments;