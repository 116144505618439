import React, { useState } from 'react';
import { ImagePlus } from 'lucide-react';
import taxios from '../../../util/token_refresh_hook';
import usePaginationStore from "../Pagination/paginationStore";
import useQuestionStore from "../QuestionList/questionStore";
import useStudyGroupStore from "../CreateStudyGroupForm/studyGroupStore";

import './QuestionForm.css';

export default function QuestionForm({ handleFormClose }) {
  const [body, setBody] = useState("");
  const [tags, setTags] = useState("");
  const [mediaFile, setMediaFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { addQuestion, triggerRevalidation } = useQuestionStore();
  const { selectedGroup } = useStudyGroupStore();
  const { setCurrentPage } = usePaginationStore();

  const handleMediaSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    if (file.type.startsWith('video/') || file.type.startsWith('image/')) {
      setMediaFile(file);
    } else {
      alert('Please select an image or video file');
    }
  };

  const uploadMedia = async (file) => {
    const isVideo = file.type.startsWith('video/');
    
    try {
      // Get signed URL
      const { data } = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api${isVideo ? '/videos/generate-signed-url' : '/videos/generate-thumbnail-url'}`,
        {
          params: {
            filename: file.name,
            contentType: file.type
          }
        }
      );

      // Upload to signed URL using fetch instead of Axios
      const uploadResponse = await fetch(data.signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type
        },
        body: file
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed with status ${uploadResponse.status}`);
      }

      // Extract filename from URL
      const filename = data.signedUrl.split('/').pop().split('?')[0];

      return {
        type: isVideo ? 'video' : 'image',
        mediaKey: filename
      };
    } catch (error) {
      console.error('Error uploading media:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!body.trim() && !mediaFile) {
      alert('Please add some text or media to your post');
      return;
    }

    if (!selectedGroup) {
      alert('Please select a study group before posting.');
      return;
    }

    setIsUploading(true);

    try {
      let mediaData = null;
      if (mediaFile) {
        mediaData = await uploadMedia(mediaFile);
      }

      const postData = {
        body: body.trim(),
        tags: tags.split(" ").map(tag => tag.trim()).filter(tag => tag),
        study_group: selectedGroup._id,
        ...(mediaData && { media: mediaData })
      };

      const response = await taxios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/posts`,
        postData
      );

      triggerRevalidation();
      setCurrentPage(1);
      addQuestion(response.data.payload);

      // Clear form
      setBody("");
      setTags("");
      setMediaFile(null);
      setUploadProgress(0);
      handleFormClose();
    } catch (error) {
      console.error("Error creating post:", error);
      alert('Failed to create post. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="post-form-wrapper">
      <div className="post-form-container">
        <form onSubmit={handleSubmit}>
          <textarea
            value={body}
            onChange={(e) => setBody(e.target.value)}
            placeholder="Share something helpful..."
            className="post-input"
          />
          <input
            type="text"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder="Add tags (space separated)"
            className="tags-input"
          />
          <div className="form-footer">
            <div className="media-upload">
              <label className="media-button">
                <input
                  type="file"
                  onChange={handleMediaSelect}
                  accept="image/*,video/*"
                />
                <ImagePlus className="media-icon" /> Add Media
              </label>
              {mediaFile && (
                <span className="media-name">
                  {mediaFile.name}
                  {uploadProgress > 0 && uploadProgress < 100 && 
                    ` (${uploadProgress}%)`
                  }
                </span>
              )}
            </div>
            <button 
              type="submit" 
              className="post-button"
              disabled={isUploading}
            >
              {isUploading ? 'Uploading...' : 'Post'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
