import React, { useState } from 'react';
import './RegistrationModal.css';
import taxios from '../../util/token_refresh_hook'; // Assuming you have a custom axios instance

const RegistrationModal = ({ seminar, onClose, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [jobTitle, setJobTitle] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !firstName || !lastName) {
      alert('Email, First Name, and Last Name are required.');
      return;
    }

    const registrationData = { email, firstName, lastName, company, jobTitle };

    try {
      const response = await taxios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/seminars/${seminar._id}/register`,
        registrationData
      );
      console.log('Registration successful:', response.data);
      onSubmit(registrationData); // Call the onSubmit function passed from the parent component
      onClose(); // Close the modal on successful submission
    } catch (error) {
      console.error('Error registering for seminar:', error);
      alert('Failed to register. Please try again later.');
    }
  };

  return (
    <div className="registration-modal">
      <div className="registration-modal-content">
        <button className="close-button" onClick={onClose}>x</button>
        <h2>{seminar.title}</h2>
        <p><strong>Date, Time:</strong> {seminar.time}</p>
        <p><strong>Location, Company:</strong> {seminar.location}</p>
        <p>Register for this event with your Archi Studies Profile</p>
        <form onSubmit={handleSubmit}>
          <input 
            type="email" 
            placeholder="Email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
          <input 
            type="text" 
            placeholder="First Name" 
            value={firstName} 
            onChange={(e) => setFirstName(e.target.value)} 
            required 
          />
          <input 
            type="text" 
            placeholder="Last Name" 
            value={lastName} 
            onChange={(e) => setLastName(e.target.value)} 
            required 
          />
          <input 
            type="text" 
            placeholder="Company/School" 
            value={company} 
            onChange={(e) => setCompany(e.target.value)} 
          />
          <input 
            type="text" 
            placeholder="Job Title/Major" 
            value={jobTitle} 
            onChange={(e) => setJobTitle(e.target.value)} 
          />
          <button className="register-button" type="submit">Register</button>
        </form>
      </div>
      <div className="registration-modal-overlay" onClick={onClose}></div>
    </div>
  );
};

export default RegistrationModal;