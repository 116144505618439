import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './SignupPage.css';
import logo from '../../../assets/LogoWithName.png';
import GoogleSignIn from '../Login/GoogleSignIn'; // Import the GoogleSignIn component
import AutocompleteInput from '../../AutoComplete/AutoCompleteInput'; // For Google Places (school input)
import KnowledgeGraphAutocomplete from '../../AutoComplete/KnowledgeGraphAutoComplete';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignupPage = ({ onSignup }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (location.state && location.state.role) {
      setRole(location.state.role);
    }
  }, [location]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRoleSelection = (selectedRole) => {
    setRole(selectedRole.toLowerCase());
    setFormData({});
  };

  const handleFormDataChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

	console.log("form data:", formData)

  const handleSignup = async (event) => {
    event.preventDefault();
    const payload = {
      username: name,
      email,
      password,
      role,
    };

    console.log("form data:", formData);

    if (role === 'student') {
      payload.school = formData.school || '';
      payload.major = formData.major || '';
      payload.employer = formData.employer || '';
      payload.job_title = formData.job_title || '';
      payload.aspiration = formData.aspiration || '';
    } else if (role === 'educator') {
      payload.plan_to_teach = formData.plan_to_teach || '';
      payload.school_attended = formData.school_attended || ''; 
      payload.employer = formData.employer || '';
      payload.job_title = formData.job_title || '';
    } else if (role === 'organization') {
      payload.organization_name = formData.organization_name || '';
      payload.industry = formData.industry || '';
      payload.primary_service = formData.primary_service || '';
      payload.job_title = formData.job_title || '';
    }

    console.log('Payload being sent to backend:', payload);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/register`,
        payload,
        { withCredentials: true } // Allows setting of the cookies after registration
      );

      // Add a check for response and response.data
      if (response && response.data) {
        onSignup(response.data); // Call the onSignup function to update the state
      } else {
        throw new Error('Server returned an invalid response');
      }

    } catch (error) {
      console.error('Error creating account:', error.response ? error.response.data : error.message);
    }
  };


  const renderRoleSpecificForm = () => {
    return (
      <div className="signup-role-form">
        {/* Student Role */}
        {role === 'student' && (
          <>
            {/* Autocomplete for School */}
            <AutocompleteInput
              placeholder="Where do you go to school?"
              className="signup-role-input"
              onSelection={(school) => handleFormDataChange('school', school)}
            />

            {/* Knowledge Graph for Major */}
            <KnowledgeGraphAutocomplete
              placeholder="What are you studying?"
              className="signup-role-input"
              onSelection={(major) => handleFormDataChange('major', major)}
              type="major"
            />

            {/* Knowledge Graph for Employer */}
            <KnowledgeGraphAutocomplete
              placeholder="Where do you work?"
              className="signup-role-input"
              onSelection={(employer) => handleFormDataChange('employer', employer)}
            />

            {/* Knowledge Graph for Job Title */}
            <KnowledgeGraphAutocomplete
              placeholder="What do you do?"
              className="signup-role-input"
              onSelection={(jobTitle) => handleFormDataChange('job_title', jobTitle)}
            />

            <input
              type="text"
              placeholder="What are you looking to learn about?"
              className="signup-role-input"
              value={formData.aspiration || ''}
              onChange={(e) => handleFormDataChange('aspiration', e.target.value)}
            />
          </>
        )}

        {/* Educator Role */}
        {role === 'educator' && (
          <>
            <input
              type="text"
              placeholder="What do you plan to teach?"
              className="signup-role-input"
              value={formData.plan_to_teach || ''}
              onChange={(e) => handleFormDataChange('plan_to_teach', e.target.value)}
            />

            {/* Autocomplete for School Attended */}
            <AutocompleteInput
              placeholder="Where did you go to school?"
              className="signup-role-input"
              onSelection={(school_attended) => handleFormDataChange('school_attended', school_attended)}
            />

            {/* Knowledge Graph for Employer */}
            <KnowledgeGraphAutocomplete
              placeholder="Where do you work?"
              className="signup-role-input"
              onSelection={(employer) => handleFormDataChange('employer', employer)}
            />

            <KnowledgeGraphAutocomplete
              placeholder="What do you do?"
              className="signup-role-input"
              onSelection={(jobTitle) => handleFormDataChange('job_title', jobTitle)}
            />
          </>
        )}

        {/* Organization Role */}
        {role === 'organization' && (
          <>
            <input
              type="text"
              placeholder="Name of your Organization"
              className="signup-role-input"
              value={formData.organization_name || ''}
              onChange={(e) => handleFormDataChange('organization_name', e.target.value)}
            />

            {/* Knowledge Graph Autocomplete for Industry */}
            <KnowledgeGraphAutocomplete
              placeholder="What industry are you in?"
              className="signup-role-input"
              onSelection={(industry) => handleFormDataChange('industry', industry)}
            />

            <input
              type="text"
              placeholder="Who do you serve primarily?"
              className="signup-role-input"
              value={formData.primary_service || ''}
              onChange={(e) => handleFormDataChange('primary_service', e.target.value)}
            />

            {/* Knowledge Graph Autocomplete for Job Title */}
            <KnowledgeGraphAutocomplete
              placeholder="What is your role?"
              className="signup-role-input"
              onSelection={(jobTitle) => handleFormDataChange('job_title', jobTitle)}
              type="job"
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="signup-page">
      <div className="signup-form-section">
        <img src={logo} alt="Logo with Name" className="signup-form-logo-image" />
        <div className="signup-form-header">
          <h2>Create your account</h2>
        </div>
        <div className={`signup-buttons-container ${role ? 'role-selected' : ''}`}>
          <div className="role-selection-arrows">
            <div className="middle-arrow">↓</div>
          </div>
          <div className="signup-buttons">
            <button
              className={`signup-role-button ${role === 'student' ? 'active' : ''}`}
              onClick={() => handleRoleSelection('student')}
            >
              Student
            </button>
            <button
              className={`signup-role-button ${role === 'educator' ? 'active' : ''}`}
              onClick={() => handleRoleSelection('educator')}
            >
              Educator
            </button>
            <button
              className={`signup-role-button ${role === 'organization' ? 'active' : ''}`}
              onClick={() => handleRoleSelection('organization')}
            >
              Organization
            </button>
          </div>
        </div>
        <form className="signup-form" onSubmit={handleSignup}>
          <input
            type="text"
            placeholder="Username"
            className="signup-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            className="signup-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {renderRoleSpecificForm()}
          <div className="signup-password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              className="signup-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="signup-toggle-password-button"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="signup-terms-checkbox">
            <input
              type="checkbox"
              id="terms"
              name="terms"
              value={formData.terms || ''}
              onChange={(e) => handleFormDataChange('terms', e.target.checked)}
            />
            <label htmlFor="terms">I agree to the Terms of Service and Privacy Policy</label>
          </div>
          <button type="submit" className="signup-submit-button">Create your account</button>
        </form>
        <div className="signup-divider">
          <span>OR</span>
        </div>
        <GoogleSignIn/>
      </div>
      <div className="signup-logo-section">
        <img src={logo} alt="Logo with Name" className="logo-image" />
      </div>
    </div>
  );
};

export default SignupPage;
