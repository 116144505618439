import React from 'react';
import { format } from 'date-fns';

const ApplicationsSection = ({ applications }) => {
  return (
    <div className="applications-container grid gap-4">
      {applications.map((application) => (
        <div 
          key={application._id} 
          className="application-item bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md"
        >
          <div className="flex justify-between items-start mb-4">
            <div>
              <h3 className="text-lg font-semibold mb-1">{application.internshipTitle || 'Untitled Internship'}</h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">{application.companyName}</p>
            </div>
            <span className={`px-3 py-1 rounded-full text-sm ${
              application.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
              application.status === 'accepted' ? 'bg-green-100 text-green-800' :
              application.status === 'rejected' ? 'bg-red-100 text-red-800' :
              'bg-gray-100 text-gray-800'
            }`}>
              {application.status}
            </span>
          </div>

          <div className="space-y-2">
            <div className="flex items-center">
              <span className="text-sm font-medium mr-2">Email:</span>
              <span className="text-sm">{application.email}</span>
            </div>
            {application.phoneNumber && (
              <div className="flex items-center">
                <span className="text-sm font-medium mr-2">Phone:</span>
                <span className="text-sm">{application.phoneNumber}</span>
              </div>
            )}
            {application.resumeUrl && (
              <div className="flex items-center">
                <a 
                  href={application.resumeUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 text-sm"
                >
                  View Resume
                </a>
              </div>
            )}
            <div className="text-sm text-gray-500">
              Applied: {format(new Date(application.createdAt), 'MMM dd, yyyy')}
            </div>
          </div>

          {application.answers && (
            <div className="mt-4 border-t pt-4">
              <h4 className="text-sm font-medium mb-2">Application Answers:</h4>
              <div className="space-y-2">
                {application.answers.split(',').map((answer, index) => (
                  <p key={index} className="text-sm">{answer}</p>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ApplicationsSection;