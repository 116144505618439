import React, { useState, useEffect, useCallback, useRef } from 'react';
import './ChatPage.css';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import { FaVideo, FaPhone, FaUserPlus, FaImage, FaSmile, FaTimes } from 'react-icons/fa';
import taxios from '../../util/token_refresh_hook';
import io from 'socket.io-client';
import { toast } from 'react-toastify';

function ChatPage({ toggleSidebar, sidebarOpen, onLogout }) {
  const [selectedChat, setSelectedChat] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [chats, setChats] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const [chatsLoaded, setChatsLoaded] = useState(false);
  const messagesEndRef = useRef(null);


  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getCookie('accessToken')}`
    }
  };


  // Socket initialization
  useEffect(() => {
    const token = getCookie('accessToken');
    console.log('Access Token:', token);

    if (!token) {
      console.error('No access token found');
      return;
    }

    const newSocket = io(process.env.REACT_APP_CHAT_SERVICE_URL, { 
      withCredentials: true,
      auth: { token }
    });

    setSocket(newSocket);
    return () => newSocket.close();
  }, []);

  // Socket message handler
  useEffect(() => {
    if (!socket) return;

    const handleMessage = (message) => {
      console.log('New message received:', message);
      setChats(prevChats => 
        prevChats.map(chat => 
          chat._id === message.chatId
            ? { 
                ...chat, 
                messages: [...chat.messages, { 
                  ...message, 
                  sender: { _id: message.senderId },
                  time: new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                }] 
              }
            : chat
        )
      );

      if (selectedChat?._id === message.chatId) {
        setSelectedChat(prev => ({
          ...prev,
          messages: [...prev.messages, { 
            sender: { _id: message.senderId }, 
            content: message.content, 
            time: new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) 
          }]
        }));
      }
    };

    socket.on('new message', handleMessage);
    return () => socket.off('new message', handleMessage);
  }, [socket, selectedChat, newMessage, currentUser]);

  // Current user fetch
  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await taxios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/users/me`,
          config
        );
        setCurrentUser(response.data.payload);
      } catch (error) {
        console.error('Error fetching current user:', error);
        toast.error('Failed to load user profile');
      }
    };

    fetchCurrentUser();
  }, []);

  // Fetch chats when current user is loaded
  useEffect(() => {
    if (currentUser?._id) {
      fetchChats();
    }
  }, [currentUser]);

  // Handle opening a chat
  useEffect(() => {
    if (selectedChat && messagesEndRef.current) {
      //scroll to the bottom of new chat
      messagesEndRef.current.scrollIntoView();
    }
  }, [chats, selectedChat]);

  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
    if (socket?.connected) {
      socket.emit('join chat', chat._id);
    }
  };

  const getTitle = (user) => {
    if (!user) return 'N/A';
    switch(user.role) {
      case 'student':
        return `${user.school || 'N/A'} - ${user.major || 'N/A'}`;
      case 'educator':
        return `${user.employer || 'N/A'} - ${user.job_title || 'N/A'}`;
      case 'organization':
        return `${user.organization_name || 'N/A'} - ${user.industry || 'N/A'}`;
      default:
        return 'N/A';
    }
  };

  const fetchChatMessages = useCallback(async (chatId) => {
    try {
      const response = await taxios.get(
        `${process.env.REACT_APP_CHAT_SERVICE_URL}/api/messages/chat/${chatId}`,
        config
      );
      const messagesData = Array.isArray(response.data.payload) ? response.data.payload : [];
      return messagesData.map(message => ({
        sender: message.senderId,
        content: message.content,
        time: new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      }));
    } catch (error) {
      console.error(`Error fetching messages for chat ${chatId}:`, error);
      toast.error('Failed to load messages');
      return [];
    }
  }, []);

  const fetchChats = useCallback(async () => {
    if (!currentUser?._id) return;

    try {
      const response = await taxios.get(
        `${process.env.REACT_APP_CHAT_SERVICE_URL}/api/chats/user/${currentUser._id}`,
        config
      );

      const chatsData = Array.isArray(response.data.payload) ? response.data.payload : [];
      
      const formattedChats = await Promise.all(chatsData.map(async chat => {
        const otherUser = chat.participants.find(p => p._id !== currentUser._id);
        const messages = await fetchChatMessages(chat._id);
        
        return {
          _id: chat._id,
          name: otherUser?.username || 'Unknown User',
          aspiration: otherUser?.plan_to_teach || 'N/A',
          title: getTitle(otherUser),
          time: new Date(chat.updatedAt || chat.createdAt).toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit' 
          }),
          avatar: otherUser?.avatar || '/default-avatar.png',
          messages: messages,
          participants: chat.participants
        };
      }));

      setChats(formattedChats);
      setChatsLoaded(true);
    } catch (error) {
      console.error("Error fetching chats:", error);
      toast.error('Failed to load chats');
      setChats([]);
      setChatsLoaded(false);
    }
  }, [currentUser, fetchChatMessages]);

    
  useEffect(() => {
    if(currentUser && !chatsLoaded){
      fetchChats().then(() => setChatsLoaded(true));
    }
  }, [fetchChats, currentUser, chatsLoaded]);

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !currentUser?._id || !selectedChat?._id) return;
    
    try {
      const response = await taxios.post(
        `${process.env.REACT_APP_CHAT_SERVICE_URL}/api/messages`,
        {
          chatId: selectedChat._id,
          senderId: currentUser._id,
          content: newMessage.trim(),
        },
        config
      );

      setNewMessage('');
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error('Failed to send message');
    }
  };

    // Inside the ChatPage component
  const handleDeleteChat = async (chatId) => {
    // Prompt the user for confirmation
    const confirmDelete = window.confirm("Are you sure you want to delete this message?");
    
    if (!confirmDelete) return; // Exit if the user cancels
    
    try {
      await taxios.delete(
        `${process.env.REACT_APP_CHAT_SERVICE_URL}/api/chats/${chatId}`,
        config
      );
      setChats((prevChats) => prevChats.filter((chat) => chat._id !== chatId));
      if (selectedChat?._id === chatId) {
        setSelectedChat(null); // Deselect chat if it's the deleted one
      }
      toast.success('Chat deleted');
    } catch (error) {
      console.error('Error deleting chat:', error);
      toast.error('Failed to delete chat');
    }
  };


  const handleStartNewChat = async () => {
    const newChatName = prompt('Enter the name of the person you want to chat with:');
    if (!newChatName?.trim() || !currentUser) return;

    try {
      const userResponse = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/by_uname/${newChatName}`,
        config
      );

      const otherUser = userResponse.data.payload;
      
      if (!otherUser) {
        toast.error('User not found');
        return;
      }

      if (otherUser._id === currentUser._id) {
        toast.error('Cannot create chat with yourself');
        return;
      }

      const existingChat = chats.find(chat => 
        chat.participants.some(p => p._id === otherUser._id)
      );

      if (existingChat) {
        setSelectedChat(existingChat);
        return;
      }

      const chatResponse = await taxios.post(
        `${process.env.REACT_APP_CHAT_SERVICE_URL}/api/chats`,
        {
          participants: [currentUser._id, otherUser._id],
          type: 'private'
        },
        config
      );

      if (chatResponse.data?.payload) {
        const newChat = chatResponse.data.payload;
        const formattedChat = {
          _id: newChat._id,
          name: otherUser.username,
          aspiration: otherUser.plan_to_teach || 'N/A',
          title: getTitle(otherUser),
          time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          avatar: otherUser.avatar || '/default-avatar.png',
          messages: [],
          participants: [currentUser, otherUser]
        };

        setChats(prevChats => [...prevChats, formattedChat]);
        setSelectedChat(formattedChat);

        if (socket) {
          socket.emit('join chat', newChat._id);
        }

        toast.success('Chat created successfully');
      }
    } catch (error) {
      console.error("Error creating new chat:", error);
      toast.error(error.response?.data?.message || 'Failed to create chat');
    }
  };

  const handleVideoCall = () => {
    toast.info('Video call functionality will be added soon');
  };

  const handlePhoneCall = () => {
    toast.info('Phone call functionality will be added soon');
  };

  return (
    <div className="chat-page">
      <TopNavBar toggleSidebar={toggleSidebar} onLogout={onLogout} />
      <div className="main-content">
        <CommunitySidebar isOpen={sidebarOpen} />
        <div className={`content ${sidebarOpen ? 'sidebar-open' : ''}`}>
          <div className="chat-sidebar">
            <div className="chat-header">
              <h2>Chats</h2>
              <FaUserPlus className="new-chat-icon" onClick={handleStartNewChat} />
            </div>
            <input type="text" placeholder="Search or start a chat" className="chat-search" />
            <div className="chat-list">
              {!chatsLoaded ? (
                <div>Loading chats...</div>
              ) : chats.length > 0 ? (
                chats.map((chat) => (
                  <div 
                    key={chat._id} 
                    className={`chat-item ${selectedChat?._id === chat._id ? 'selected' : ''}`} 
                    onClick={() => handleSelectChat(chat)}
                  >
                    <img src={chat.avatar} alt="Profile" className="profile-pic" />
                    <div className="chat-info">
                      <h4>{chat.name} {chat.hasNewMessage && <span className="new-message-indicator" />}</h4>
                      <p>{chat.messages.length > 0 ? chat.messages[chat.messages.length - 1].content : 'No messages yet'}</p>
                      <span className="time">{chat.time}</span>
                    </div>
                    <FaTimes
                      className="delete-chat-icon"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the chat selection
                        handleDeleteChat(chat._id);
                      }}
                    />
                  </div>
                ))
              ) : (
                <div>No chats yet</div>
              )}
            </div>
          </div>
          <div className="chat-window">
            {selectedChat ? (
              <>
                <div className="chat-window-header">
                  <img src={selectedChat.avatar || '/default-avatar.png'} alt="Profile" className="profile-pic" />
                  <div>
                    <h4>{selectedChat.name}</h4>
                    <p>{selectedChat.title} | Aspiration: {selectedChat.aspiration}</p>
                    {selectedChat.isTyping && <p className="typing-indicator">Typing...</p>}
                  </div>
                  <div className="chat-options">
                    <FaVideo className="chat-option-icon" onClick={handleVideoCall} />
                    <FaPhone className="chat-option-icon" onClick={handlePhoneCall} />
                  </div>
                </div>
                <div className="chat-messages">
                  {selectedChat.messages.length > 0 ? (
                    selectedChat.messages.map((message, index) => (
                      <div key={index} className="message-container">
                        {index === 0 || selectedChat.messages[index - 1].time !== message.time ? (
                          <div className="message-date">{message.time}</div>
                        ) : null}
                        <div className={`message ${message.sender && message.sender._id === currentUser?._id ? 'sent' : 'received'}`}>
                          <p>{message.content}</p>
                          <span className="message-time">{message.time}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-messages">No messages yet</div>
                  )}
                  <div ref={messagesEndRef} />
                </div>
                <div className="chat-input">
                  <FaImage className="input-icon" />
                  <FaSmile className="input-icon" />
                  <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a message"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage();
                      }
                    }}
                  />
                  <button 
                    onClick={handleSendMessage}
                    disabled={!newMessage.trim()}
                  >
                    Send
                  </button>
                </div>
              </>
            ) : (
              <div className="no-chat-selected">Select a chat to start messaging</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;