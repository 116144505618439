import axios from "axios";

// Constants
const MAX_DELTA_TO_EXPIRY = 43200; // 12 hours

// Create base axios instance with credentials
const axiosInst = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add request interceptor
axiosInst.interceptors.request.use(
  async function(config) {
    // Get the expiry cookies
    const accessTExpCVal = getCookie(process.env.REACT_APP_AETOK_NAME);
    const refreshTExpCVal = getCookie(process.env.REACT_APP_RETOK_NAME);
    
    if(!accessTExpCVal || !refreshTExpCVal) {
      // If no tokens exist, still need to send what we have in cookies
      const accessToken = getCookie('accessToken'); // or however you store it
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    }

    const accessTExp = getUnixTime(accessTExpCVal);
    const refreshTExp = getUnixTime(refreshTExpCVal);
    const now = getUnixTime(new Date());

    // Check if token needs refresh
    const delta2Exp = accessTExp - now;
    if(delta2Exp >= MAX_DELTA_TO_EXPIRY) {
      // No refresh needed, but still need to add the token to headers
      const accessToken = getCookie('accessToken'); // or however you store it
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      console.log("No need to refresh access token; delta to expiry:", delta2Exp);
      return config;
    }

    console.log(`Refreshing access token (delta: ${delta2Exp} < ${MAX_DELTA_TO_EXPIRY})`);

    try {
      // Only refresh token with main backend
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/refresh_token`,
        {},
        { withCredentials: true }
      );
      
      console.log("Access token refreshed");

      // Add the new token to the original request headers
      const newAccessToken = getCookie('accessToken'); // or however you get the new token
      if (newAccessToken) {
        config.headers.Authorization = `Bearer ${newAccessToken}`;
      }
    } catch(error) {
      console.error("Token refresh failed:", error);
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInst.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // If it's a 401 from the chat service, try refreshing token once
    if (error.response?.status === 401 && 
        !originalRequest._retry && 
        originalRequest.url.includes(process.env.REACT_APP_CHAT_SERVICE_URL)) {
      originalRequest._retry = true;

      try {
        // Refresh token with main backend
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/users/refresh_token`,
          {},
          { withCredentials: true }
        );

        // Update the failed request with new token
        const newAccessToken = getCookie('accessToken');
        if (newAccessToken) {
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        }

        // Retry the original request
        return axios(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed during 401 retry:", refreshError);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

//-- Utilities
/**
 * Gets the value of a cookie, if it exists.
 * @param {string} name The name of the cookie
 * @returns {?string} The cookie's value
 */

// Utility functions
export function getCookie(name) {
  const pair = document.cookie.split("; ").find(row => row.startsWith(`${name}=`));
  return pair ? pair.split("=")[1] : null;
}

/**
 * Converts a date to a Unix timestamp. See: https://stackoverflow.com/a/28683720
 * @param {String|Date} date The date to convert
 * @returns {Number} The resulting Unix timestamp
 */

export function getUnixTime(date) {
  const dateObject = date instanceof Date ? date : new Date(date);
  return parseInt((dateObject.getTime() / 1000).toFixed(0));
}

const taxios = axiosInst;
export default taxios;