export const termsContent = {
  TermsOfService: {
    title: "Terms of Service",
    content: `
      <h1>Terms of Service</h1>
      <p>Effective Date: 7/27/2024</p>
      <h2>1. Introduction</h2>
      <p>Welcome to Archi Studies ("we", "our", "us"). These Terms of Service ("Terms") govern your use of our website, courses, mentorship services, job listings, and other services (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms.</p>
      <h2>2. Acceptance of Terms</h2>
      <p>By using our Services, you confirm that you are at least 18 years old or have reached the age of majority in your jurisdiction. If you are under 18, you must have your parent or guardian's consent to use the Services.</p>
      <h2>3. Changes to Terms</h2>
      <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the Services after such changes constitutes your acceptance of the new Terms.</p>
      <h2>4. Privacy Policy</h2>
      <p>Your use of our Services is also governed by our Privacy Policy, which outlines how we collect, use, share, and protect your personal information.</p>
      <h2>5. Account Registration</h2>
      <p>To access certain features of the Services, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.</p>
      <h2>6. Courses</h2>
      <h3>6.1 Enrollment</h3>
      <p>When you enroll in a course, you get a license to view the course via the Archi Studies Services, and no other use. You are not allowed to transfer or resell courses in any way. We grant you a limited, non-exclusive, non-transferable license to access and view the courses and associated content for which you have paid all required fees, solely for your personal, non-commercial, educational purposes through the Services.</p>
      <h3>6.2 Course Content</h3>
      <p>The content provided in the courses is for informational purposes only. We do not guarantee that any course will lead to specific results or qualifications. We are not responsible for any actions you take based on the course content.</p>
      <h3>6.3 Refund Policy</h3>
      <p>If you are not satisfied with a course, you may request a refund within 30 days of purchase or before viewing 25% or more of the course, subject to our Refund Policy.</p>
      <h2>7. Mentorship</h2>
      <h3>7.1 Engagement</h3>
      <p>When you engage a mentor, you enter into a direct relationship with the mentor. We act as a facilitator but are not a party to the agreement between you and the mentor.</p>
      <h3>7.2 Payment</h3>
      <p>All payments for mentorship services are processed through our platform. We collect a commission on each transaction as specified in our fee schedule.</p>
      <h3>7.3 Mentor Conduct</h3>
      <p>Mentors are independent contractors and not employees of Archi Studies. We do not endorse any mentor and are not responsible for any advice or services provided by mentors.</p>
      <h3>7.4 Disputes</h3>
      <p>Any disputes between mentors and mentees must be resolved directly between the parties. We may assist in mediating disputes but are not obligated to do so.</p>
      <h2>8. Job Listings</h2>
      <h3>8.1 Job Postings</h3>
      <p>Organizations may post job listings on our platform. We are not responsible for the content of job postings or the actions of employers.</p>
      <h3>8.2 Application Process</h3>
      <p>When you apply for a job through our platform, you provide your information directly to the employer. We do not guarantee employment or the accuracy of job listings.</p>
      <h3>8.3 User Conduct</h3>
      <p>Users must not post false or misleading job information. We reserve the right to remove any job listings that violate our policies or applicable laws.</p>
      <h2>9. User Content</h2>
      <h3>9.1 Posting Content</h3>
      <p>You may post content, including comments, reviews, and other materials, on our platform. You retain ownership of your content but grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and display your content.</p>
      <h3>9.2 Prohibited Content</h3>
      <p>You agree not to post any content that is unlawful, defamatory, harassing, obscene, or otherwise objectionable. We reserve the right to remove any content that violates these Terms.</p>
      <h2>10. Intellectual Property</h2>
      <h3>10.1 Ownership</h3>
      <p>All content and materials available on our platform, including text, graphics, logos, and software, are the property of Archi Studies or its licensors and are protected by copyright, trademark, and other intellectual property laws.</p>
      <h3>10.2 Use of Content</h3>
      <p>You may access and use the content and materials on our platform for personal, non-commercial purposes only. You must not reproduce, distribute, modify, or create derivative works from any content without our express written permission.</p>
      <h2>11. Disclaimers and Limitation of Liability</h2>
      <h3>11.1 Disclaimers</h3>
      <p>Our Services are provided "as is" without warranties of any kind, either express or implied. We do not warrant that the Services will be uninterrupted or error-free.</p>
      <h3>11.2 Limitation of Liability</h3>
      <p>To the fullest extent permitted by law, we disclaim all liability for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of our Services. Our total liability to you for any claims arising from the use of our Services is limited to the amount you paid us in the past twelve months.</p>
      <h2>12. Indemnification</h2>
      <p>You agree to indemnify and hold Archi Studies, its officers, directors, employees, and agents harmless from any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or in any way connected with your use of our Services, your violation of these Terms, or your violation of any rights of another.</p>
      <h2>13. Governing Law and Dispute Resolution</h2>
      <p>These Terms are governed by and construed in accordance with the laws of San Jose, California, USA. Any disputes arising out of or relating to these Terms or our Services will be resolved through binding arbitration in San Jose, California, USA, except that either party may seek injunctive or other equitable relief in a court of competent jurisdiction.</p>
      <h2>14. Miscellaneous</h2>
      <h3>14.1 Severability</h3>
      <p>If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>
      <h3>14.2 Entire Agreement</h3>
      <p>These Terms constitute the entire agreement between you and Archi Studies regarding your use of the Services and supersede all prior agreements.</p>
      <h3>14.3 Waiver</h3>
      <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.</p>
    `,
  },
  PrivacyPolicy: {
    title: "Privacy Policy",
    content: `
      <h1>Privacy Policy</h1>
      <p>Effective Date: 7/27/2024</p>
      <h2>1. Introduction</h2>
      <p>Welcome to Archi Studies ("we", "our", "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, share, and protect your information when you use our website and services.</p>
      <h2>2. Information We Collect</h2>
      <h3>2.1 Information You Give Us</h3>
      <p>When you create an account, buy courses, post comments, join events, apply for jobs, pay for mentorship, chat with other students, or set up your profile page, you provide us with personal information that includes your name, email address, phone number, payment information, and any other information you choose to provide.</p>
      <h3>2.2 Information We Get From Your Use of Our Services</h3>
      <p>We collect information about the services that you use and how you use them. This information includes:</p>
      <ul>
        <li><strong>Device Information:</strong> We collect device-specific information, such as your hardware model, operating system version, unique device identifiers, and mobile network information.</li>
        <li><strong>Log Information:</strong> When you use our services or view content provided by Archi Studies, we automatically collect and store certain information in server logs. This includes details of how you used our service, Internet protocol (IP) address, device event information, and cookies that may uniquely identify your browser or your account.</li>
        <li><strong>Location Information:</strong> When you use Archi Studies services, we may collect and process information about your actual location using various technologies, including IP address, GPS, and other sensors.</li>
        <li><strong>Cookies and Similar Technologies:</strong> We use cookies and similar technologies to collect and store information when you visit our website, interact with our services, and use our apps.</li>
      </ul>
      <h2>3. How We Use Information We Collect</h2>
      <p>We use the information we collect from all our services to provide, maintain, protect, and improve them, to develop new ones, and to protect Archi Studies and our users. We also use this information to offer you tailored content – like giving you more relevant course recommendations and ads.</p>
      <ul>
        <li><strong>Providing Services:</strong> We use your information to process your transactions, manage your account, and provide customer support.</li>
        <li><strong>Improving Services:</strong> We use your information to understand how users interact with our services, to troubleshoot and fix issues, and to improve the overall user experience.</li>
        <li><strong>Communication:</strong> We may use your email address or other contact information to send you information about our services, updates, marketing communications, or promotional offers.</li>
        <li><strong>Personalization:</strong> We use your information to personalize your experience on Archi Studies, such as recommending courses, events, or jobs based on your interests and interactions.</li>
        <li><strong>Legal Compliance:</strong> We may use your information to comply with applicable laws, regulations, and legal processes.</li>
      </ul>
      <h2>4. Information We Share</h2>
      <p>We do not share personal information with companies, organizations, and individuals outside of Archi Studies unless one of the following circumstances applies:</p>
      <ul>
        <li><strong>With Your Consent:</strong> We will share personal information with companies, organizations, or individuals outside of Archi Studies when we have your consent to do so.</li>
        <li><strong>For External Processing:</strong> We provide personal information to our affiliates or other trusted businesses or persons to process it for us, based on our instructions and in compliance with our Privacy Policy.</li>
        <li><strong>For Legal Reasons:</strong> We will share personal information with companies, organizations, or individuals outside of Archi Studies if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to:</li>
        <ul>
          <li>Meet any applicable law, regulation, legal process, or enforceable governmental request.</li>
          <li>Enforce applicable Terms of Service, including investigation of potential violations.</li>
          <li>Detect, prevent, or otherwise address fraud, security, or technical issues.</li>
          <li>Protect against harm to the rights, property, or safety of Archi Studies, our users, or the public as required or permitted by law.</li>
        </ul>
        <li><strong>Business Transfers:</strong> If Archi Studies is involved in a merger, acquisition, or asset sale, your personal information may be transferred. We will provide notice before your personal information is transferred and becomes subject to a different privacy policy.</li>
      </ul>
      <h2>5. Your Controls and Choices</h2>
      <ul>
        <li><strong>Managing Your Information:</strong> You can access, update, and delete your personal information by logging into your account and going to your account settings.</li>
        <li><strong>Communication Preferences:</strong> You can opt out of receiving promotional communications from us by following the instructions in those communications. If you opt out, we may still send you non-promotional communications, such as those about your account or our ongoing business relations.</li>
        <li><strong>Ad Personalization:</strong> You can control the information we use to show you ads by adjusting your ad settings in your account.</li>
      </ul>
      <h2>6. Data Security</h2>
      <p>We work hard to protect Archi Studies and our users from unauthorized access, alteration, disclosure, or destruction of information we hold. We implement a variety of security measures, including encryption and access controls, to safeguard your personal information.</p>
      <h2>7. Data Retention</h2>
      <p>We retain personal information for as long as necessary to provide our services, fulfill the transactions you have requested, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements.</p>
      <h2>8. Children's Privacy</h2>
      <p>Our services are not directed to children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p>
      <h2>9. International Users</h2>
      <p>If you are accessing our services from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located and our central database is operated. By using our services, you consent to the transfer of your information to our facilities as described in this Privacy Policy.</p>
      <h2>10. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
    `,
  },
  AcceptableUse: {
    title: "Acceptable Use Policy",
    content: `
      <h1>Acceptable Use Policy & Community Guidelines</h1>
      <p>Effective Date: 7/27/2024</p>
      <h2>1. Introduction</h2>
      <p>Welcome to Archi Studies ("we", "our", "us"). To ensure a safe and respectful environment for all users, we have established these Acceptable Use Policy & Community Guidelines ("Guidelines"). By using our platform, you agree to adhere to these Guidelines.</p>
      <h2>2. Acceptable Use Policy</h2>
      <h3>2.1 Acceptable Behaviors</h3>
      <ul>
        <li>Engage in respectful and constructive communication.</li>
        <li>Share knowledge and resources that are relevant to the community.</li>
        <li>Support other users in their learning and professional development.</li>
        <li>Report any content or behavior that violates these Guidelines.</li>
      </ul>
      <h3>2.2 Unacceptable Behaviors</h3>
      <ul>
        <li><strong>Harassment:</strong> Any form of harassment, bullying, or threatening behavior.</li>
        <li><strong>Spamming:</strong> Posting or sending unsolicited or irrelevant content.</li>
        <li><strong>Illegal Activities:</strong> Engaging in activities that are illegal or violate applicable laws and regulations.</li>
        <li><strong>Inappropriate Content:</strong> Posting content that is obscene, offensive, defamatory, or otherwise inappropriate.</li>
        <li><strong>Impersonation:</strong> Misrepresenting yourself or impersonating another person or entity.</li>
        <li><strong>Privacy Violations:</strong> Sharing personal information of others without their consent.</li>
        <li><strong>Intellectual Property Infringement:</strong> Posting content that violates the intellectual property rights of others.</li>
      </ul>
      <h2>3. Community Guidelines</h2>
      <h3>3.1 Posting and Commenting</h3>
      <ul>
        <li>Stay on topic and contribute meaningful content.</li>
        <li>Be respectful of differing opinions and experiences.</li>
        <li>Avoid inflammatory or disruptive comments.</li>
        <li>Use appropriate language and tone.</li>
      </ul>
      <h3>3.2 Respectful Communication</h3>
      <ul>
        <li>Treat others with kindness and respect.</li>
        <li>Avoid personal attacks, name-calling, and hate speech.</li>
        <li>Listen and respond thoughtfully to other community members.</li>
        <li>Recognize and appreciate the diversity of our community.</li>
      </ul>
      <h2>4. Consequences for Violating the Policy</h2>
      <h3>4.1 Enforcement</h3>
      <p>We reserve the right to enforce these Guidelines at our discretion. Consequences for violating the Guidelines may include:</p>
      <ul>
        <li><strong>Warnings:</strong> Issuance of a warning for minor violations.</li>
        <li><strong>Content Removal:</strong> Removal of content that violates these Guidelines.</li>
        <li><strong>Account Suspension:</strong> Temporary suspension of user accounts for serious or repeated violations.</li>
        <li><strong>Account Termination:</strong> Permanent termination of user accounts for severe or repeated violations.</li>
      </ul>
      <h3>4.2 Reporting Violations</h3>
      <p>If you encounter any content or behavior that violates these Guidelines, please report it to our support team at sezer@archistudies.com. We will review all reports and take appropriate action.</p>
      <h2>5. Encouraging Respectful and Constructive Communication</h2>
      <p>We believe in fostering a positive and inclusive community. To support this, we encourage all users to:</p>
      <ul>
        <li>Provide constructive feedback and support to others.</li>
        <li>Share insights and experiences that can benefit the community.</li>
        <li>Engage in active listening and thoughtful discussions.</li>
        <li>Celebrate the achievements and milestones of fellow users.</li>
      </ul>
      <h2>6. Prohibited Activities</h2>
      <p>To ensure compliance with legal requirements and to protect the integrity of our platform, the following activities and products are prohibited on Archi Studies:</p>
      <ul>
        <li>Unlicensed proprietary content</li>
        <li>Food products and ingredients (including consumer packaged goods, livestock, plants, and seeds)</li>
        <li>Airlines and cruise lines</li>
        <li>Bail bonds and bankruptcy lawyers</li>
        <li>Bidding fee or penny auctions</li>
        <li>Age or legally restricted products or services</li>
        <li>Sexually-oriented or pornographic content</li>
        <li>Cell phones/pagers and centralized reservation services</li>
        <li>Collection agencies and check cashing</li>
        <li>Counterfeit goods/replicas</li>
        <li>Currency exchange and money transmission</li>
        <li>Consulting firms and "get rich quick" schemes</li>
        <li>Credit card and identity theft protection</li>
        <li>Flea markets and drug paraphernalia</li>
        <li>Essay mills/paper mills</li>
        <li>Extended warranties and fortune tellers</li>
        <li>Gambling (including internet gambling)</li>
        <li>Illegal products or services</li>
        <li>Marijuana dispensaries and related businesses</li>
        <li>Multi-level marketing or pyramid schemes</li>
        <li>Pharmacies or internet pharmacies</li>
        <li>Pseudo pharmaceuticals</li>
        <li>Quasi-cash or stores of value</li>
        <li>Security brokerage and door-to-door sales</li>
        <li>Offering substantial rebates or special incentives post-purchase</li>
        <li>Negative response marketing techniques</li>
        <li>Deceptive marketing practices</li>
        <li>Shipping/forwarding brokers</li>
        <li>Substances designed to mimic illegal drugs</li>
        <li>Telemarketing and timeshares</li>
        <li>Tobacco products and weapons or ammunition</li>
        <li>Jewelry or beauty products</li>
      </ul>
      <p>This list is maintained separately from our Terms and Conditions and may change without notice. For any queries regarding prohibited activities, please contact us at sezer@archistudies.com.</p>
      <h2>7. Changes to These Guidelines</h2>
      <p>We may update these Guidelines from time to time. Any changes will be effective immediately upon posting on this page. Your continued use of our platform after changes are posted constitutes your acceptance of the new terms.</p>
    `,
  },
  RefundPolicy: {
    title: "Refund Policy",
    content: `
      <h1>Refund Policy</h1>
      <p>Effective Date: 7/27/2024</p>
      <h2>1. Course Refunds</h2>
      <h3>1.1 Eligibility</h3>
      <p>You may request a refund for a course within 30 days of purchase if you are not satisfied. To be eligible for a refund, you must meet the following conditions:</p>
      <ul>
        <li>You have viewed less than 50% of the course content.</li>
        <li>It has been less than 30 days since the purchase date.</li>
      </ul>
      <h3>1.2 Process</h3>
      <p>To request a refund, please contact our support team at <a href="mailto:sezer@archistudies.com">sezer@archistudies.com</a> with your order details and reason for the refund. We will review your request and, if eligible, process your refund within 5-7 business days.</p>
      <h3>1.3 Exceptions</h3>
      <p>No refunds will be granted if:</p>
      <ul>
        <li>You have viewed more than 50% of the course content.</li>
        <li>It has been more than 30 days since the purchase date.</li>
        <li>The course was purchased using a non-refundable promotion or discount.</li>
      </ul>
      <h3>1.4 Method of Refund</h3>
      <p>Refunds will be issued to the original payment method used during purchase.</p>
      <h2>2. Mentorship Refunds</h2>
      <h3>2.1 Eligibility</h3>
      <p>We understand that mentorship sessions may not always meet your expectations. You may request a refund for a mentorship session if:</p>
      <ul>
        <li>The mentor fails to provide the agreed-upon session.</li>
        <li>The mentor does not meet the agreed schedule without prior notice or rescheduling.</li>
      </ul>
      <h3>2.2 Process</h3>
      <p>To request a refund, please contact our support team at <a href="mailto:sezer@archistudies.com">sezer@archistudies.com</a> within 48 hours of the scheduled session. Provide your order details and reason for the refund. We will review your request and, if eligible, process your refund within 5-7 business days.</p>
      <h3>2.3 Exceptions</h3>
      <p>No refunds will be granted if:</p>
      <ul>
        <li>You miss the session without prior notice to the mentor.</li>
        <li>The session was completed as scheduled.</li>
      </ul>
      <h3>2.4 Method of Refund</h3>
      <p>Refunds will be issued to the original payment method used during purchase.</p>
      <h2>3. General Terms</h2>
      <h3>3.1 Chargebacks</h3>
      <p>If you initiate a chargeback with your bank or credit card company, you will not be eligible for a refund through our platform. We reserve the right to dispute any chargeback.</p>
      <h3>3.2 Changes to the Refund Policy</h3>
      <p>We may update this Refund Policy from time to time. Any changes will be effective immediately upon posting on this page. Your continued use of our services after changes are posted constitutes your acceptance of the new terms.</p>
      <h3>3.3 Contact Us</h3>
      <p>If you have any questions or need assistance with a refund request, please contact our support team at <a href="mailto:sezer@archistudies.com">sezer@archistudies.com</a>.</p>
    `,
  },
  DpaClause: {
    title: "Data Processing Agreement (DPA)",
    content: `
      <h1>Data Processing Agreement (DPA)</h1>
      <h2>1. Introduction</h2>
      <p>This Data Processing Agreement ("DPA") forms part of the Terms of Service between [Platform Name] ("Data Controller") and [Third-Party Service Provider] ("Data Processor").</p>
      <h2>2. Subject Matter and Duration</h2>
      <p>The Data Processor will process personal data on behalf of the Data Controller for the duration of the service agreement.</p>
      <h2>3. Nature and Purpose of Processing</h2>
      <p>The Data Processor will process personal data for the purpose of [specific purpose, e.g., hosting services, payment processing].</p>
      <h2>4. Types of Personal Data and Data Subjects</h2>
      <p><strong>Types of personal data:</strong> [e.g., names, email addresses]</p>
      <p><strong>Categories of data subjects:</strong> [e.g., students, educators]</p>
      <h2>5. Obligations of the Data Processor</h2>
      <ul>
        <li>Implement appropriate security measures.</li>
        <li>Assist the Data Controller in complying with data protection obligations.</li>
        <li>Notify the Data Controller promptly in case of a data breach.</li>
      </ul>
      <h2>6. Data Subject Rights</h2>
      <p>The Data Processor shall assist the Data Controller in fulfilling data subject rights, including access, rectification, and deletion requests.</p>
      <h2>7. Sub-processors</h2>
      <p>The Data Processor shall not engage sub-processors without the prior written consent of the Data Controller.</p>
      <h2>8. Data Transfers</h2>
      <p>The Data Processor shall comply with applicable data protection laws when transferring data to third countries.</p>
      <h2>9. Audit and Inspection Rights</h2>
      <p>The Data Controller has the right to audit and inspect the Data Processor’s compliance with this DPA.</p>
    `,
  },
  GdprClause: {
    title: "GDPR Compliance",
    content: `
      <h1>GDPR Compliance</h1>
      <p>Effective Date: 7/27/2024</p>
      <h2>1. Introduction</h2>
      <p>Welcome to Archi Studies ("we", "our", "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, share, and protect your information when you use our website and services.</p>
      <h2>2. Information We Collect</h2>
      <h3>2.1 Information You Give Us</h3>
      <p>When you create an account, buy courses, post comments, join events, apply for jobs, pay for mentorship, chat with other students, or set up your profile page, you provide us with personal information that includes your name, email address, phone number, payment information, and any other information you choose to provide.</p>
      <h3>2.2 Information We Get From Your Use of Our Services</h3>
      <p>We collect information about the services that you use and how you use them. This information includes:</p>
      <ul>
        <li><strong>Device Information:</strong> We collect device-specific information, such as your hardware model, operating system version, unique device identifiers, and mobile network information.</li>
        <li><strong>Log Information:</strong> When you use our services or view content provided by Archi Studies, we automatically collect and store certain information in server logs. This includes details of how you used our service, Internet protocol (IP) address, device event information, and cookies that may uniquely identify your browser or your account.</li>
        <li><strong>Location Information:</strong> When you use Archi Studies services, we may collect and process information about your actual location using various technologies, including IP address, GPS, and other sensors.</li>
        <li><strong>Cookies and Similar Technologies:</strong> We use cookies and similar technologies to collect and store information when you visit our website, interact with our services, and use our apps.</li>
      </ul>
      <h2>3. How We Use Information We Collect</h2>
      <ul>
        <li><strong>Providing Services:</strong> We use your information to process your transactions, manage your account, and provide customer support.</li>
        <li><strong>Improving Services:</strong> We use your information to understand how users interact with our services, to troubleshoot and fix issues, and to improve the overall user experience.</li>
        <li><strong>Communication:</strong> We may use your email address or other contact information to send you information about our services, updates, marketing communications, or promotional offers.</li>
        <li><strong>Personalization:</strong> We use your information to personalize your experience on Archi Studies, such as recommending courses, events, or jobs based on your interests and interactions.</li>
        <li><strong>Legal Compliance:</strong> We may use your information to comply with applicable laws, regulations, and legal processes.</li>
      </ul>
      <h2>4. Information We Share</h2>
      <p>We do not share personal information with companies, organizations, and individuals outside of Archi Studies unless one of the following circumstances applies:</p>
      <ul>
        <li><strong>With Your Consent:</strong> We will share personal information with companies, organizations, or individuals outside of Archi Studies when we have your consent to do so.</li>
        <li><strong>For External Processing:</strong> We provide personal information to our affiliates or other trusted businesses or persons to process it for us, based on our instructions and in compliance with our Privacy Policy.</li>
        <li><strong>For Legal Reasons:</strong> We will share personal information with companies, organizations, or individuals outside of Archi Studies if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to:</li>
        <ul>
          <li>Meet any applicable law, regulation, legal process, or enforceable governmental request.</li>
          <li>Enforce applicable Terms of Service, including investigation of potential violations.</li>
          <li>Detect, prevent, or otherwise address fraud, security, or technical issues.</li>
          <li>Protect against harm to the rights, property, or safety of Archi Studies, our users, or the public as required or permitted by law.</li>
        </ul>
        <li><strong>Business Transfers:</strong> If Archi Studies is involved in a merger, acquisition, or asset sale, your personal information may be transferred. We will provide notice before your personal information is transferred and becomes subject to a different privacy policy.</li>
      </ul>
      <h2>5. Your Controls and Choices</h2>
      <ul>
        <li><strong>Managing Your Information:</strong> You can access, update, and delete your personal information by logging into your account and going to your account settings.</li>
        <li><strong>Communication Preferences:</strong> You can opt out of receiving promotional communications from us by following the instructions in those communications. If you opt out, we may still send you non-promotional communications, such as those about your account or our ongoing business relations.</li>
        <li><strong>Ad Personalization:</strong> You can control the information we use to show you ads by adjusting your ad settings in your account.</li>
      </ul>
      <h2>6. Data Security</h2>
      <p>We work hard to protect Archi Studies and our users from unauthorized access, alteration, disclosure, or destruction of information we hold. We implement a variety of security measures, including encryption and access controls, to safeguard your personal information.</p>
      <h2>7. Data Retention</h2>
      <p>We retain personal information for as long as necessary to provide our services, fulfill the transactions you have requested, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements.</p>
      <h2>8. Children's Privacy</h2>
      <p>Our services are not directed to children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p>
      <h2>9. International Users</h2>
      <p>If you are accessing our services from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located and our central database is operated. By using our services, you consent to the transfer of your information to our facilities as described in this Privacy Policy.</p>
      <h2>10. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
    `,
  },
  EduOrgs: {
    title: "For Educators & Organizations",
    content: `
      <h1>For Educators & Organizations</h1>
      <h2>1. Terms of Service for Educators and Organizations</h2>
      <p>Effective Date: 7/27/2024</p>
      <h3>1. Introduction</h3>
      <p>Welcome to Archi Studies ("we", "our", "us"). By accessing or using our platform, educators and organizations ("you") agree to comply with these Terms of Service. If you do not agree, please do not use our platform.</p>
      <h3>2. User Responsibilities</h3>
      <ul>
        <li><strong>Content Quality:</strong> Ensure that all content uploaded is accurate, appropriate, and relevant to the educational topics.</li>
        <li><strong>Conduct:</strong> Engage respectfully with students, educators, and other organizations. Harassment, spamming, and illegal activities are strictly prohibited.</li>
      </ul>
      <h3>3. Prohibited Activities</h3>
      <ul>
        <li><strong>Harassment:</strong> Any form of harassment, bullying, or threatening behavior.</li>
        <li><strong>Spamming:</strong> Posting or sending unsolicited or irrelevant content.</li>
        <li><strong>Illegal Activities:</strong> Engaging in activities that are illegal or violate applicable laws and regulations.</li>
      </ul>
      <h3>4. Account Management</h3>
      <ul>
        <li><strong>Account Creation:</strong> Provide accurate information when creating an account.</li>
        <li><strong>Account Maintenance:</strong> Keep your account information up-to-date.</li>
        <li><strong>Account Suspension/Termination:</strong> We reserve the right to suspend or terminate accounts that violate these terms.</li>
      </ul>
      <h3>5. Intellectual Property</h3>
      <ul>
        <li><strong>Content Ownership:</strong> You retain ownership of the content you upload.</li>
        <li><strong>License to Use:</strong> By uploading content, you grant Archi Studies a non-exclusive, worldwide, royalty-free license to use, display, distribute, and promote your content on the platform and through affiliated channels.</li>
      </ul>
      <h3>6. Liability and Indemnification</h3>
      <ul>
        <li><strong>Limitation of Liability:</strong> Archi Studies is not liable for any indirect, incidental, special, consequential, or punitive damages.</li>
        <li><strong>Indemnification:</strong> You agree to indemnify and hold Archi Studies harmless from any claims arising out of your use of the platform or your violation of these terms.</li>
      </ul>
      <h3>7. Dispute Resolution</h3>
      <ul>
        <li><strong>Governing Law:</strong> These terms are governed by the laws of Santa Clara, California.</li>
        <li><strong>Arbitration:</strong> Any disputes will be resolved through binding arbitration in Santa Clara, California, USA.</li>
      </ul>
      <h3>8. Changes to Terms</h3>
      <p>We may update these terms from time to time. Continued use of the platform constitutes acceptance of the updated terms.</p>
      <h3>9. Contact Us</h3>
      <p>For any questions, please contact us at <a href="mailto:sezer@archistudies.com">sezer@archistudies.com</a>.</p>
      <h2>2. Service Agreement</h2>
      <h3>1. Scope of Services</h3>
      <p>Archi Studies will provide the following services:</p>
      <ul>
        <li>Hosting educational content.</li>
        <li>Facilitating course and mentorship sessions.</li>
        <li>Providing tools for interaction between educators, organizations, and students.</li>
      </ul>
      <h3>2. Service Levels</h3>
      <ul>
        <li><strong>Uptime Commitment:</strong> Archi Studies will ensure an uptime of [X]%.</li>
        <li><strong>Support:</strong> Archi Studies will provide customer support through <a href="mailto:sezer@archistudies.com">sezer@archistudies.com</a>.</li>
      </ul>
      <h3>3. Termination by Platform</h3>
      <p>Archi Studies may terminate this agreement or suspend your account for any reason, at any time, with or without notice. Reasons for termination may include but are not limited to:</p>
      <ul>
        <li>Breach of these Terms of Service.</li>
        <li>Violation of applicable laws.</li>
        <li>Engagement in prohibited activities.</li>
      </ul>
      <h3>4. Termination by Educators and Organizations</h3>
      <p>Educators and organizations may terminate their use of Archi Studies services at any time by providing Archi Studies with written notice at least 30 days prior to termination. Upon termination, access to your account and any data associated with it will be disabled. However, any courses or mentorship sessions already sold will remain accessible to the students who purchased them.</p>
      <h3>5. Effect of Termination</h3>
      <p>Upon termination, all rights granted to you under these Terms will immediately cease. Archi Studies will handle your data in accordance with our Data Use Agreement. Any outstanding payments due to you will be processed in accordance with the Revenue Sharing and Service Charge Agreement.</p>
      <h3>6. Renewal</h3>
      <p>This agreement will automatically renew on an annual basis unless terminated by either party in accordance with the provisions outlined above.</p>
      <h2>3. Data Use Agreement</h2>
      <h3>1. Data Collection and Usage</h3>
      <ul>
        <li><strong>Data Types:</strong> We collect personal data such as names, email addresses, and payment information.</li>
        <li><strong>Usage:</strong> Data is used for providing services, improving user experience, and marketing purposes.</li>
      </ul>
      <h3>2. Data Sharing</h3>
      <ul>
        <li><strong>Third Parties:</strong> We may share aggregated, anonymized data with third parties for research and marketing purposes.</li>
        <li><strong>Consent:</strong> Personal data will only be shared with third parties with your consent or as required by law.</li>
      </ul>
      <h3>3. Data Protection</h3>
      <ul>
        <li><strong>Security Measures:</strong> We implement industry-standard security measures to protect your data.</li>
        <li><strong>Data Breach:</strong> In case of a data breach, we will notify you and relevant authorities promptly.</li>
      </ul>
      <h3>4. User Rights</h3>
      <ul>
        <li><strong>Access and Correction:</strong> You have the right to access and correct your data.</li>
        <li><strong>Deletion:</strong> You can request the deletion of your data, subject to legal and operational requirements.</li>
      </ul>
      <h2>4. Advertising Agreement</h2>
      <h3>1. Advertising Rights</h3>
      <ul>
        <li><strong>License:</strong> You grant Archi Studies the right to use your content, name, and logo for advertising and promotional purposes.</li>
        <li><strong>Ad Placement:</strong> Archi Studies may place ads on the platform, including your content and profiles.</li>
      </ul>
      <h3>2. Advertising Policies</h3>
      <ul>
        <li><strong>Content Standards:</strong> Ads must comply with our content standards and relevant laws.</li>
        <li><strong>Prohibited Content:</strong> Ads must not contain misleading, deceptive, or offensive content.</li>
      </ul>
      <h3>3. Ad Refunds</h3>
      <ul>
        <li><strong>Eligibility:</strong> Ad refunds are granted if ads are not delivered as specified due to our error.</li>
        <li><strong>Process:</strong> Contact <a href="mailto:sezer@archistudies.com">sezer@archistudies.com</a> within [X] days of the ad run to request a refund.</li>
      </ul>
      <h3>4. Changes to Advertising Terms</h3>
      <p>We may update these terms periodically. Continued use of advertising services constitutes acceptance of the updated terms.</p>
      <h2>5. Revenue Sharing and Service Charge Agreement</h2>
      <h3>1. Revenue Sharing</h3>
      <ul>
        <li><strong>Basic Version:</strong> Educators on the basic version retain 75% of their earnings from courses and mentorship sessions. Organizations are automatically on this plan.</li>
        <li><strong>Verified Educators:</strong> Educators verified by us will retain 90% of their earnings. A monthly fee is required for maintaining the verification status.</li>
        <li><strong>Enterprise Educators:</strong> Earnings percentage and fees are determined by mutual agreement.</li>
      </ul>
      <h3>2. Service Charges</h3>
      <ul>
        <li><strong>Deduction:</strong> Service charges of [X]% will be deducted from the gross revenue generated by each transaction.</li>
        <li><strong>Payouts:</strong> Payments will be made monthly, with detailed statements provided.</li>
      </ul>
      <h3>3. Payment Terms</h3>
      <ul>
        <li><strong>Minimum Payout:</strong> The minimum payout threshold is [X] dollars.</li>
        <li><strong>Timing:</strong> Payments will be processed within [X] days of the end of each month.</li>
      </ul>
      <h3>4. Tax Responsibilities</h3>
      <ul>
        <li><strong>Tax Compliance:</strong> You are responsible for complying with all tax obligations in your jurisdiction.</li>
        <li><strong>Reporting:</strong> Archi Studies will provide necessary documentation for tax reporting purposes.</li>
      </ul>
    `,
  },
};
