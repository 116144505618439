import React, { useState, useEffect } from "react";
import "./TextSpinner.css";

const TextSpinner = () => {
  const content = [
    "Welcome to Archi Studies",
    "Bienvenido a Archi Estudios",      // Spanish
    "Bienvenue à Archi Études",         // French
    "Willkommen bei Archi Studien",     // German
    "欢迎来到 Archi 学习",               // Chinese
    "ようこそ Archi スタディーズ",       // Japanese
    "में आपका स्वागत है Archi अध्ययन",   // Hindi
    "Bem-vindo ao Archi Estudos",       // Portuguese
    "مرحبا بكم في Archi للدراسات"        // Arabic
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [visualDirection, setVisualDirection] = useState(1);

  // Define a fixed height in pixels for each item
  const ITEM_HEIGHT = 60; // Adjust this value based on your needs

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => {
        let nextIndex = prevIndex + visualDirection;
        
        if (nextIndex >= content.length) {
          nextIndex = content.length - 1;
          setVisualDirection(-1);
        } else if (nextIndex < 0) {
          nextIndex = 1;
          setVisualDirection(1);
        } else {
          setVisualDirection(Math.random() > 0.5 ? 1 : -1);
        }
        return nextIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [visualDirection, content.length]);

  const getPositionStyle = () => {
    return { 
      transform: `translateY(-${ITEM_HEIGHT * activeIndex}px)`,
      transition: 'transform 0.5s ease-in-out'
    };
  };

  return (
    <div className="spinner--container">
      <div className="spinner--list">
        <ul style={getPositionStyle()}>
          {content.map((item, index) => (
            <li 
              key={index}
              style={{
                height: `${ITEM_HEIGHT}px`,
                display: 'flex',
                alignItems: 'left',
                justifyContent: 'left'
              }}
            >
              <h1>{item}</h1>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TextSpinner;