import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import EducatorProfilePage from './EducatorProfilePage';
import OrganizationProfilePage from './OrganizationProfilePage';
import StudentProfilePage from './StudentProfilePage';
import fetcher from '../../util/fetcher';

const ProfilePage = () => {
  const [profileInfo, setProfileInfo] = useState();
  const [currentUser, setCurrentUser] = useState();

  const {id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch current user data once on mount
    const fetchUserProfile = async () => {
      try {
        const userData = await fetcher(`${process.env.REACT_APP_BACKEND_URL}/api/users/me`);
        setCurrentUser(userData.payload);
  
        // If no ID is present in the URL params, navigate to the user's own profile
        if (!id) {
          navigate(`/profile/${userData.payload._id}`);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
  
    fetchUserProfile();
  }, [id, navigate]);
  
  // Separate effect to fetch educator profile after currentUser is set
  useEffect(() => {
    const fetchEducatorProfile = async () => {
      if (!currentUser) return;
  
      try {
        // If viewing a different user's profile, fetch their data
        if (id && id !== currentUser._id) {
          const profileData = await fetcher(`${process.env.REACT_APP_BACKEND_URL}/api/users/by_id/${id}`);
          setProfileInfo(profileData.payload);
        } else {
          // If viewing the current user's profile, use their data
          setProfileInfo(currentUser);
        }
      } catch (error) {
        console.error('Error fetching educator profile:', error);
      }
    };

   
    fetchEducatorProfile();
  }, [currentUser, id]);



  return (
    profileInfo && currentUser &&
    <>
      {profileInfo.role === 'educator' && <EducatorProfilePage profileInfo={profileInfo} currentUser={currentUser} />}
      {profileInfo.role === 'organization' && <OrganizationProfilePage profileInfo={profileInfo} currentUser={currentUser} />}
      {profileInfo.role === 'student' && <StudentProfilePage profileInfo={profileInfo} currentUser={currentUser} />}
    </>
  );
};

export default ProfilePage;
