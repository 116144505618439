// ./SettingsPage.jsx
import React, { useState, useEffect } from "react";
import CommunitySidebar from "../HomePage/CommunitySidebar/CommunitySidebar";
import TopNavBar from "../HomePage/TopNavBar/TopNavBar";
import fetcher from "../../util/fetcher";
import { FaUserCog, FaLock, FaShieldAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import StripeOnboarding from "./StripeOnboarding";

const tabEnum = {
  ACCOUNT_PREFERENCES: "accountPreferences",
  SIGN_IN_SECURITY: "signInSecurity",
  DATA_PRIVACY: "dataPrivacy",
};

const SettingsPage = ({ onLogout }) => {
  const [communitySidebarOpen, setCommunitySidebarOpen] = useState(
    window.innerWidth > 768
  );
  const [selectedTab, setSelectedTab] = useState(tabEnum.ACCOUNT_PREFERENCES);
  const [userInfo, setUserInfo] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );

  useEffect(() => {
    document.documentElement.classList.toggle("dark", isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await fetcher(
          `${process.env.REACT_APP_BACKEND_URL}/api/users/me`
        );
        setUserInfo(userData.payload);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const toggleDarkMode = () => {
    const newDarkModeState = !isDarkMode;
    setIsDarkMode(newDarkModeState);
    localStorage.setItem("darkMode", newDarkModeState);
    document.documentElement.classList.toggle("dark", newDarkModeState);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCommunitySidebarOpen(false);
      } else {
        setCommunitySidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleCommunitySidebar = () => {
    setCommunitySidebarOpen(!communitySidebarOpen);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case tabEnum.ACCOUNT_PREFERENCES:
        return (
          <div>
            <h2 className="text-2xl text-light-text dark:text-dark-text mb-6">
              Account Preferences
            </h2>
            <div className="mb-6">
              <label className="block text-light-text dark:text-dark-text mb-2">
                Display Name
              </label>
              <input
                type="text"
                placeholder="Enter your display name"
                className="w-full px-4 py-2 border rounded-lg dark:border-dark-border"
              />
            </div>
            <div className="flex items-center">
              <label className="text-light-text dark:text-dark-text">
                Dark Mode
              </label>
              <label className="switch ml-4">
                <input
                  type="checkbox"
                  checked={isDarkMode}
                  onChange={toggleDarkMode}
                />
                <span className="slider"></span>
              </label>
            </div>
            <StripeOnboarding />
          </div>
        );
      case tabEnum.SIGN_IN_SECURITY:
        return (
          <div>
            <h2 className="text-2xl text-light-text dark:text-dark-text mb-6">
              Sign In & Security
            </h2>
            <div className="mb-6">
              <label className="block text-light-text dark:text-dark-text mb-2">
                Email Address
              </label>
              <span>{userInfo?.email || "Not available"}</span>
            </div>
            <div className="mb-6">
              <label className="block text-light-text dark:text-dark-text mb-2">
                Phone Number
              </label>
              <span>{userInfo?.phone || "Not available"}</span>
            </div>
            <button className="px-6 py-2 bg-light-accent text-white rounded-lg hover:bg-light-accentSecondary dark:bg-dark-accent dark:hover:bg-dark-accentSecondary">
              Change Password
            </button>
          </div>
        );
      case tabEnum.DATA_PRIVACY:
        return (
          <div>
            <h2 className="text-2xl text-light-text dark:text-dark-text mb-6">
              Data Privacy
            </h2>
            <p className="mb-6 text-light-text dark:text-dark-text">
              We value your privacy. Learn more about how we use your data and
              the options you have.
            </p>
            <Link to="/terms-of-service">
              <button className="px-6 py-2 bg-light-accent text-white rounded-lg hover:bg-light-accentSecondary dark:bg-dark-accent dark:hover:bg-dark-accentSecondary">
                Learn More
              </button>
            </Link>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col min-h-screen font-sans">
      <TopNavBar toggleSidebar={toggleCommunitySidebar} onLogout={onLogout} />
      <CommunitySidebar
        isOpen={communitySidebarOpen}
        toggleSidebar={toggleCommunitySidebar}
      />
      <div
        className={`flex flex-col lg:flex-row ${
          communitySidebarOpen ? "ml-64" : ""
        } transition-all duration-300 mt-16`}
      >
        <aside className="w-64 bg-light-secondary text-light-text dark:bg-dark-secondary dark:text-dark-text p-6">
          <button
            className={`w-full flex items-center px-4 py-3 rounded-lg mb-3 transition-all duration-300 ${
              selectedTab === tabEnum.ACCOUNT_PREFERENCES
                ? "bg-light-accent text-white dark:bg-dark-accent"
                : "hover:bg-light-border dark:hover:bg-dark-border"
            }`}
            onClick={() => setSelectedTab(tabEnum.ACCOUNT_PREFERENCES)}
          >
            <FaUserCog className="mr-2" />
            Account Preferences
          </button>
          <button
            className={`w-full flex items-center px-4 py-3 rounded-lg mb-3 transition-all duration-300 ${
              selectedTab === tabEnum.SIGN_IN_SECURITY
                ? "bg-light-accent text-white dark:bg-dark-accent"
                : "hover:bg-light-border dark:hover:bg-dark-border"
            }`}
            onClick={() => setSelectedTab(tabEnum.SIGN_IN_SECURITY)}
          >
            <FaLock className="mr-2" />
            Sign In & Security
          </button>
          <button
            className={`w-full flex items-center px-4 py-3 rounded-lg transition-all duration-300 ${
              selectedTab === tabEnum.DATA_PRIVACY
                ? "bg-light-accent text-white dark:bg-dark-accent"
                : "hover:bg-light-border dark:hover:bg-dark-border"
            }`}
            onClick={() => setSelectedTab(tabEnum.DATA_PRIVACY)}
          >
            <FaShieldAlt className="mr-2" />
            Data Privacy
          </button>
        </aside>
        <main className="flex-1 p-8 bg-light-primary dark:bg-dark-primary overflow-auto">
          {renderContent()}
        </main>
      </div>
    </div>
  );
};

export default SettingsPage;
