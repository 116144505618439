// CourseCreationWizard.jsx
import React, { useState } from "react";
import TopNavBar from "../../HomePage/TopNavBar/TopNavBar";
import CommunitySidebar from "../../HomePage/CommunitySidebar/CommunitySidebar";
import { FaUpload } from "react-icons/fa";
import taxios from "../../../util/token_refresh_hook";
import { getUInfo } from "../../../util/whoami";
import BasicInfoStep from "./BasicInfoStep";
import IntroVideoStep from "./IntroVideoStep";
import ContentStructureStep from "./ContentStructureStep";
import PricingReviewStep from "./PricingReviewStep";
import "./CourseCreationPage.css";

function CourseCreationWizard({ toggleSidebar, sidebarOpen, onLogout }) {
  const [sections, setSections] = useState([]);
  const [expandedSection, setExpandedSection] = useState(0);
  const [editingSectionId, setEditingSectionId] = useState(null);
  const [editingSubsectionId, setEditingSubsectionId] = useState(null);

  const [courseThumbnailFile, setCourseThumbnailFile] = useState(null);
  const [courseThumbnailURL, setCourseThumbnailURL] = useState(null);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseCategory, setCourseCategory] = useState("");
  const [coursePrice, setCoursePrice] = useState("0");

  const [introVideo, setIntroVideo] = useState(null);
  const [introVideoURL, setIntroVideoURL] = useState(null);
  const [introDuration, setIntroDuration] = useState(0);
  const [introVideoTitle, setIntroVideoTitle] = useState("Course Introduction");

  const [isUploading, setIsUploading] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    { number: 1, label: "Basic Info" },
    { number: 2, label: "Intro Video" },
    { number: 3, label: "Content" },
    { number: 4, label: "Pricing & Review" },
  ];

  const goToNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const goToPreviousStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  // Handlers extracted from original code
  const handleCourseThumbnailChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const timestamp = Date.now().toString();
      const filename = `${timestamp}-${file.name}`;

      const { data } = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/videos/generate-thumbnail-url`,
        {
          params: {
            filename: filename,
            contentType: "image/jpeg",
          },
        }
      );

      const uploadResponse = await fetch(data.signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "image/jpeg",
          "x-amz-acl": "private",
        },
        body: file,
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed with status ${uploadResponse.status}`);
      }

      setCourseThumbnailFile(file);
      setCourseThumbnailURL(
        `https://archi-video-bucket.s3.us-west-1.amazonaws.com/thumbnails/${filename}`
      );
    } catch (error) {
      console.error("Error uploading course thumbnail:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
      }
      alert("Failed to upload course thumbnail. Please try again.");
    }
  };

  const handleIntroVideoChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const timestamp = Date.now().toString();
      const filename = `${timestamp}-${file.name}`;

      const {
        data: { signedUrl, videoKey },
      } = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/videos/generate-signed-url`,
        {
          params: {
            filename: filename,
            contentType: "video/mp4",
          },
        }
      );

      const uploadResponse = await fetch(signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "video/mp4",
          "x-amz-acl": "private",
        },
        body: file,
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed with status ${uploadResponse.status}`);
      }

      const duration = await getVideoDuration(file);

      setIntroVideo(file);
      setIntroVideoURL(videoKey);
      setIntroDuration(duration);
    } catch (error) {
      console.error("Error uploading intro video:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
      }
      alert("Failed to upload intro video. Please try again.");
    }
  };

  const handleAddSection = () => {
    const newSection = {
      id: Date.now(),
      title: "Name it here",
      subsections: [],
    };
    setSections([...sections, newSection]);
  };

  const handleUpdateSectionTitle = (
    sectionId,
    newTitle,
    shouldBlur = false
  ) => {
    setSections(
      sections.map((section) =>
        section.id === sectionId ? { ...section, title: newTitle } : section
      )
    );
    if (shouldBlur) {
      setEditingSectionId(null);
    }
  };

  const handleUpdateSubsectionTitle = (
    sectionIndex,
    subsectionIndex,
    newTitle,
    shouldBlur = false
  ) => {
    const updatedSections = sections.map((section, idx) => {
      if (idx === sectionIndex) {
        const updatedSubsections = section.subsections.map(
          (subsection, subIdx) =>
            subIdx === subsectionIndex
              ? { ...subsection, title: newTitle }
              : subsection
        );
        return { ...section, subsections: updatedSubsections };
      }
      return section;
    });
    setSections(updatedSections);
    if (shouldBlur) {
      setEditingSubsectionId(null);
    }
  };

  const handleAddSubsection = (sectionIndex) => {
    const updatedSections = sections.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          subsections: [
            ...section.subsections,
            {
              id: Date.now(),
              title: "Subsection",
              duration: 0,
              videoFile: null,
              videoURL: null,
            },
          ],
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const handleVideoChange = async (e, sectionIndex, subsectionIndex) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const timestamp = Date.now().toString();
      const filename = `${timestamp}-${file.name}`;

      const {
        data: { signedUrl, videoKey },
      } = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/videos/generate-signed-url`,
        {
          params: {
            filename: filename,
            contentType: "video/mp4",
          },
        }
      );

      const uploadResponse = await fetch(signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "video/mp4",
          "x-amz-acl": "private",
        },
        body: file,
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed with status ${uploadResponse.status}`);
      }

      const duration = await getVideoDuration(file);

      const updatedSections = [...sections];
      updatedSections[sectionIndex].subsections[subsectionIndex] = {
        ...updatedSections[sectionIndex].subsections[subsectionIndex],
        videoFile: file,
        videoURL: videoKey,
        duration: duration,
      };

      setSections(updatedSections);
    } catch (error) {
      console.error("Error handling video upload:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
      }
      alert("Failed to upload video. Please try again.");
    }
  };

  const getVideoDuration = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        // Convert seconds to minutes and round to 1 decimal place
        const durationInMinutes = Math.round((video.duration / 60) * 10) / 10;
        resolve(durationInMinutes);
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const handleSaveCourse = async () => {
    setIsUploading(true);
    const { id: creatorID } = getUInfo();

    if (!courseThumbnailURL) {
      alert("Please upload a course thumbnail before saving.");
      setIsUploading(false);
      return;
    }

    try {
      // Clean up sections data
      const cleanedSections = sections.map((section) => ({
        title: section.title,
        subsections: section.subsections.map((subsection) => ({
          title: subsection.title,
          videoURL: subsection.videoURL || "",
          duration: subsection.duration || 0,
        })),
      }));

      // Calculate total duration
      const totalDuration = sections.reduce((total, section) => {
        return (
          total +
          section.subsections.reduce((subTotal, subsection) => {
            return subTotal + (subsection.duration || 0);
          }, 0)
        );
      }, introDuration || 0);

      const courseData = {
        courseTitle,
        courseDescription,
        courseCategory: courseCategory || "Uncategorized",
        coursePrice: coursePrice || "0",
        totalDuration: totalDuration.toString(),
        creatorID,
        courseThumbnailURL,
        introVideoURL,
        introVideoTitle,
        introDuration: introDuration.toString(),
        sections: JSON.stringify(cleanedSections),
      };

      const response = await taxios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/courses`,
        courseData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        alert("Course created successfully!");
        resetForm();
      } else {
        throw new Error(response.data.error || "Failed to create course");
      }
    } catch (error) {
      console.error("Error creating course:", error);
      if (error.response?.data?.error) {
        console.error("Server error:", error.response.data.error);
      }
      alert("Failed to create course. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const resetForm = () => {
    setCourseTitle("");
    setCourseDescription("");
    setCourseCategory("");
    setCoursePrice("0");
    setSections([]);
    setCourseThumbnailFile(null);
    setCourseThumbnailURL(null);
    setIntroVideo(null);
    setIntroVideoURL(null);
    setIntroDuration(0);
    setIntroVideoTitle("Course Introduction");
    setCurrentStep(1);
  };

  // Step indicator component
  const StepIndicator = () => (
    <div className="relative flex items-center justify-between mb-4">
      {steps.map((step, index) => {
        const isActive = currentStep === step.number;
        const isCompleted = currentStep > step.number;

        return (
          <React.Fragment key={step.number}>
            {/* Step circle */}
            <div className="flex-1 text-center z-10 relative">
              <div
                className={`w-8 h-8 mx-auto rounded-full flex items-center justify-center font-semibold 
              ${
                isCompleted
                  ? "bg-green-600"
                  : isActive
                  ? "bg-[#002855]"
                  : "bg-gray-400"
              } text-white`}
              >
                {step.number}
              </div>
              <div
                className={`mt-1 text-xs ${
                  isActive || isCompleted
                    ? "text-[#002855] font-medium"
                    : "text-gray-600"
                }`}
              >
                {step.label}
              </div>
            </div>

            {/* Decorative line */}
            {index < steps.length - 1 && (
              <div
                className={`absolute h-[2px] top-1/2 transform -translate-x-5 -translate-y-3.5 ${
                  isCompleted ? "bg-green-600" : "bg-gray-300"
                }`}
                style={{
                  left: `${(index + 1) * (100 / steps.length) - 5}%`,
                  width: "20%",
                }}
              ></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );

  return (
    <div className="course-creation-page">
      <TopNavBar toggleSidebar={toggleSidebar} onLogout={onLogout} />
      <CommunitySidebar isOpen={sidebarOpen} />

      {/* Wizard content container */}
      <div
        className={`wizard-content ${
          sidebarOpen ? "ml-[250px]" : "ml-0"
        } mt-[64px] p-6 pl-48 xl:pl-0 bg-[#f5f5f5] min-h-[calc(100vh-64px)] flex justify-center items-start overflow-hidden`}
      >
        <div className="wizard-container bg-white rounded-lg shadow-lg p-10 max-w-[800px] w-full mx-auto">
          <StepIndicator />

          <div className="wizard-step-content fade-in">
            {currentStep === 1 && (
              <BasicInfoStep
                courseTitle={courseTitle}
                courseDescription={courseDescription}
                courseCategory={courseCategory}
                courseThumbnailURL={courseThumbnailURL}
                onTitleChange={setCourseTitle}
                onDescriptionChange={setCourseDescription}
                onCategoryChange={setCourseCategory}
                onThumbnailChange={handleCourseThumbnailChange}
                onNext={goToNextStep}
              />
            )}
            {currentStep === 2 && (
              <IntroVideoStep
                introVideo={introVideo}
                introVideoTitle={introVideoTitle}
                introDuration={introDuration}
                onIntroTitleChange={setIntroVideoTitle}
                onIntroVideoChange={handleIntroVideoChange}
                onPrevious={goToPreviousStep}
                onNext={goToNextStep}
              />
            )}
            {currentStep === 3 && (
              <ContentStructureStep
                sections={sections}
                expandedSection={expandedSection}
                editingSectionId={editingSectionId}
                editingSubsectionId={editingSubsectionId}
                onAddSection={handleAddSection}
                onAddSubsection={handleAddSubsection}
                onUpdateSectionTitle={handleUpdateSectionTitle}
                onUpdateSubsectionTitle={handleUpdateSubsectionTitle}
                onVideoChange={handleVideoChange}
                onExpandSection={setExpandedSection}
                onEditingSectionIdChange={setEditingSectionId}
                onEditingSubsectionIdChange={setEditingSubsectionId}
                onPrevious={goToPreviousStep}
                onNext={goToNextStep}
              />
            )}
            {currentStep === 4 && (
              <PricingReviewStep
                coursePrice={coursePrice}
                onPriceChange={setCoursePrice}
                isUploading={isUploading}
                onPrevious={goToPreviousStep}
                onSave={handleSaveCourse}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseCreationWizard;
