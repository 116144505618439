// This is the JavaScript entry point for our React application. 
// It renders our main App component inside the div with the id of root in our index.html file.
// Essentially this is where the Main App runs from
import './App.css'
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

// Parent app component this Wraps entire application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);
