import React, { useState } from "react";
import { getUInfo } from "../../../util/whoami";
import ProfilePicture from "../../ProfilePicture/ProfilePicture";
import QuestionActions from "../QuestionActions/QuestionActions";
import MediaDisplay from "../../MediaDisplay/MediaDisplay";
import { Link } from "react-router-dom";

export default function Post({ post, author, onHitCommentBtn, isQuestion }) {
  const [showOptions, setShowOptions] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  console.log('Post data:', {
    media: post.media,
    type: post.media?.type,
    key: post.media?.mediaKey
  });
  const toggleOptions = (index) => {
    setShowOptions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  

  const TEXT_LIMIT = 80;
  const shouldTruncate = post.body.length > TEXT_LIMIT;
  const displayText = isExpanded ? post.body : post.body.slice(0, TEXT_LIMIT);

  // Helper function to validate and normalize media data
  const getMediaInfo = () => {
    if (!post.media || !post.media.mediaKey) return null;

    // Normalize media type
    const normalizedType = post.media.type?.toLowerCase();
    if (!['image', 'video'].includes(normalizedType)) return null;

    return {
      type: normalizedType,
      mediaKey: post.media.mediaKey,
      // Extract file extension from mediaKey if present
      isImage: normalizedType === 'image' || post.media.mediaKey.match(/\.(jpg|jpeg|png|gif)$/i)
    };
  };

  const mediaInfo = getMediaInfo();

  return (
    <div className={`post-container ${isQuestion ? "question" : "comment"}`}>
      <div className="post-header">
        <div className="profile-wrapper" onClick={() => toggleOptions("post")}>
          <ProfilePicture src={author?.avatar} alt={author?.username} />
          {showOptions["post"] && (
            <div className="options-menu">
              <Link to={`/profile/${author?._id}`}>
                <div>View Profile</div>
              </Link>
              <div>Message</div>
              <div>Block</div>
            </div>
          )}
        </div>
        <div className="post-content">
          <div className="post-author">
            <strong className={author._id === getUInfo().id ? "self" : ""}>
              {author ? author.username : "UNKNOWN"}
            </strong>
            <span className="post-time">{formatTime(post.createdAt)}</span>
          </div>
          <div className="post-body">
            {displayText}
            {shouldTruncate && !isExpanded && (
              <button onClick={() => setIsExpanded(true)} className="more-button">
                ...more
              </button>
            )}
          </div>
          {mediaInfo && (
            <div className="media-wrapper">
              <MediaDisplay
                mediaType={mediaInfo.type}
                mediaKey={mediaInfo.mediaKey}
                isHomepage={true} // This is crucial - tells MediaDisplay to use homepage endpoints
              />
            </div>
          )}
          {isQuestion && post.tags && (
            <div className="tags-container">
              {post.tags.map((tag, index) => (
                <span key={index} className="tag">{tag}</span>
              ))}
            </div>
          )}
          <QuestionActions
            key={post._id}
            question={post}
            id={post._id}
            author={author}
            irating={post.ratingInfo}
            isComment={!isQuestion}
            onHitCommentBtn={onHitCommentBtn}
          />
        </div>
      </div>
    </div>
  );
}

function formatTime(timeString) {
  const now = new Date();
  const postTime = new Date(timeString);
  const diffInSeconds = Math.floor((now - postTime) / 1000);
  if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) return `${diffInHours} hours ago`;
  const diffInDays = Math.floor(diffInHours / 24);
  return `${diffInDays} days ago`;
}
