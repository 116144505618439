import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaEllipsisH } from "react-icons/fa";

import usePaginationStore from "./paginationStore";
import useQuestionStore from "../QuestionList/questionStore";

import "./PMenu.css"

//TODO: don't render the skipper buttons if there are <= `ctxAround` pages to render

/** Controls the amount of quick context buttons to surround the current page button. */
const ctxAround = 3;
const maxCtxInView = ctxAround * 2;

/** Declares a self-managed question pagination component. */
export default function PaginationMenu(){
	//Setup component states
	const {
		currentPage, maxPages, setCurrentPage, totalPosts,
		isPopulated: isPPopulated
	} = usePaginationStore();
	const { setIsPopulated: setIsQPopulated } = useQuestionStore();

	/** Gets an ellipses marker that has a "unique" key val. */
	const getEllipses = (idx) => {
		return (
			<li key={`ellipsis-${idx}`}>
				<span className="pg btn_gap"><FaEllipsisH /></span>
			</li>
		);
	}

	/** Creates an array for pagination buttons. **/
	const renderPageNumbers = () => {
		//Constants
		const minPages = 1;

		//Computed constants
		const minCtx = currentPage - ctxAround;
		const maxCtx = currentPage + ctxAround;
		
		//Calculate the min and max indices for the buttons
		let minIdx = minCtx > minPages ? minCtx : minPages;
		let maxIdx = maxCtx < maxPages ? maxCtx : maxPages;

		//Setup the array that will contain the page numbers to render
		const pageNumbers = [];

		//Check if there are more than x pages to render
		if(maxPages > maxCtxInView){
			//Adjust indices if the total number of pages in view is less than maxCtxInView
			const totalPagesInView = maxIdx - minIdx; // +1 to include the current page
			if(totalPagesInView < maxCtxInView){
				const deltaNeeded = maxCtxInView - totalPagesInView;
				//Adjust the maxIdx first
				if(maxIdx < maxPages){
					maxIdx = Math.min(maxPages, maxIdx + deltaNeeded);
				}
				//If still not enough pages, adjust the minIdx
				if(maxIdx - minIdx + 1 < maxCtxInView){
					minIdx = Math.max(minPages, minIdx - (maxCtxInView - (maxIdx - minIdx + 1)));
				}
			}

			//Add ellipses before the page numbers if the min index isn't the min page number
			if(minIdx > minPages) pageNumbers.push(getEllipses(minPages));
		}
		else {
			//Simply set min to be 1 and max to be the max pages
			minIdx = minPages;
			maxIdx = maxPages;
		}

		//Add the page numbers in between
		for(let i = minIdx; i <= maxIdx; i++){
			pageNumbers.push(
				<li key={i}>
					<span
						key={`pg_selector_${i}`}
						className={`pg pg_button ${currentPage === i ? "selected" : ""}`}
						onClick={() => handleJumpToPage(i)}
					>
						<span className="pg-number">{i}</span>
					</span>
				</li>
			);
		}

		//Add ellipses after the page numbers if the max index isn't the max page number
		if(maxPages > maxCtxInView){
			if(maxIdx < maxPages) pageNumbers.push(getEllipses(maxPages));
		}

		//Return the filled page numbers array
		return pageNumbers;
	};

	/** Handles clicks of the "first page" button. */
	const handleFirstPage = () => {
		if(currentPage > 1){
			setCurrentPage(1);
			afterSwitch();
		}
	};
	/** Handles clicks of the "previous page" button. */
	const handlePreviousPage = () => {
		if(currentPage > 1){
			setCurrentPage(currentPage - 1);
			afterSwitch();
		}
	};
	/** Handles clicks of the "next page" button. */
	const handleNextPage = () => {
		if(currentPage < maxPages){
			setCurrentPage(currentPage + 1);
			afterSwitch();
		}
	};
	/** Handles clicks of the "last page" button. */
	const handleLastPage = () => {
		if(currentPage < maxPages){
			setCurrentPage(maxPages);
			afterSwitch();
		}
	};
	/** Handles clicks of any of the numbered pagination buttons. */
	const handleJumpToPage = (idx) => {
		setCurrentPage(idx);
		afterSwitch();
	}

	/** Defines the logic to run after successfully switching the page. */
	const afterSwitch = () => {
		//Mark the question list as stale
		setIsQPopulated(false);
	}

	//Render the component only if its populated
	// Render the component only if it's populated
return (
  isPPopulated &&
  maxPages > 1 && (
    <nav className="pagination_container d-flex flex-wrap justify-content-between align-items-center">
      <div className="big_skip left">
        <span
          className={`pg_button ${currentPage === 1 ? "disabled" : ""}`}
          onClick={handleFirstPage}
        >
          <FaAngleDoubleLeft /> <span className="pg-text">First Page</span>
        </span>
      </div>
      <div className="small_skip left">
        <span
          className={`pg_button ${currentPage === 1 ? "disabled" : ""}`}
          onClick={handlePreviousPage}
        >
          <FaAngleLeft /> <span className="pg-text">Previous</span>
        </span>
      </div>
      <ul className="pagination mb-0 col-4 d-flex justify-content-center">
        {renderPageNumbers()}
      </ul>
      <div className="small_skip right">
        <span
          className={`pg_button ${currentPage === maxPages ? "disabled" : ""}`}
          onClick={handleNextPage}
        >
          <span className="pg-text">Next</span> <FaAngleRight />
        </span>
      </div>
      <div className="big_skip right">
        <span
          className={`pg_button ${currentPage === maxPages ? "disabled" : ""}`}
          onClick={handleLastPage}
        >
          <span className="pg-text">Last Page</span> <FaAngleDoubleRight />
        </span>
      </div>
      {/* == DEBUG START == */}
      {/* Uncomment the debug section if needed */}
      {/* <div className="w-100"></div>
      <div className="debug">
        <span>current page: {currentPage}</span>
        <span>max pages: {maxPages}</span>
      </div> */}
      {/* == DEBUG END == */}
    </nav>
  )
);

}