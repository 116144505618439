import React, { useState, useEffect, useRef } from 'react';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import { useNavigate } from 'react-router-dom';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import taxios from '../../util/token_refresh_hook';
import './InternshipsPage.css';

import ApplicationModal from './ApplicationModal';

const InternshipsPage = ({ toggleSidebar, sidebarOpen, onLogout }) => {
  const [selectedInternship, setSelectedInternship] = useState(null);
  const [filter, setFilter] = useState({ location: '', industry: '', size: '', sortBy: 'newest' });
  const [internships, setInternships] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [industries, setIndustries] = useState([]);
  const navigate = useNavigate();
  const modalRef = useRef(null); // Ref for the modal
  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [selectedInternshipForApplication, setSelectedInternshipForApplication] = useState(null);
  const [appliedInternships, setAppliedInternships] = useState(new Set());

  useEffect(() => {
    fetchInternships();
    fetchUserRole(); // Fetch user role when component mounts
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowRoleModal(false);
      }
    };

    if (showRoleModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showRoleModal]);


  const handleApplyClick = (internship) => {
    setSelectedInternshipForApplication(internship);
    setShowApplicationModal(true);
  };

  const handleApplicationSuccess = (internshipId) => {
    setAppliedInternships(prev => new Set([...prev, internshipId]));
    setShowApplicationModal(false);
  };

  const fetchInternships = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/internships/all`);
      const internshipsData = Array.isArray(response.data.payload) ? response.data.payload : [];
      setInternships(internshipsData);

      // Extract unique locations and industries
      const uniqueLocations = [...new Set(internshipsData.map(internship => internship.location))];
      const uniqueIndustries = [...new Set(internshipsData.map(internship => internship.industry))];
      
      setLocations(uniqueLocations);
      setIndustries(uniqueIndustries);

    } catch (error) {
      console.error("Error fetching internships:", error);
      setInternships([]); // Clear internships on error
    }
  };

  const fetchUserRole = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/role`);
      setUserRole(response.data.role);
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  const handleOrganizationClick = (internship) => {
    setSelectedInternship(internship);
  };

  const handleFilterChange = (filterType, value) => {
    setFilter({ ...filter, [filterType]: value });
  };

  const handleCreateInternship = () => {
    if (userRole === 'educator' || userRole === 'organization') {
      navigate('/internship-creation');
    } else {
      setShowRoleModal(true);
    }
  };

  const redirectToSignup = (role) => {
    navigate('/signup', { state: { role } });
  };

  const filteredInternships = internships.filter((internship) => {
    const locationMatch = filter.location ? internship.location.includes(filter.location) : true;
    const industryMatch = filter.industry ? internship.industry.includes(filter.industry) : true;
    const sizeMatch = filter.size ? parseInt(internship.salary.replace(/[$,]/g, '')) >= parseInt(filter.size) : true;
    return locationMatch && industryMatch && sizeMatch;
  }).sort((a, b) => {
    if (filter.sortBy === 'newest') return new Date(b.createdAt) - new Date(a.createdAt);
    if (filter.sortBy === 'oldest') return new Date(a.createdAt) - new Date(b.createdAt);
    if (filter.sortBy === 'mostApplied') return b.applicants.length - a.applicants.length;
    if (filter.sortBy === 'leastApplied') return a.applicants.length - b.applicants.length;
    return 0;
  });

  const CustomDropdown = ({ label, options, onSelect, value }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);

    return (
      <div className="custom-dropdown">
        <button className="custom-dropdown-button" onClick={toggleDropdown}>
          {value || label}
        </button>
        {isOpen && (
          <ul className="custom-dropdown-options">
            <li onClick={() => { onSelect(''); toggleDropdown(); }}>{label}</li>
            {options.map((option, index) => (
              <li key={index} onClick={() => { onSelect(option); toggleDropdown(); }}>
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  return (
    <div className="internships-page">
      <TopNavBar 
        toggleSidebar={toggleSidebar} 
        onLogout={onLogout} 
        onCreateInternship={handleCreateInternship} 
      />
      <CommunitySidebar isOpen={sidebarOpen} />
      <div className={`internships-content ${sidebarOpen ? 'sidebar-open' : ''}`}>
        <div className="internships-filters">
          <CustomDropdown 
            label="Sort By" 
            options={['Newest', 'Oldest', 'Most Applied', 'Least Applied']} 
            onSelect={(value) => handleFilterChange('sortBy', value.toLowerCase().replace(' ', ''))} 
            value={filter.sortBy.charAt(0).toUpperCase() + filter.sortBy.slice(1)}
          />
          <CustomDropdown 
            label="Location" 
            options={locations} 
            onSelect={(value) => handleFilterChange('location', value)} 
            value={filter.location}
          />
          <CustomDropdown 
            label="Industry" 
            options={industries} 
            onSelect={(value) => handleFilterChange('industry', value)} 
            value={filter.industry}
          />
          <CustomDropdown 
            label="Company Size" 
            options={["50,000+", "100,000+"]} 
            onSelect={(value) => handleFilterChange('size', value)} 
            value={filter.size}
          />
        </div>
        <div className="internships-main-content">
          <div className="internships-left-container">
            {filteredInternships.map((internship, index) => (
              <div key={index} className="internships-organization-card" onClick={() => handleOrganizationClick(internship)}>
                <h2>{internship.companyName}</h2>
                <p><strong>Industry:</strong> {internship.industry}</p>
                <p><strong>Location:</strong> {internship.location}</p>
                <p><strong>Salary:</strong> {internship.salary ? `$${internship.salary}` : 'Not specified'}</p>
              </div>
            ))}
          </div>
          <div className="internships-middle-container">
            {selectedInternship ? (
              selectedInternship.internships.map((internship, index) => (
                <div key={index} className="internships-internship-details">
                  <div className="internships-internship-header">
                    <h2>{internship.name}</h2>
                    <button 
                      onClick={() => handleApplyClick(internship)}
                      className={`apply-button ${appliedInternships.has(internship._id) ? 'applied' : ''}`}
                      disabled={appliedInternships.has(internship._id)}
                    >
                      {appliedInternships.has(internship._id) ? 'Applied' : 'Apply'}
                    </button>
                  </div>
                  <p><strong>Company:</strong> {selectedInternship.companyName}</p>
                  <p><strong>Location:</strong> {internship.location}</p>
                  <p><strong>Posted:</strong> {internship.postedDaysAgo} days ago</p>
                  
                  <h3>Prerequisites</h3>
                  <ul>
                    {internship.prerequisites.map((prerequisite, index) => (
                      <li key={index}>{prerequisite}</li>
                    ))}
                  </ul>

                  <h3>About the Job</h3>
                  <p>{internship.about}</p>
                </div>
              ))
            ) : (
              <p>Select an organization to see details</p>
            )}
          </div>
        </div>
      </div>

      {showRoleModal && (
        <div className="internships-role-modal">
          <div className="internships-role-modal-content" ref={modalRef}>
            <button className="internships-close-button" onClick={() => setShowRoleModal(false)}>×</button>
            <h2>Create</h2>
            <div className="internships-role-options">
              <div className="internships-role-option">
                <h3>Educator</h3>
                <button onClick={() => redirectToSignup('educator')} className="internships-create-account-button">Create an account</button>
              </div>
              <div className="internships-role-option">
                <h3>Organization</h3>
                <button onClick={() => redirectToSignup('organization')} className="internships-create-account-button">Create an account</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Application Modal moved to root level */}
      <ApplicationModal 
        isOpen={showApplicationModal}
        onClose={() => setShowApplicationModal(false)}
        jobTitle={selectedInternshipForApplication?.name}
        internshipId={selectedInternshipForApplication?._id}
        onSuccess={(internshipId) => handleApplicationSuccess(internshipId)}
      />
    </div>
  );
};

export default InternshipsPage