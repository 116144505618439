import React, { useState, useEffect } from "react";
import "./TermsOfService.css";
import TopNavBar from "../HomePage/TopNavBar/TopNavBar.jsx";
import CommunitySidebar from "../HomePage/CommunitySidebar/CommunitySidebar.jsx";
import { termsContent } from "./termsContent.js"; // Import the content
import { useNavigate } from "react-router-dom";

const sectionEnum = {
  TERMS_OF_SERVICE: "TermsOfService",
  PRIVACY_POLICY: "PrivacyPolicy",
  ACCEPTABLE_USE: "AcceptableUse",
  REFUND_POLICY: "RefundPolicy",
  DPA_CLAUSE: "DpaClause",
  GDPR_CLAUSE: "GdprClause",
  EDU_ORGS: "EduOrgs",
};

const TermsOfService = ({ onLogout }) => {
  const navigate = useNavigate();
  const [communitySidebarOpen, setCommunitySidebarOpen] = useState(
    window.innerWidth > 768
  );
  const [selectedSection, setSelectedSection] = useState(
    sectionEnum.TERMS_OF_SERVICE
  );

  // Update CommunitySidebar state based on window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCommunitySidebarOpen(false);
      } else {
        setCommunitySidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleCommunitySidebar = () => {
    setCommunitySidebarOpen(!communitySidebarOpen);
  };

  const renderContent = () => {
    const contentData = termsContent[selectedSection];
    if (contentData) {
      return (
        <div>
          <h2>{contentData.title}</h2>
          <div
            className="terms-text"
            dangerouslySetInnerHTML={{ __html: contentData.content }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="terms-page">
      <TopNavBar toggleSidebar={toggleCommunitySidebar} onLogout={onLogout} />
      <div className="content-wrapper">
        <aside className={`terms-sidebar ${communitySidebarOpen ? "" : "hidden"}`}>
          <h1>Terms of Service</h1>
          <ul>
            {Object.entries(sectionEnum).map(([key, value]) => (
              <li
                key={value}
                className={selectedSection === value ? "active" : ""}
                onClick={() => setSelectedSection(value)}
              >
                {termsContent[value]?.title || value}
              </li>
            ))}
          </ul>
        </aside>
        <div className="terms-content-container">
          <button className="back-button" onClick={() => navigate(-1)}>
            ← Back
          </button>
          <main className="terms-content">{renderContent()}</main>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
