import React, { useState, useRef, useEffect } from 'react';

// Predefined list of majors
const predefinedMajors = [
  "Computer Science",
  "Electrical Engineering",
  "Mechanical Engineering",
  "Biology",
  "Business Administration",
  "Psychology",
  "Economics",
  "Finance",
  "Civil Engineering",
  "Marketing",
  "Nursing",
  "Law",
  "Education",
  "Physics",
  "Mathematics",
  "Chemistry",
];

// Predefined list of common job titles
const predefinedJobTitles = [
  "Software Engineer",
  "Data Scientist",
  "Mechanical Engineer",
  "Marketing Manager",
  "Business Analyst",
  "Project Manager",
  "Doctor",
  "Teacher",
  "Nurse",
  "Accountant",
  "Lawyer",
  "Web Developer",
  "Graphic Designer",
  "Human Resources Manager",
];

const KnowledgeGraphAutocomplete = ({ placeholder, className, onSelection, type }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const autocompleteRef = useRef(null); // Create a ref for the autocomplete component

  const handleInputChange = async (e) => {
    const input = e.target.value;
    setInputValue(input);
	if (onSelection) onSelection(input);

    if (input.length > 2) {
      let predefinedSuggestions = [];
      let googleSuggestions = [];

      // Check if the type is 'major' or 'job' and filter from the predefined list
      if (type === 'major') {
        predefinedSuggestions = predefinedMajors.filter((major) =>
          major.toLowerCase().includes(input.toLowerCase())
        );
      } else if (type === 'job') {
        predefinedSuggestions = predefinedJobTitles.filter((jobTitle) =>
          jobTitle.toLowerCase().includes(input.toLowerCase())
        );
      }

      // Fetch from Google Knowledge Graph API
      try {
        const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        const response = await fetch(
          `https://kgsearch.googleapis.com/v1/entities:search?query=${input}&limit=5&key=${apiKey}`
        );
        const data = await response.json();

        // Filter Google suggestions to exclude irrelevant entities
        googleSuggestions = data.itemListElement
          .filter((item) => {
            const name = item.result.name.toLowerCase();
            return (
              !["youtube", "facebook", "apple", "twitter"].includes(name) &&  // Exclude irrelevant results
              (name.includes("engineer") || name.includes("scientist") || name.includes("manager") || name.includes("analyst") ||
               name.includes("doctor") || name.includes("lawyer") || name.includes("teacher") || name.includes("developer"))
            );
          })
          .map((item) => item.result.name);
      } catch (error) {
		// Error out, but do not terminate entirely
        console.error('Error fetching from Google API:', error);
      }

      // Merge predefined suggestions with filtered Google API suggestions
      setSuggestions([...predefinedSuggestions, ...googleSuggestions]);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
	if (onSelection) onSelection(suggestion); // Pass the selected suggestion to the parent component
    setSuggestions([]);
  };

  // Function to close the dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
      setSuggestions([]); // Close the dropdown when clicking outside
    }
  };

  // Add click event listener when component mounts
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={autocompleteRef} className="autocomplete-container" style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={className}
      />
      {suggestions.length > 0 && (
        <ul style={{
          position: 'absolute',
          zIndex: 1000,
          backgroundColor: 'white',
          border: '1px solid black',
          maxHeight: '200px',
          overflowY: 'auto',
          width: '100%',
          marginTop: '4px',
          listStyleType: 'none',
          padding: '0',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
          borderRadius: '10px',
        }}>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{
                padding: '10px',
                cursor: 'pointer',
                borderBottom: index !== suggestions.length - 1 ? '2px solid black' : 'none',
                backgroundColor: 'white',
                transition: 'background-color 0.3s ease',
                borderRadius: '8px',
              }}
              onMouseEnter={(e) => e.target.style.backgroundColor = 'orange'}
              onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default KnowledgeGraphAutocomplete;
