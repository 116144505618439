import { create } from "zustand";

/** Contains the currently loaded questions; state management done by Zustand. */
const useQuestionStore = create((set) => ({
	//Holds the currently loaded questions.
	questions: [],

	//Holds whether the question list is populated.
	isPopulated: false,

	//Holds whether the questions should be pulled down.
	shouldRevalidate: false,

	//Sets the contents of the question list from a given array.
	setQuestions: (newQs) => set(() => ({ questions: newQs })),

	//Adds a singular question to the question list.
	addQuestion: (newQ) => set((state) => ({ questions: [newQ, ...state.questions] })),

	//Sets whether the question list is populated.
	setIsPopulated: (populated) => set({ isPopulated: populated }),

	//Triggers a revalidation of the question state.
	triggerRevalidation: () => set({ shouldRevalidate: true }),

	//Resets the revalidation trigger.
	resetRevalidation: () => set({ shouldRevalidate: false }),
}));

export default useQuestionStore;
