import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfilePage.css';
import { useLocation } from 'react-router-dom';
import { FaSchool, FaBriefcase, FaEdit, FaEnvelope, FaStar, FaShare } from 'react-icons/fa';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import taxios from '../../util/token_refresh_hook';
import { toast } from 'react-toastify';
import Post from "../HomePage/QuestionThread/Post";
import QuestionThread from '../HomePage/QuestionThread/QuestionThread';
import ApplicationsSection from './ApplicationsSection';

const OrganizationPage = ({ onLogout, studyGroups, createStudyGroup, editStudyGroup, deleteStudyGroup, profileInfo, currentUser }) => {
  // Enumerator that contains the tab bar names. To be used for a useState that checks the selected tab
  const tabEnum = {
    HOME: 'home',
    POSTS: 'posts',
    JOBS: 'jobs',
    COURSES: 'courses',
    SEMINARS: 'seminars',
    HISTORY: 'history',
    APPLICATIONS: 'applications'
  };
  const [tabSelected, setTabSelected] = useState(tabEnum.POSTS);
  // Make sure to use the tabEnum object to set this value
  const changeTab = (tab) => {
    if(Object.values(tabEnum).includes(tab)){
      setTabSelected(tab);
    }
    else console.error(`Invalid tab value: ${tab}`);
  };

  const [posts, setPosts] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [courses, setCourses] = useState([]);
  const [applications, setApplications] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [banner, setBanner] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const navigate = useNavigate()

  // Check for query params in the URL indicating a specific profile to fetch
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const profileUname = searchParams.get('uname');

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleBannerUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setBanner(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle course click to navigate to the course profile page
  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  // Fetch content based off tab selected
  useEffect(() => {

    const fetchPosts = async () => {
      switch(tabSelected){
        // Fetch the user's posts if there are no posts currently populated
        case tabEnum.POSTS:
          if(posts.length === 0){
            try {
              const response = await taxios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/posts/by_author/${profileInfo.username}`);
              setPosts(response.data.payload.posts)
            } catch (error) {
              console.error(`Error fetching posts for user ${profileInfo.username}:`, error);
              toast.error('Failed to load posts');
              return [];
            }
          }
          break;
        case tabEnum.COURSES:
          //if(courses.length === 0){
            try {
              const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/courses/creator/${profileInfo._id}`);
              // Sort courses by newest first (descending order)
              const sortedCourses = response.data.courses.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
              setCourses(sortedCourses || []);
            } catch (error) {
              console.error('Error fetching courses:', error);
            }
          //}
          break;
        case tabEnum.APPLICATIONS:
          if (applications.length === 0) {
            try {
              const response = await taxios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/applications/by_user/${profileInfo._id}`
              );
              setApplications(response.data.payload);
            } catch (error) {
              console.error(`Error fetching applications for user ${profileInfo.username}:`, error);
              toast.error('Failed to load applications');
            }
          }
          break;
        default:
          break;
      }
    }
    
    fetchPosts();
  }, [tabSelected, currentUser]) 

  // Handle the functionality of the follow button
  const handleFollow = async () => {
    try {
      const endpoint = isFollowing
        ? `${process.env.REACT_APP_BACKEND_URL}/api/users/unfollow/${profileInfo._id}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/users/follow/${profileInfo._id}`;
      await taxios.post(endpoint);
      setIsFollowing(!isFollowing);
    } catch (error) {
      console.error(`Error ${isFollowing ? 'unfollowing' : 'following'} profile:`, error);
    }
  };

  // Check if the current user is currently following the profile being viewed
  useEffect(() => {
    const followStatus = profileInfo.followers.includes(currentUser._id)
    setIsFollowing(followStatus)
  }, [])

  return (
    <div className="profile-page">
      <TopNavBar 
        toggleSidebar={toggleSidebar} 
        onLogout={onLogout} 
        onCreateStudyGroup={createStudyGroup} 
        onEditStudyGroup={editStudyGroup} 
        deleteStudyGroup={deleteStudyGroup} 
        studyGroups={studyGroups} 
      />
      <div className="profile-main-content">
        <CommunitySidebar isOpen={sidebarOpen} />
        <div className="profile-container">
          <div className="profile-banner">
            {banner ? (
              <img src={banner} alt="Banner" className="banner-image" />
            ) : (
              profileInfo._id === currentUser._id &&
              (<label htmlFor="banner-upload" className="banner-upload-label">
                Upload a banner image or GIF
                <input type="file" id="banner-upload" accept="image/*" onChange={handleBannerUpload} />
              </label>)
            )}
          </div>
          <div className="profile-header">
            <div className="profile-info">
              <div className="profile-picture">
                <img src={profileInfo.avatar} alt="Profile" />
              </div>

              <div className="profile-details">
                <h1>{profileInfo.username}</h1>
                <p>{profileInfo.description}</p>
                <div className="profile-stats">
                  <span>{profileInfo.followers.length} Followers</span>
                  <span>{profileInfo.following.length} Following</span>
                </div>
                {/* Action buttons. Edit if it's the current user and follow/message otherwise */}
                {profileInfo._id === currentUser._id ? (
                  <div className='profile-actions'>
                    <button className="edit-button button-primary">
                      <FaEdit /> Edit
                    </button>
                  </div>
                ) : (
                  <div className='profile-actions'>
                    <button className={`follow-button ${isFollowing ? "button-secondary" : "button-primary"}`} onClick={handleFollow}>
                      {isFollowing ? "Following" : "Follow"}
                    </button>
                    <button className="message-button button-primary">
                      <FaEnvelope /> Message
                    </button>
                  </div>
                )}        
              </div>
            </div>
            <div className="profile-extra">
              <div className="extra-item">
                <FaBriefcase /> <span>{profileInfo.job_title}</span>
              </div>
              <div className="extra-item">
                <FaSchool /> <span>{profileInfo.school}</span>
              </div>
            </div>
          </div>
          <div className="tabs">
            <button 
              className={tabSelected === tabEnum.POSTS ? "tab current" : "tab"}
              onClick={() => changeTab(tabEnum.POSTS)}
            >Posts
            </button>

            <button 
              className={tabSelected === tabEnum.JOBS ? "tab current" : "tab"}
              onClick={() => changeTab(tabEnum.JOBS)}
            >Jobs
            </button>

            <button 
              className={tabSelected === tabEnum.COURSES ? "tab current" : "tab"}
              onClick={() => changeTab(tabEnum.COURSES)}
            >Courses
            </button>

            <button 
              className={tabSelected === tabEnum.SEMINARS ? "tab current" : "tab"}
              onClick={() => changeTab(tabEnum.SEMINARS)}
            >Seminars
            </button>

            <button 
              className={tabSelected === tabEnum.HISTORY ? "tab current" : "tab"}
              onClick={() => changeTab(tabEnum.HISTORY)}
            >History
            </button>

            {/* Show Applications tab only if viewing own profile AND user type is organization */}
            {profileInfo._id === currentUser._id && profileInfo.role === 'organization' && (
              <button 
                className={tabSelected === tabEnum.APPLICATIONS ? "tab current" : "tab"}
                onClick={() => changeTab(tabEnum.APPLICATIONS)}
              >Applications
              </button>
            )}
          </div>

          {/* And modify the Applications content section as well */}
          {tabSelected === tabEnum.APPLICATIONS && profileInfo._id === currentUser._id && profileInfo.role === 'organization' && (
            <div className='profile-section'>
              {applications.length > 0 ? (
                <ApplicationsSection applications={applications} />
              ) : (
                <div className="text-center py-8 bg-light-primary dark:bg-dark-primary rounded-lg shadow-sm">
                  <p className="text-gray-500 dark:text-gray-400">No applications submitted yet.</p>
                </div>
              )}
            </div>
          )}
          
          {/* Content specific to the organization */}
          <div className="profile-content">

            {/* Posts section content */}
            {tabSelected === tabEnum.POSTS &&
            (posts.length > 0 ?
              <div id="posts" className="profile-section">
                {posts.map((post) => (
                  <div key={post._id} className="post">
                          <QuestionThread question={post} />
                  </div>
                ))}
              </div>
              : <h2> No posts yet </h2>)
            }

            {/* Jobs section content */}
            {tabSelected === tabEnum.JOBS &&
            (<div id="jobs" className="profile-section">
              <h2>Jobs</h2>
            </div>)
            }

            {/* Courses section content */}
            {tabSelected === tabEnum.COURSES &&
              (courses.length > 0 ? 
                <div className='profile-section'>
                  <div className='courses-container'>
                    {courses.map((course, index) => (
                      <div key={course._id || index} className="course-item bg-light-primary dark:bg-dark-primary text-light-text dark:text-dark-text hover:shadow-lg" onClick={() => handleCourseClick(course._id)}>
                        <div className="course-thumbnail">
                          <img
                            src={
                              course?.courseThumbnailURL
                                ? course.courseThumbnailURL.startsWith('http')
                                  ? course.courseThumbnailURL
                                  : `https://archi-video-bucket.s3.us-west-1.amazonaws.com/thumbnails/${course.courseThumbnailURL}`
                                : 'https://via.placeholder.com/150'
                            }
                            alt="Course Thumbnail"
                            className="course-thumbnail-img"
                          />
                        </div>
                        <div className="course-info">
                          <div className="course-details">
                            <h3>{course.courseTitle || course.title}</h3>
                            <div className="creator-name">
                              <img src={course.creatorID?.profilePictureURL || course.profilePicture} alt="Profile" className="profile-picture" />
                              <span>{course.creatorID?.username || course.creator}</span>
                            </div>
                            <div className="rating">
                              {[...Array(5)].map((_, i) => (
                                <FaStar key={i} color={i < Math.round(course.rating) ? '#7C4A33' : '#e4e9f0'} />
                              ))}
                              <span>{course.rating?.toFixed(1)}</span>
                            </div>
                            <FaShare className="share-icon text-accent hover:text-accentPalette-400" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              : (
                <div>No courses available.</div>
              ))
            }
            
            {/* Seminars section content */}
            {tabSelected === tabEnum.SEMINARS &&
            (<div id="seminars" className="profile-section">
              <h2>Seminars</h2>
            </div>)
            }
            
            {/* History section content */}
            {tabSelected === tabEnum.HISTORY &&
            (<div id="history" className="profile-section">
              <h2>History</h2>
            </div>)
            }

            {/* Applications section content */}
            {tabSelected === tabEnum.APPLICATIONS && (
              <div className='profile-section'>
                {applications.length > 0 ? (
                  <ApplicationsSection applications={applications} />
                ) : (
                  <div className="text-center py-8 bg-light-primary dark:bg-dark-primary rounded-lg shadow-sm">
                    <p className="text-gray-500 dark:text-gray-400">No applications submitted yet.</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationPage;
