import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './CommunitySidebar.css';
import { 
  FaHome, 
  FaUserGraduate, 
  FaBuilding, 
  FaBook, 
  FaChalkboardTeacher, 
  FaBriefcase, 
  FaTimes, 
  FaBars, 
  FaComments 
} from 'react-icons/fa';
import ContactForm from '../../ContactForm';

function CommunitySidebar({ isOpen, toggleSidebar }) {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location]);

  const menuItems = [
    { label: 'Home', Icon: FaHome, path: '/app' },
    { label: 'Educators', Icon: FaUserGraduate, path: '/educator' },
    { label: 'Organizations', Icon: FaBuilding, path: '/organization' },
    { label: 'Courses', Icon: FaBook, path: '/courses' },
    { label: 'Events', Icon: FaChalkboardTeacher, path: '/seminars' },
    { label: 'Internships', Icon: FaBriefcase, path: '/internships' }
  ];

  return (
    <>
      <button 
        className={`sidebar-toggle-btn ${isOpen ? 'open' : ''}`} 
        onClick={toggleSidebar} 
        aria-label="Toggle Sidebar"
      >
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>
      
      <aside className={`community-sidebar ${isOpen ? 'open' : ''}`}>
        <ul className="communities">
          {menuItems.map((item, index) => (
            <li key={index} className={activeItem === item.path ? 'active' : ''}>
              <Link to={item.path} className="sidebar-link" onClick={toggleSidebar}>
                <item.Icon className="sidebar-icon" />
                <span className="sidebar-label">{item.label}</span>
              </Link>
            </li>
          ))}
          
          <div className="contact-us-container">
            <button
              onClick={() => setIsContactFormOpen(true)}
              className="contact-us-button"
            >
              <FaComments className="sidebar-icon" />
              <span className="sidebar-label">Feedback</span>
            </button>
          </div>
        </ul>
      </aside>
      
      <ContactForm
        isOpen={isContactFormOpen}
        onClose={() => setIsContactFormOpen(false)}
      />
    </>
  );
}

export default CommunitySidebar;
