import { create } from "zustand";

import {getParam, setParam} from "../../../util/query_param"

/** Contains the pagination info; state management done by Zustand. */
const usePaginationStore = create((set, get) => ({
	//Holds the current page number.
	currentPage: null,

	//Holds the maximum number of pages.
	maxPages: 1, //This is changed later

	//Holds whether the pagination info is populated.
	isPopulated: false,

	//Sets the current page number and updates the search params.
	setCurrentPage: (pnum) => {
		set({ currentPage: pnum });
		setParam("page", pnum);
	},

	//Sets the maximum number of pages.
	setMaxPages: (mx) => set({ maxPages: mx }),

	//Sets whether the pagination is populated.
	setIsPopulated: (populated) => set({ isPopulated: populated }),

	//Initializes the store using the URL params.
	initFromUrl: () => {
		const initialPage = getParam("page", 1);
		if(initialPage !== get().currentPage){
			set({ currentPage: initialPage });
		}
	},
}));

//Initialize the store when it's created
usePaginationStore.getState().initFromUrl();

export default usePaginationStore;