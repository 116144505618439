import React from "react";
import CourseCreationWizard from "./CourseCreationWizard";

function CourseCreationPage({ toggleSidebar, sidebarOpen, onLogout }) {
  return (
    <CourseCreationWizard
      toggleSidebar={toggleSidebar}
      sidebarOpen={sidebarOpen}
      onLogout={onLogout}
    />
  );
}

export default CourseCreationPage;
