//Sets the localstorage key for the user ID.
const LS_ID_KEY = "uid";

//Sets the localstorage key for the username.
const LS_UNAME_KEY = "username";

/**
 * Purges the user's info from localstorage.
 */
export function clearUInfo(){
	localStorage.removeItem(LS_ID_KEY);
	localStorage.removeItem(LS_UNAME_KEY);
}

/**
 * Gets the current user's info from localstorage.
 * @return {{id: String, username: String}} The user's info from localstorage
 */
export function getUInfo(){
	return {
		id: localStorage.getItem(LS_ID_KEY),
		username: localStorage.getItem(LS_UNAME_KEY)
	}
}

/**
 * Sets the current user's info in localstorage.
 * @param {String} id The ID of the user
 * @param {String} username The username of the user
 */
export function setUInfo(id, username){
	localStorage.setItem(LS_ID_KEY, id);
	localStorage.setItem(LS_UNAME_KEY, username);
}

/**
 * Sets the current user's info in localstorage via an API response payload.
 * @param {*} res The response from the server; data only
 */
export function setUInfoFromPayload(res){
	setUInfo(res.payload._id, res.payload.username);
}