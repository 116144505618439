import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaShare, FaStar } from 'react-icons/fa';

import TopNavBar from './TopNavBar/TopNavBar';
import CommunitySidebar from './CommunitySidebar/CommunitySidebar';
import QuestionForm from './QuestionForm/QuestionForm';
import QuestionList from './QuestionList/QuestionList';
import StudyGroupList from './CreateStudyGroupForm/StudyGroupList';
import CreateStudyGroupForm from './CreateStudyGroupForm/CreateStudyGroupForm';
import PaginationMenu from './Pagination/PMenu';

import useStudyGroupStore from './CreateStudyGroupForm/studyGroupStore';
import taxios from '../../util/token_refresh_hook';

import './HomePage.css';

const HomePage = ({
  questions,
  setQuestions,
  onLike,
  onDislike,
  onComment,
  onShare,
  addQuestion,
  toggleSidebar,
  sidebarOpen,
  setShowCreateForm,
  showEditForm,
  setShowEditForm,
  createStudyGroup,
  deleteStudyGroup,
  editStudyGroup,
  handleLogout,
  courses = []
}) => {
  const [searchParams] = useSearchParams();
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [page, setPage] = useState(1);
  const [fetchedCourses, setFetchedCourses] = useState([]);

  const navigate = useNavigate();

  // Access the state and toggle functions of the study group.
  const showGroupCreationForm = useStudyGroupStore((state) => state.showCreationForm);
  const toggleShowGroupCreationForm = useStudyGroupStore((state) => state.toggleShowCreationForm);

  useEffect(() => {
    const scrollToId = searchParams.get('scrollTo');
    if (scrollToId) {
      setTimeout(() => {
        const element = document.getElementById(scrollToId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          // Add highlight effect
          element.classList.add('highlighted');
          setTimeout(() => {
            element.classList.remove('highlighted');
          }, 3000);
        }
      }, 100);
    }
  }, [searchParams]);

  // Fetch courses from the backend when the component mounts
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/courses`);
        // Sort courses by newest first (descending order)
        const sortedCourses = response.data.payload.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setFetchedCourses(sortedCourses || []);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    console.log("Current fetched courses:", fetchedCourses);

    const matchCourseHeight = () => {
      const feedSection = document.querySelector('.feed-section');
      const coursesSection = document.querySelector('.courses-section');

      if (feedSection && coursesSection) {
        coursesSection.style.height = `${feedSection.offsetHeight}px`;
      }
    };

    // Call it after the courses are fetched
    matchCourseHeight();

    // Listen for window resize to re-adjust heights
    window.addEventListener('resize', matchCourseHeight);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', matchCourseHeight);
  }, [fetchedCourses]);

  // Handle post button click to toggle the question form visibility
  const handlePostButtonClick = () => {
    setShowQuestionForm(!showQuestionForm);
  };

  // Define the function to handle closing the form when "I'm finished" is clicked
  const handleFormClose = () => {
    setShowQuestionForm(false); // This will hide the form
  };

  const loadMoreQuestions = () => {
    setPage((prevPage) => prevPage + 1);
    // Implement the logic to load more questions here
  };

  // Define the handleScroll function to manage infinite scrolling
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      loadMoreQuestions();
    }
  };

  // Handle course click to navigate to the course profile page
  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  return (
    <div className="home-page light:bg-light-primary dark:bg-dark-primary text-light-text dark:text-dark-text">
      <TopNavBar
        toggleSidebar={toggleSidebar}
        onLogout={handleLogout}
        onCreateStudyGroup={() => toggleShowGroupCreationForm()}
      />
      <CommunitySidebar isOpen={sidebarOpen} />
      <div className={`content ${sidebarOpen ? 'sidebar-open' : ''}`}>
        <div className="main-content" onScroll={handleScroll}>
          <div className="feed-and-courses">
            {/* Left Column */}
            <div className="left-column">
              <div className="top-container bg-light-accentSecondary dark:bg-dark-accentSecondary text-light-text dark:text-dark-text">
                <div className="study-group-section">
                  <StudyGroupList />
                  {showGroupCreationForm && <CreateStudyGroupForm />}
                </div>
                <div className="post-button-container">
                  <button
                    className="post-button bg-accent hover:bg-accentPalette-400 text-light-primary dark:text-dark-primary"
                    onClick={handlePostButtonClick}
                  >
                    <span>Post +</span>
                  </button>
                </div>
              </div>
              {showQuestionForm && (
                <QuestionForm onAddQuestion={addQuestion} handleFormClose={handleFormClose} />
              )}
              <div className="feed-section bg-light-secondary dark:bg-dark-secondary text-light-text dark:text-dark-text">
                <PaginationMenu />
                <QuestionList highlightId={searchParams.get('scrollTo')} />
                <PaginationMenu />
              </div>
            </div>
            {/* Right Column */}
            <div className="right-column">
              <h2 className="courses-title">Courses</h2>
              <div className="courses-section text-light-text dark:text-dark-text">
                <div className="courses-list">
                  {fetchedCourses.length > 0 ? fetchedCourses.map((course, index) => (
                    <div key={course._id || index} className="course-item bg-light-primary dark:bg-dark-primary text-light-text dark:text-dark-text hover:shadow-lg" onClick={() => handleCourseClick(course._id)}>
                      <div className="course-thumbnail">
                        <img
                          src={
                            course?.courseThumbnailURL
                              ? course.courseThumbnailURL.startsWith('http')
                                ? course.courseThumbnailURL
                                : `https://archi-video-bucket.s3.us-west-1.amazonaws.com/thumbnails/${course.courseThumbnailURL}`
                              : 'https://via.placeholder.com/150'
                          }
                          alt="Course Thumbnail"
                          className="course-thumbnail-img"
                        />
                      </div>
                      <div className="course-info">
                        <div className="course-details">
                          <h3>{course.courseTitle || course.title}</h3>
                          <div className="creator-name">
                            <img src={course.creatorID?.profilePictureURL || course.profilePicture} alt="Profile" className="profile-picture" />
                            <span>{course.creatorID?.username || course.creator}</span>
                          </div>
                          <div className="rating">
                            {[...Array(5)].map((_, i) => (
                              <FaStar key={i} color={i < Math.round(course.rating) ? '#7C4A33' : '#e4e9f0'} />
                            ))}
                            <span>{course.rating?.toFixed(1)}</span>
                          </div>
                          <FaShare className="share-icon text-accent hover:text-accentPalette-400" />
                        </div>
                      </div>
                    </div>
                  )) : (
                    <div>No courses available.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
