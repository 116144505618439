import React from "react";
import { FaUpload } from "react-icons/fa";

function ContentStructureStep({
  sections,
  expandedSection,
  editingSectionId,
  editingSubsectionId,
  onAddSection,
  onAddSubsection,
  onUpdateSectionTitle,
  onUpdateSubsectionTitle,
  onVideoChange,
  onExpandSection,
  onEditingSectionIdChange,
  onEditingSubsectionIdChange,
  onPrevious,
  onNext,
}) {
  return (
    <div className="border border-gray-300 rounded-lg bg-white p-6 mb-6">
      <h2 className="text-left text-lg font-bold text-gray-800">
        Course Content
      </h2>
      <h3 className="text-xs text-gray-800 mb-3">
        Let's add some content to your course
      </h3>

      <div>
        {sections.map((section, sectionIndex) => (
          <div
            key={section.id}
            className="mb-3 border border-gray-300 rounded-lg bg-white"
          >
            <div
              className="flex justify-between items-center p-4 border-b border-gray-300 cursor-pointer hover:bg-gray-50"
              onClick={() =>
                onExpandSection(
                  expandedSection === sectionIndex ? null : sectionIndex
                )
              }
            >
              <div className="flex flex-col">
                <h3 className="font-semibold text-gray-800">
                  {editingSectionId === section.id ? (
                    <input
                      type="text"
                      value={section.title}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        onUpdateSectionTitle(section.id, e.target.value)
                      }
                      onBlur={() =>
                        onUpdateSectionTitle(section.id, section.title, true)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onUpdateSectionTitle(section.id, section.title, true);
                        }
                      }}
                      autoFocus
                      className="border border-gray-300 rounded-md px-2 py-1 w-[200px]"
                    />
                  ) : (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditingSectionIdChange(section.id);
                      }}
                      className="hover:bg-gray-100 rounded px-2 py-1 cursor-text"
                    >
                      Section {sectionIndex + 1}: {section.title}
                    </span>
                  )}
                </h3>
                <span className="text-sm text-gray-600">
                  {section.subsections.length}/2 |{" "}
                  {section.subsections.reduce(
                    (total, sub) => total + (sub.duration || 0),
                    0
                  )}{" "}
                  min
                </span>
              </div>
              <span className="text-gray-500">
                {expandedSection === sectionIndex ? "▼" : "▲"}
              </span>
            </div>

            {expandedSection === sectionIndex && (
              <div className="bg-gray-50 p-4">
                {section.subsections.map((subsection, subsectionIndex) => (
                  <div
                    key={subsection.id}
                    className="flex flex-col md:flex-row md:items-center justify-between border-b border-gray-300 p-4 bg-white mb-2 rounded"
                  >
                    <div className="flex-1 flex flex-col md:flex-row md:items-center md:gap-4">
                      {editingSubsectionId === subsection.id ? (
                        <input
                          type="text"
                          value={subsection.title}
                          onChange={(e) =>
                            onUpdateSubsectionTitle(
                              sectionIndex,
                              subsectionIndex,
                              e.target.value
                            )
                          }
                          onBlur={() =>
                            onUpdateSubsectionTitle(
                              sectionIndex,
                              subsectionIndex,
                              subsection.title,
                              true
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              onUpdateSubsectionTitle(
                                sectionIndex,
                                subsectionIndex,
                                subsection.title,
                                true
                              );
                            }
                          }}
                          onClick={(e) => e.stopPropagation()}
                          autoFocus
                          className="border border-gray-300 rounded-md px-2 py-1 w-[200px]"
                        />
                      ) : (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            onEditingSubsectionIdChange(subsection.id);
                          }}
                          className="hover:bg-gray-100 rounded px-2 py-1 cursor-text font-medium text-gray-800"
                        >
                          {subsectionIndex + 1}. {subsection.title}
                        </span>
                      )}
                      <span className="text-sm text-gray-600 md:ml-2">
                        {subsection.duration || 0} min
                      </span>
                    </div>

                    {/* Video Upload */}
                    <div className="my-4 md:my-0 md:ml-4">
                      <label
                        htmlFor={`video-upload-${sectionIndex}-${subsectionIndex}`}
                        className="flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-5 bg-gray-100 hover:bg-gray-200 transition cursor-pointer min-h-[120px]"
                      >
                        {subsection.videoFile ? (
                          <p className="text-gray-700">
                            {subsection.videoFile.name}
                          </p>
                        ) : (
                          <>
                            <FaUpload
                              size={24}
                              className="text-gray-500 mb-2"
                            />
                            <p className="text-sm text-gray-600">
                              Click to upload a video
                            </p>
                          </>
                        )}
                        <input
                          type="file"
                          id={`video-upload-${sectionIndex}-${subsectionIndex}`}
                          accept="video/mp4,video/quicktime,video/x-msvideo"
                          onChange={(e) =>
                            onVideoChange(e, sectionIndex, subsectionIndex)
                          }
                          className="hidden"
                        />
                      </label>
                    </div>
                  </div>
                ))}

                {section.subsections.length < 2 && (
                  <button
                    onClick={() => onAddSubsection(sectionIndex)}
                    className="w-full py-2 mt-2 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200 transition"
                  >
                    Add media
                  </button>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      <button
        onClick={onAddSection}
        className="mt-4 bg-[#002855] text-white rounded-md px-6 py-3 hover:bg-[#001f40] transition"
      >
        Add Section
      </button>

      <div className="flex justify-between mt-4">
        <button
          onClick={onPrevious}
          className="bg-[#002855] text-white rounded-md px-4 py-2 hover:bg-[#001f40] transition"
        >
          Back
        </button>
        <button
          onClick={onNext}
          className="bg-[#002855] text-white rounded-md px-4 py-2 hover:bg-[#001f40] transition"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ContentStructureStep;
