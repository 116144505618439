import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FaStar, FaStarHalfAlt, FaLock, FaUnlock } from 'react-icons/fa';
import taxios from '../../util/token_refresh_hook';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import { getUInfo } from '../../util/whoami';
import './CourseProfilePage.css';
import VideoPlayer from '../VideoPlayer';
import { Elements, useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CourseComments from './CourseComments';
import CourseRating from './CourseRating';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const CourseProfilePage = ({ toggleSidebar, sidebarOpen, onLogout }) => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [rating, setRating] = useState(0);
  const [userRating, setUserRating] = useState(0);
  const [newComment, setNewComment] = useState('');
  const [isFollowing, setIsFollowing] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [comments, setComments] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);
  const placeholderImage = 'https://via.placeholder.com/50';
  const user = getUInfo();
  const [isIntroSelected, setIsIntroSelected] = useState(true);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  
  const stripe = useStripe();
  const elements = useElements();

  const fetchCourseData = useCallback(async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/courses/${id}`);
      const courseData = response.data.payload;
      setCourse(courseData);
      setRating(courseData.rating || 0);
      setUserRating(courseData.userRating || 0);
      setIsEnrolled(courseData.enrolledUsers.map((enrolled) => enrolled.username).includes(user.username) || false);
      if (courseData.sections && courseData.sections.length > 0) {
        setSelectedSection(courseData.sections[0]);
        if (courseData.sections[0].subsections && courseData.sections[0].subsections.length > 0) {
          setSelectedSubsection(courseData.sections[0].subsections[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching course data:', error);
    }
  }, [id, user.username]);

  const fetchComments = useCallback(async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/comments/post/${id}`);
      const fetchedComments = response.data || [];
      setComments(Array.isArray(fetchedComments) ? fetchedComments : []);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setComments([]);
    }
  }, [id]);

  const checkIfFollowingCourse = useCallback(async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/courses/check-following/${id}`);
      setIsFollowing(response.data.isFollowing);
    } catch (error) {
      console.error('Error checking if following the course:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchCourseData();
    fetchComments();
    checkIfFollowingCourse();
  }, [fetchCourseData, fetchComments, checkIfFollowingCourse]);

  const handleFollowClick = async () => {
    try {
      if (isFollowing) {
        await taxios.post(`${process.env.REACT_APP_BACKEND_URL}/api/courses/${id}/unfollow`);
        setIsFollowing(false);
      } else {
        await taxios.post(`${process.env.REACT_APP_BACKEND_URL}/api/courses/${id}/follow`);
        setIsFollowing(true);
      }
    } catch (error) {
      console.error('Error updating follow status:', error);
    }
  };

  const handleEnroll = async () => {
    if (course?.coursePrice === 0) {
      try {
        const response = await taxios.post(`${process.env.REACT_APP_BACKEND_URL}/api/courses/${id}/join`);
        if (response.status === 201) {
          setIsEnrolled(true);
          toast.success('Successfully enrolled in course!');
          fetchCourseData();
        }
      } catch (error) {
        console.error('Error enrolling in course:', error);
        toast.error('Failed to enroll in course');
      }
    } else {
      if (!stripe) {
        toast.error('Payment system is not ready. Please try again in a moment.');
        return;
      }
      setShowPaymentModal(true);
    }
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      toast.error('Payment system is not ready. Please try again.');
      return;
    }

    setIsProcessing(true);
    try {
      const response = await taxios.post(`${process.env.REACT_APP_BACKEND_URL}/api/courses/${id}/join`);
      const { clientSecret } = response.data;

      const cardElement = elements.getElement(CardElement);
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: cardElement }
      });

      if (error) {
        toast.error('Payment failed: ' + error.message);
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        await taxios.post(`${process.env.REACT_APP_BACKEND_URL}/api/courses/${id}/join/confirm`, { paymentIntentId: paymentIntent.id });

        setIsEnrolled(true);
        toast.success('Successfully enrolled in course!');
        setShowPaymentModal(false);
        fetchCourseData();
      }
    } catch (error) {
      if (error.response?.data?.error === 'Already enrolled in this course') {
        toast.info('You are already enrolled in this course');
        setShowPaymentModal(false);
      } else {
        toast.error('Payment failed. Please try again.');
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) return;
    try {
      const response = await taxios.post(`${process.env.REACT_APP_BACKEND_URL}/api/comments`, {
        author: user.id,
        authorUname: user.username,
        parent: course._id,
        body: newComment.trim(),
      });
      setComments([...comments, response.data.payload]);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleSectionClick = (section) => {
    if (!isEnrolled && !section.title.toLowerCase().includes('intro')) {
      return;
    }
    setSelectedSection(section);
    setIsIntroSelected(false);
    if (section.subsections?.length > 0) {
      setSelectedSubsection(section.subsections[0]);
    }
  };

  const handleSubsectionClick = (subsection) => {
    setSelectedSubsection(subsection);
    setIsIntroSelected(false);
  };

  useEffect(() => {
    if (course?.introVideoURL) {
      setIsIntroSelected(true);
      setSelectedSection(null);
      setSelectedSubsection(null);
    }
  }, [course]);

  const renderMediaContent = () => {
    if (!course) {
      return <div className="video-player"><p>Loading...</p></div>;
    }

    if (isIntroSelected && course.introVideoURL && !selectedSubsection) {
      return (
        <div className="video-player">
          <VideoPlayer videoId={course.introVideoURL} isIntroVideo={true} />
        </div>
      );
    }

    if (isEnrolled && selectedSubsection?.videoURL) {
      return (
        <div className="video-player">
          <VideoPlayer videoId={selectedSubsection.videoURL} isIntroVideo={false} />
        </div>
      );
    }

    if (!isEnrolled && !isIntroSelected) {
      return (
        <div className="video-player locked-content">
          <div className="lock-message">
            <h3>You need to enroll to access this content</h3>
            <button onClick={handleEnroll} className="enroll-button">
              {course.coursePrice === 0 ? 'Enroll Now (Free)' : `Enroll Now ($${course.coursePrice})`}
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="video-player">
        <p>Select a lesson to start learning</p>
      </div>
    );
  };

  return (
    <div className="course-profile-page">
      <TopNavBar toggleSidebar={toggleSidebar} onLogout={onLogout} />
      <CommunitySidebar isOpen={sidebarOpen} />
      
      <div className="course-profile-content">
        <div className="media-section">
          <div className="video-container">
            {renderMediaContent()}
          </div>
          <div className="course-info">
            <div className="top-header">
              <div className="title-container">
                <h1>{course?.courseTitle}</h1>
              </div>
              <div className="button-container">
                {!isEnrolled && (
                  <button onClick={handleEnroll} className="enroll-btn-header">
                    {course?.coursePrice === 0 ? 'Enroll Now (Free)' : `Enroll Now ($${course?.coursePrice?.toFixed(2)})`}
                  </button>
                )}
              </div>
            </div>
            <div className="instructor-info">
              <div className="instructor-left">
                <ProfilePicture
                  src={course?.creatorID?.profilePicture || placeholderImage}
                  alt={course?.creatorID?.username || 'Instructor'}
                />
                <div className="instructor-details">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <span className="instructor-name">{course?.creatorID?.username || 'Instructor'}</span>
                    <button onClick={handleFollowClick} className="follow-btn">
                      {isFollowing ? 'Unfollow' : 'Follow'}
                    </button>
                  </div>
                  <div className="rating-section-wrapper" style={{ flexDirection: 'row', alignItems: 'center', margin: '16px 0' }}>
                    <span className="rating-label" style={{ marginRight: '8px' }}>Rate this Course:</span>
                    <div className="rating-section">
                      <CourseRating 
                        courseId={course?._id || id}
                        initialRating={rating}
                        userRating={userRating}
                        onRatingUpdate={(updatedCourse) => {
                          setRating(updatedCourse.rating);
                          setUserRating(updatedCourse.userRating);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="course-meta">
              <div className="meta-item">
                <label>Category:</label>
                <span>{course?.courseCategory}</span>
              </div>
              <div className="meta-item">
                <label>Duration:</label>
                <span>{course?.courseDuration} minutes</span>
              </div>
              <div className="meta-item">
                <label>Price:</label>
                <span>${course?.coursePrice?.toFixed(2)}</span>
              </div>
            </div>
            <div className="course-description">
              <h2>About This Course</h2>
              <p>{course?.courseDescription}</p>
            </div>
            <CourseComments courseId={course?._id} user={user} />
          </div>
        </div>

        <div className="course-sections">
          <h2>Course Content</h2>
          {course?.introVideoURL && (
            <div className={`intro-section ${isIntroSelected ? 'active' : ''}`} onClick={() => setIsIntroSelected(true)}>
              <div className="section-header">
                <div className="section-title">
                  <h3>
                    {course.introVideoTitle || 'Course Introduction'}
                    <span className="free-preview-badge">Free Preview</span>
                  </h3>
                </div>
                <div className="section-duration-lock">
                  <span>{course.introDuration || 0} min</span>
                  <FaUnlock className="lock-icon unlocked" />
                </div>
              </div>
            </div>
          )}

          {course?.sections?.map((section, index) => (
            <div key={section.id || index} className="section">
              <div className="section-header" onClick={() => {
                if (isEnrolled) {
                  setIsIntroSelected(false);
                  handleSectionClick(section);
                }
              }} style={{ cursor: isEnrolled ? 'pointer' : 'not-allowed' }}>
                <div className="section-title">
                  <h3>{section.title}</h3>
                </div>
                <div className="section-duration-lock">
                  <span>{section.duration} min</span>
                  {isEnrolled ? <FaUnlock className="lock-icon unlocked" /> : <FaLock className="lock-icon locked" />}
                </div>
              </div>

              {isEnrolled && selectedSection === section && (
                <div className="subsections">
                  {section.subsections?.map((subsection, subIndex) => (
                    <div key={subIndex} className={`subsection ${selectedSubsection === subsection ? 'active' : ''}`} onClick={() => {
                      setIsIntroSelected(false);
                      handleSubsectionClick(subsection);
                    }}>
                      <span>{subsection.title}</span>
                      <div className="section-duration-lock">
                        <span>{subsection.duration} min</span>
                        {isEnrolled ? <FaUnlock className="lock-icon unlocked" /> : <FaLock className="lock-icon locked" />}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {showPaymentModal && course && (
        <div className="payment-modal">
          <div className="payment-modal-content">
            <button className="close-button" onClick={() => setShowPaymentModal(false)}>×</button>
            <h2>Purchase {course.courseTitle}</h2>

            <form onSubmit={handlePaymentSubmit}>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': { color: '#aab7c4' },
                    },
                    invalid: { color: '#9e2146' },
                  },
                }}
              />
              <button type="submit" disabled={!stripe || !elements || isProcessing}>
                {isProcessing ? 'Processing...' : `Pay $${course.coursePrice.toFixed(2)}`}
              </button>
            </form>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default CourseProfilePage;
