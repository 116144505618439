import React from 'react';
import './Logo.css';
import logo from '../../../assets/LogoWithName.png'; // Adjust the path to the image

const Logo = () => {
  return (
    <div className="login-signup-logo">
      <img src={logo} alt="Logo" className="login-signup-logo-image" />
    </div>
  );
};

export default Logo;